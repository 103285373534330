import { FullProfileType } from 'permaweb-sdk/dist/helpers/types';
import { doFinalizeProfileUpdate } from 'components/profileEdit/actions';
import { suppressToConsole } from 'helpers/error';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal } from 'store/modal/slice';
import uploadSlice from 'store/upload/slice';

import Error from './error';
import { UPLOAD_REQUEST_IDS } from './index';

export default function Form() {
  const formHook = useFormContext<FullProfileType>();
  const dispatch = useAppDispatch();

  const onSubmit = async (data: FullProfileType) => {
    await dispatch(doFinalizeProfileUpdate(data))
      .then(() => dispatch(closeModal()))
      .catch(suppressToConsole); // let user continue editing
  };

  const onInvalid = (errors: any) => {
    console.log('errors:', errors);
  };

  const onReset = () => {
    formHook.reset();
    dispatch(uploadSlice.actions.deleteRequest(UPLOAD_REQUEST_IDS.banner));
    dispatch(uploadSlice.actions.deleteRequest(UPLOAD_REQUEST_IDS.avatar));
  };

  return (
    <>
      <form onSubmit={formHook.handleSubmit(onSubmit, onInvalid)}>
        <div className="profileEdit__list">
          <div className="profileEdit__field">
            <label>Handle</label>
            <input type="text" {...formHook.register('handle', { required: 'Required field' })} />
            <Error state="handle" />
          </div>

          <div className="profileEdit__field">
            <label>Display Name</label>
            <input type="text" {...formHook.register('displayName')} placeholder="(optional)" />
            <Error state="displayName" />
          </div>

          <div className="profileEdit__field">
            <label>Bio</label>
            <textarea
              placeholder="(optional)"
              {...formHook.register('bio', { maxLength: { value: 500, message: 'Max length is 500 characters' } })}
            />
            <Error state="bio" />
          </div>

          <div className="profileEdit__submit">
            {formHook.formState.isDirty && (
              <button type="button" onClick={onReset}>
                Restore
              </button>
            )}
            <input type="submit" />
          </div>
        </div>
      </form>
    </>
  );
}
