import React from 'react';
import { doPostComment } from 'permaweb-sdk/dist/implementations/rtk';

import Avatar from 'components/avatar';
import { sendAlert } from 'helpers/error';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import * as S from './styles';

interface Props {
  assetId: TxId;
  parentId?: TxId;
  threadId?: TxId;
  onCreate?: () => void;
}

export default function CommentCreate(props: Props) {
  const { assetId, parentId, threadId, onCreate } = props;
  const dispatch = useAppDispatch();
  const creating = useAppSelector((state) => state.comments.flags.creatingComment);
  const inputRef = React.useRef<HTMLTextAreaElement>(null);

  const handleSubmit = async () => {
    dispatch(doPostComment(inputRef?.current?.value || '', assetId, parentId || assetId, threadId))
      .then(() => {
        inputRef.current!.value = '';
        onCreate && onCreate();
      })
      .catch(sendAlert);
  };

  return (
    <S.CommentCreate busy={Boolean(creating)}>
      <S.CommentCreateRow>
        <Avatar walletAddr="active_user" />
        <S.Input placeholder="Add a comment..." ref={inputRef} />
      </S.CommentCreateRow>
      <S.Submit type="button" onClick={handleSubmit}>
        {!!parentId && assetId !== parentId ? 'Reply' : 'Comment'}
      </S.Submit>
    </S.CommentCreate>
  );
}
