import './style.scss';

function AudioRenderer(props: any) {
  const { asset } = props;

  return (
    <div className="renderer--audio">
      {asset.thumbnail && <img src={`https://arweave.net/${asset.thumbnail}`} alt="Poster" className="thumbnail" />}
      <audio controls className={`${asset.thumbnail ? 'audioWithThumbnail' : ''}`}>
        <source src={`https://arweave.net/${asset.id}`} type={asset.contentType} />
        Your browser does not support the audio tag.
      </audio>
    </div>
  );
}

export default AudioRenderer;
