import React from 'react';
import Ar, { TArweaveInstance } from 'helpers/arweave/ar';

type Ret = number | null | undefined;

export default function useGetCurrentBlockHeight(instance: TArweaveInstance): Ret {
  const [currentBlockHeight, setCurrentBlockHeight] = React.useState<Ret>(undefined);

  React.useEffect(() => {
    Ar.getInstance('standard')
      .network.getInfo()
      .then((info) => {
        setCurrentBlockHeight(info.height);
      })
      .catch((error) => {
        setCurrentBlockHeight(null);
      });
  }, [instance]);

  return currentBlockHeight;
}
