import { STYLING } from 'helpers/config';
import styled from 'styled-components';

export const ContentListHeader = styled.div<{ desktop: boolean; filters: boolean }>`
  position: sticky;
  top: ${(props) => (props.desktop ? '10px' : '60px')};
  margin-left: ${(props) => (props.desktop ? 0 : `calc(${STYLING.spacing.s} * -1)`)};
  width: 100%;
  z-index: 99;
  background-image: ${(props) => (props.desktop ? 'none' : 'rgba(255, 255, 255, 0.3)')};
  backdrop-filter: ${(props) => (props.desktop ? 'none' : 'blur(10px)')};
  padding: ${(props) => (props.desktop ? 0 : STYLING.spacing.s)};
  //   margin-bottom: ${(props) => (props.desktop ? STYLING.spacing.s : 0)};
  border-bottom: ${(props) => (props.desktop ? 'none' : `2px solid var(--color-background)`)};
`;

export const ContentListHeaderItems = styled.div`
  display: flex;
  width: 100%;
`;

export const Filter = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--color-gray3);
  margin-right: ${STYLING.spacing.s};
  outline: 2px solid var(--color-background);

  * > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 6px;
    background: black;
    box-sizing: border-box;
    svg {
      color: white;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: var(--color-gray4);
    * > div {
      background: white;
      svg {
        color: black;
      }
    }
  }
`;

export const NavigationWrapper = styled.div<{ visible: boolean }>`
  width: 100%;
  max-height: ${(props) => (props.visible ? '100px' : 0)};
  margin-top: ${(props) => (props.visible ? STYLING.spacing.s : 0)};
  overflow: hidden;
  height: fit-content;
  transition: all 0.4s;
`;
