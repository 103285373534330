// ****************************************************************************
// Tag Names
// ****************************************************************************

export const TAG_NAMES = {
  appName: 'App-Name',
  appVersion: 'App-Version',
  banner: 'Banner',
  contentType: 'Content-Type',
  mediaIds: 'Media-Ids',
  protocolName: 'Protocol-Name',
  protocolVersion: 'Protocol-Version',
  unixTime: 'Unix-Time',
  dateCreated: 'Date-Created',
  title: 'Title',
  description: 'Description',
  input: 'Input',
  contractSrc: 'Contract-Src',
  contractManifest: 'Contract-Manifest',
  license: 'License',
  derivation: 'Derivation',
  commercialUse: 'Commercial-Use',
  rootSource: 'Root-Source',
  dataSource: 'Data-Source',
  threadSource: 'Thread-Source',
} as const;

export type TagNames = (typeof TAG_NAMES)[keyof typeof TAG_NAMES];

// ****************************************************************************
// findTag()
// ****************************************************************************

type All = TagNames; // | FairTagNames | SocialSdkTagNames;

/**
 * Find a tag's value from an ITag array.
 *
 * Generics is optional, but helps to narrow-down the tag name autocompletion.
 *
 * Equivalent usages:
 *  • findTag(tags, 'Model-Transaction')
 *  • findTag(tags, TAG_NAMES.modelTransaction)
 *  • findTag<FairTagNames>(tags, TAG_NAMES.modelTransaction)
 *
 * @param tags
 * @param tagName
 */
export function findTag<T extends All>(tags: ITag[] | undefined, tagName: T) {
  if (tags) {
    return tags.find((tag) => tag.name === tagName)?.value;
  }
  return undefined;
}
