export const language = {
  en: {
    about: 'About',
    arBalance: `AR Balance`,
    appName: 'Interesting',
    connectorNotFound: 'Connector not found',
    copied: 'Copied',
    copyLink: `Copy link`,
    copyWalletAddress: `Copy wallet address`,
    signIn: 'Sign In',
    signOut: 'Sign Out',
    chooseWallet: 'Choose a Wallet',
    demo: 'Demo',
    loading: `Loading`,
    login: `Login`,
    disconnect: `Disconnect`,
    upload: 'Upload',
    viewProfile: `View profile`,
  },
};
