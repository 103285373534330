import './style.scss';

function PageRenderer(props: any) {
  const { asset } = props;

  return (
    <div className="renderer--page">
      <iframe src={`https://arweave.net/${asset.id}`} loading="lazy" />
    </div>
  );
}

export default PageRenderer;
