import React from 'react';
import Avatar from 'components/avatar';
import Icon from 'components/icon';
import { useAppSelector } from 'store/hooks';
import { selectStampersForContentIds, selectStampersLoadingForContentId } from 'store/stamps/selectors';
import './style.scss';

function AssetMeta(props: any) {
  const { asset } = props;
  if (!asset) return;
  const timestampDisplay = asset.dateCreated ? `${new Date(asset.dateCreated).toLocaleString()}` : '';
  const id = asset.id;

  const idInList = React.useMemo(() => [id], [id]);

  const { total, vouched } = useAppSelector((state) => selectStampersForContentIds(state, idInList));
  const stampersLoading = useAppSelector((state: AppState) => selectStampersLoadingForContentId(state, id));

  return (
    asset && (
      <div className="assetMeta">
        <div className="assetMeta__header">
          <Avatar walletAddr={asset.creator.txId} />
          <span className="assetMeta__handle">{asset.creator?.handle || 'Anonymous'}</span>
          {timestampDisplay}
        </div>

        {/* License: {JSON.stringify(data.license)} */}

        <div className="assetMeta__stampers">
          <Icon icon={'upvote'} size={24} height={26} />

          {!vouched.length && stampersLoading && <Avatar walletAddr={'loading'} small stack />}
          {vouched && vouched.map((stamper: string, i: any) => <Avatar key={i} walletAddr={stamper} small stack />)}
        </div>
      </div>
    )
  );
}

export default AssetMeta;
