import './style.scss';
import React from 'react';
import { useSnackbar } from '@mui/base';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { doRemoveToast } from 'store/toast/thunks';

interface Props {
  toastId: number;
  stackIndex: number;
}

export default function ToastView(props: Props) {
  const { toastId } = props;
  const dispatch = useAppDispatch();
  const toast = useAppSelector((state) => state.toast.toasts.find((t) => t.id === toastId));

  const { getRootProps } = useSnackbar({
    open: !!toast,
    autoHideDuration: null, // Disable MUI's timer; use ours to support stacking
  });

  return (
    <>
      {toast && (
        <div className="toast" {...getRootProps()}>
          {toast.message}
          {toast.subMessage && <div className="toast__sub-message">{toast.subMessage}</div>}
          <span className="toast__close" onClick={() => dispatch(doRemoveToast(toastId))}>
            x
          </span>
        </div>
      )}
    </>
  );
}
