import styled from 'styled-components';
import { STYLING } from 'helpers/config';

export const FollowList = styled.div<{ modal?: boolean }>`
  min-width: 50vw;
  max-height: ${(props) => (props.modal ? '80vh' : 'unset')};
  overflow-y: auto;
`;

export const Spinner = styled.div`
  margin-top: ${STYLING.spacing.m};
  height: 4px;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: loading 4s ease-in-out infinite;
  border-radius: 2px;

  @keyframes loading {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${STYLING.spacing.m};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${STYLING.spacing.m};
  align-items: center;

  background-color: var(--color-gray3);
  border-radius: 10px;
  padding: ${STYLING.spacing.s};
`;

export const Infos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Name = styled.div`
  font-weight: bold;
`;

export const Handle = styled.div`
  font-size: smaller;
  opacity: 0.7;
`;
export const Bio = styled.div`
  margin-top: 5px;
`;
