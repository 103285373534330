import React from 'react';
import { ReactSVG } from 'react-svg';

import { updateProfile } from 'permaweb-sdk/dist/api';
import { ProfileType } from 'permaweb-sdk/dist/helpers/types';

// import { Button } from 'components/atoms/Button';
// import { Loader } from 'components/atoms/Loader';
// import { ProfilePreview } from 'components/molecules/ProfilePreview';
import { ALLOWED_AVATAR_TYPES, ASSETS } from 'helpers/config';
// import { useLanguageProvider } from 'providers/LanguageProvider';
import { useAppSelector } from 'store/hooks';

import * as S from './styles';

export default function Onboarding(props: any) {
  const { handleClose } = props;
  // const languageProvider = useLanguageProvider();
  // const language = languageProvider.object[languageProvider.current];
  const [activeCard, setActiveCard] = React.useState(0);
  const [check, setCheck] = React.useState(false);
  const [displayName, setDisplayName] = React.useState('');
  const [handle, setHandle] = React.useState('');
  const [isDraggingOver, setIsDraggingOver] = React.useState(false);
  const avatarInputRef = React.useRef<any>(null);
  const [avatar, setAvatar] = React.useState<any>(null);

  const profilesById = useAppSelector((state) => state.profiles.byWalletId);
  const profiles = profilesById ? Object.values(profilesById) : [];
  const profilesFetching = useAppSelector((state) => state.profiles.pendingFetches);
  const connectedProfile = useAppSelector((state) => state.arweave.profile);

  const handleCheckDisplayName = (e: any) => {
    setDisplayName(e.target.value);
  };

  const handleCheckHandle = (e: any) => {
    setHandle(e.target.value);
  };

  function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length) {
      const file = e.target.files[0];
      if (file.type.startsWith('image/')) {
        const reader = new FileReader();

        reader.onload = (event: ProgressEvent<FileReader>) => {
          if (event.target?.result) {
            setAvatar(event.target.result);
          }
        };

        reader.readAsDataURL(file);
      }
      e.target.value = '';
    }
  }

  const onDrop = (e: any) => {
    e.preventDefault();
    e.target.files = e.dataTransfer.files;
    handleFileChange(e);
    setIsDraggingOver(false);
  };

  const onDragOver = (e: any) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  React.useEffect(() => {
    if (activeCard === 0) {
      if (displayName && displayName.length > 2 && handle && handle.length > 2) setCheck(true);
      else setCheck(false);
    } else if (activeCard === 1) {
      if (avatar) setCheck(true);
      else setCheck(false);
    } else if (activeCard === 2) {
      setCheck(true);
    }
  }, [activeCard, displayName, handle, avatar, check]);

  React.useEffect(() => {
    if (connectedProfile) {
      if (connectedProfile.displayName) setDisplayName(connectedProfile.displayName);
      if (connectedProfile.handle) setHandle(connectedProfile.displayName);
      if (connectedProfile.displayName && connectedProfile.handle) setActiveCard(1);
    }
  }, [connectedProfile]);

  async function handleSubmit() {
    if (connectedProfile && connectedProfile.walletAddress && handle && displayName) {
      try {
        await updateProfile({
          address: connectedProfile.walletAddress,
          displayName: displayName,
          handle: handle,
          avatar: avatar,
          banner: '',
          bio: '',
        });
        handleClose();
      } catch (e: any) {
        console.error(e);
      }
    }
  }

  return (
    <S.Wrapper>
      <S.CardsWrapper>
        <S.Cards marginLeft={activeCard}>
          <S.Card1 id="card1">
            <h2>Create a new User</h2>
            <S.CardRows>
              <S.CardRow>
                <S.InputWrapper check={Boolean(displayName.length > 2)}>
                  <input
                    type={'text'}
                    value={displayName}
                    onChange={handleCheckDisplayName}
                    placeholder="My Username"
                  />
                  <div>&#x2714;</div>
                </S.InputWrapper>
              </S.CardRow>
              <S.CardRow>
                <S.InputWrapper handle={true} check={Boolean(handle.length > 2)}>
                  <input type={'text'} onChange={handleCheckHandle} placeholder="myhandle" />
                  <span>@</span>
                  <div>&#x2714;</div>
                </S.InputWrapper>
              </S.CardRow>
            </S.CardRows>
          </S.Card1>
          <S.Card2>
            <h2>Upload a profile picture</h2>
            <S.AvatarPreview>
              {avatar && avatar.length ? (
                <img src={avatar} />
              ) : (
                <div>
                  <ReactSVG src={ASSETS.upload} />
                </div>
              )}
              <input
                ref={avatarInputRef}
                type={'file'}
                onChange={(e: any) => handleFileChange(e)}
                accept={ALLOWED_AVATAR_TYPES}
              />
            </S.AvatarPreview>
            <S.AvatarDropArea onDrop={onDrop} onDragOver={onDragOver} isDraggingOver={isDraggingOver}>
              <input ref={avatarInputRef} type={'file'} accept={ALLOWED_AVATAR_TYPES} />
              {!isDraggingOver ? <p>Drag and drop an image here or use the button above</p> : <p>Drop the file!</p>}
            </S.AvatarDropArea>
          </S.Card2>
          <S.Card3>
            <h2>Suggested topics to follow</h2>
            <S.TopicsWrapper>
              <S.Topic>
                Crypto
                <button type="button" value="Follow" onClick={() => {}} />
              </S.Topic>
              <S.Topic>
                News
                <button type="button" value="Follow" onClick={() => {}} />
              </S.Topic>
            </S.TopicsWrapper>
          </S.Card3>
          <S.Card4>
            <h2>Suggested profiles for you to follow</h2>
            {(profiles && profiles.length) || (!profiles && !profilesFetching) ? (
              <S.ProfilesWrapper>
                {/* profiles.map((profile: ProfileType, index: number) => (
									<ProfilePreview key={index} address={profile.walletAddress} slim={true} />
								)) */}
              </S.ProfilesWrapper>
            ) : (
              <S.LWrapper>{/* <Loader sm relative /> */}</S.LWrapper>
            )}
          </S.Card4>
        </S.Cards>
      </S.CardsWrapper>
      <S.Navigation>
        <button
          type="button"
          value={'Back'}
          onClick={() => setActiveCard(activeCard - 1)}
          disabled={activeCard === 0}
        />
        <button
          type="button"
          value={activeCard < 3 ? 'Next' : 'Finish'}
          onClick={() => (activeCard < 3 ? setActiveCard(activeCard + 1) : handleSubmit())}
          disabled={!check}
        />
      </S.Navigation>
      <S.Progress activeCard={activeCard}>
        <div />
        <div />
        <div />
        <div />
      </S.Progress>
    </S.Wrapper>
  );
}
