import classnames from 'classnames';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import uiSlice from 'store/ui/slice';

import * as S from './styles';

interface Props {
  hide?: boolean;
}

function TimeToggle(props: Props) {
  const { hide } = props;
  const timeFrameDays = useAppSelector((state) => state.ui.listRangeDays);
  const dispatch = useAppDispatch();

  const Selection = (props: { days: number }) => {
    const classes = classnames('timeToggle__entry', { 'timeToggle__entry--active': timeFrameDays === props.days });
    return (
      <div className={classes} onClick={() => dispatch(uiSlice.actions.setListRangeDays(props.days))}>
        {props.days}d
      </div>
    );
  };

  return (
    <S.TimeToggle hidden={Boolean(hide)}>
      <Selection days={7} />
      <Selection days={30} />
      <S.TimeToggleMarker month={timeFrameDays === 30} />
    </S.TimeToggle>
  );
}

export default TimeToggle;
