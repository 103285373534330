import React from 'react';

export default function useScrollPosition(updateCondition?: (scrollPosition: number) => boolean) {
  const [scrollPosition, setScrollPosition] = React.useState(0);

  const handleScroll = () => {
    const position = document.body.scrollTop || document.documentElement.scrollTop;
    setScrollPosition(position);
  };

  React.useEffect(() => {
    handleScroll();
    document.addEventListener('scroll', handleScroll);
    return () => document.removeEventListener('scroll', handleScroll);
  }, []);

  return scrollPosition;
}
