import styled, { keyframes } from 'styled-components';
import { STYLING } from 'helpers/config';

export const MenuButton = styled.button`
  line-height: 1.5;
  background-color: black;
  color: white;
  font-weight: 600;
  cursor: pointer;
  transition: all 150ms ease;
  border-radius: 20px;
  border: none;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: ${STYLING.spacing.s};
  padding-left: 4px;
  padding-right: 8px;

  img {
    align-self: unset;
    // filter: invert(0%);
    transition: filter 0.4s;
  }

  &.active {
    background-color: #0059b2;
  }

  &:focus-visible {
    outline: none;
  }
`;

export const MenuList = styled.ul`
  box-sizing: border-box;
  padding: 10px;
  margin: 10px 10px 0px 0px;
  min-width: 200px;
  background: black;
  border: 1px solid #424a53;
  border-radius: var(--border-radius);
  color: #afb8c1;
  overflow: auto;
  outline: 0;
  z-index: 100;

  hr {
    color: #424a53;
    border-color: #424a53;
  }
`;

export const MenuItem = styled.li`
  list-style: none;
  padding: 10px;
  border-radius: 0.45em;
  cursor: default;
  user-select: none;

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    background-color: #32383f;
    color: #afb8c1;
  }
`;
