import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { doInitHelpers, doReportInitErrors } from 'helpers/init';
import { doInitSdk } from 'permaweb-sdk/dist/implementations/rtk';

import { GlobalStyle } from 'styles';
// import Modal from 'components/base/modal';
import { LanguageProvider } from 'providers/LanguageProvider';
import ToastList from 'components/base/toast/toastList';
import Chat from 'components/chat';

import { SDK_CONFIG } from 'helpers/config';
import { persistor, store } from 'store/store';
import UI from 'components/ui';
import { DOM } from 'helpers/config';

import './style.scss';

function AppWrapper() {
  const onBeforeLift = async () => {
    await store.dispatch(doInitSdk(SDK_CONFIG));
    await store.dispatch(doInitHelpers());
    store.dispatch(doReportInitErrors());
  };

  return (
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={<div className="rehydrating" />} persistor={persistor} onBeforeLift={onBeforeLift}>
          <LanguageProvider>
            <GlobalStyle />
            <UI />
            <div id={DOM.overlay} />
            <ToastList />
            <Chat />
          </LanguageProvider>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  );
}

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement as HTMLElement);
root.render(<AppWrapper />);
