import styled from 'styled-components';

export const FollowButton = styled.button<{ disabled: boolean }>`
  width: 120px;
  height: 40px;
  border-radius: 50px;
  border-style: unset;
  background-color: var(--color-gray3);
  font-size: 18px;
  font-weight: 600;
  color: black;
  text-decoration: none;
  margin-left: auto;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'default')};

  &:hover {
    cursor: pointer;
    background-color: var(--color-gray4);
    color: white;
  }
`;
