import styled from 'styled-components';
import { STYLING } from 'helpers/config';

export const CommentCreate = styled.div<{ busy: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${STYLING.spacing.s};
  opacity: ${(props) => (props.busy ? 0.5 : 1)};
  pointer-events: ${(props) => (props.busy ? 'none' : 'default')};
`;

export const CommentCreateRow = styled.div`
  display: flex;
  gap: ${STYLING.spacing.s};
`;

export const Input = styled.textarea`
  resize: none;
  width: 100%;
  min-height: 5rem;
  padding: ${STYLING.spacing.s};
  border-radius: var(--border-radius);
  line-height: 1.5;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  overflow: hidden;
`;

export const Submit = styled.button`
  width: 120px;
  height: 40px;
  border-radius: 50px;
  border-style: unset;
  background-color: var(--color-gray3);
  font-size: 18px;
  font-weight: 600;
  color: black;
  text-decoration: none;
  margin-left: auto;

  &:hover {
    cursor: pointer;
    background-color: var(--color-gray4);
    color: white;
  }
`;
