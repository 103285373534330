import { useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

// import ImgHome from 'assets/img/icons/home.svg';
import ImgUpload from 'assets/img/icons/upload.svg';
import ImgInfo from 'assets/img/icons/info.svg';
import ImgDiscord from 'assets/img/icons/discord.svg';
import ImgX from 'assets/img/icons/x.svg';
import { language } from 'helpers/language';

import Onboarding from 'components/onboarding';
import { WalletConnect } from 'components/walletConnect';
import UploadAsset from 'components/uploadAsset';
import UserMenu from 'components/userMenu/UserMenu';
// import WalletList from 'components/walletList';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { openModal } from 'store/modal/slice';
import { doToast } from 'store/toast/thunks';

import * as S from './styles';
import { ReactSVG } from 'react-svg';
import { ASSETS } from 'helpers/config';

function Header(props: any) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const walletAddress = useAppSelector((state: AppState) => state.arweave.walletAddress);
  const connectingWallet = useAppSelector((state) => state.arweave.flags.connectingWallet);
  const [onboarding, setOnboarding] = useState(false);

  const handleUploadButton = () => {
    if (walletAddress) {
      dispatch(openModal(UploadAsset, { onSuccess: (id: string) => navigate(`/entry/${id}`) }));
    } else {
      dispatch(doToast('Wallet not connect', 'Connect your wallet to upload items'));
    }
  };

  return (
    <S.Header>
      <S.HeaderLeft>
        <S.HeaderHome hidden={location.pathname === '/'}>
          <NavLink to="/">
            <ReactSVG src={ASSETS.home} />
          </NavLink>
        </S.HeaderHome>
        <S.HeaderTitle right={location.pathname !== '/'}>Interesting</S.HeaderTitle>
      </S.HeaderLeft>

      <S.HeaderRight id="HeaderRight">
        {walletAddress && <UserMenu />}
        {!walletAddress && <WalletConnect />}
        <S.HeaderIcon>
          <img src={ImgInfo} height="28" />
        </S.HeaderIcon>
        <S.HeaderIcon onClick={handleUploadButton} title={'Upload asset'}>
          <img src={ImgUpload} height="28" />
        </S.HeaderIcon>
      </S.HeaderRight>
    </S.Header>
  );
}

export default Header;
