import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist';

const serializableCheck = {
  ignoredPaths: [
    /^modal.*$/,
    /^upload\.requests\..*\.file$/,
    /^upload\.results\..*\.error$/,
    /^comments\.createError$/,
  ],
  ignoredActions: [
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    'modal/openModal',
    'upload/setFile',
    'upload/uploadFail',
    'comments/createFailed',
  ],
  ignoredActionPaths: [],
};

export default serializableCheck;
