import { createSelector } from '@reduxjs/toolkit';
import { EMPTY_ARRAY } from 'helpers/arrays';

// @obsolete
export const selectContentBySort = createSelector(
  (state: AppState) => state.content.contentById,
  (state: AppState) => state.stamps.stampCountsByDataSource,
  (state: AppState) => state.ui.listSort,
  (contentById, countsById, sort) => {
    switch (sort) {
      case 'hot':
        return Object.values(contentById).sort((a, b) => {
          return countsById[b.node.id]['vouched'] - countsById[a.node.id]['vouched'];
        });

      case 'new':
      case 'rising':
        return Object.values(contentById).sort((a, b) => {
          return b.node.block.timestamp - a.node.block.timestamp;
        });

      default:
        return EMPTY_ARRAY;
    }
  }
);

export const selectContentsByQuery = createSelector(
  (state: AppState) => state.content.contentById,
  (state: AppState) => state.content.byQuery.contentIds,
  (state: AppState, query: string) => query,
  (contentByIds, contentIds, query) => {
    const ids = contentIds[query] || [];
    return ids.map((id) => contentByIds[id]);
  }
);
