import './style.scss';

function ArticleRenderer(props: any) {
  const { data } = props;

  return (
    <div className="renderer--article">
      <div className="renderer--article__text" dangerouslySetInnerHTML={{ __html: data.content }} />
    </div>
  );
}

export default ArticleRenderer;
