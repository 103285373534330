type IContentQueryForTxidsOptions = {
  txids: string[];
  cursor?: string | null | undefined;
  assetTypes?: string[];
  pageSize?: number;
};

export function gqlFetchContent(options: IContentQueryForTxidsOptions) {
  const { txids, cursor, assetTypes, pageSize } = options;

  const variables: any = {
    ids: txids,
    first: pageSize || 10,
    after: cursor,
    tags: [],
  };

  if (assetTypes && assetTypes.length > 0) {
    variables.tags.push({ name: 'Type', values: assetTypes });
  }

  const query = `
    query FETCH_CONTENT($ids: [ID!], $first: Int, $after: String, $tags: [TagFilter!]) {
      transactions(
        ids: $ids,
        first: $first,
        after: $after,
        tags: $tags,
      ) {
        pageInfo { hasNextPage }
        edges {
          cursor
          node {
            id
            owner { address }
            tags { name value }
            block { timestamp }
          }
        }
      }
    }`;


  return { query, variables, operationName: 'FETCH_CONTENT' };

}
