import { createSelector } from '@reduxjs/toolkit';
import { EMPTY_ARRAY } from 'helpers/arrays';

export const selectAssetTypesFromListFilter = createSelector(
  (state: AppState) => state.ui.listTypeFilter,
  (filter) => {
    if (filter.length === 0) {
      return EMPTY_ARRAY as unknown as string[];
    }

    const assetTypes: string[] = [];

    if (filter.includes('Article')) assetTypes.push('Article', 'article');
    if (filter.includes('Document')) assetTypes.push('Document', 'document', 'PDF');
    if (filter.includes('Image')) assetTypes.push('image', 'image/jpeg');
    if (filter.includes('Video')) assetTypes.push('video', 'video/mp4');
    if (filter.includes('Audio')) assetTypes.push('Audio', 'audio', 'audio/*');
    if (filter.includes('Page')) assetTypes.push('web-page', 'social-post');
    if (filter.includes('Spec')) assetTypes.push('spec');

    return assetTypes;
  }
);
