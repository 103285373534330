/**
 * Redux effector routines.
 * https://redux-toolkit.js.org/api/createListenerMiddleware
 */

import { TypedStartListening } from '@reduxjs/toolkit';
import { effResolveMyStampedIds } from 'store/stamps/effects';
import { effAutoDismissToast } from 'store/toast/slice';

import { effectsMiddleware as sdkEffectsMiddleware } from 'permaweb-sdk/dist/implementations/rtk';

type AppStartListening = TypedStartListening<AppState, AppDispatch>;

const effectors = sdkEffectsMiddleware;
const startListening = effectors.startListening as AppStartListening;

startListening(effAutoDismissToast);
startListening(effResolveMyStampedIds);

export default effectors;
