import styled from 'styled-components';
import { STYLING } from 'helpers/config';

export const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  border-radius: var(--border-radius);
  padding: 0 ${STYLING.spacing.s};
  height: 28px;
  margin-left: ${STYLING.spacing.s};

  min-width: 100px;
  margin-left: 0px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;

  span {
    width: 100%;
    margin-left: 6px;
    font-size: 12px;
    line-height: 12px;
  }

  img {
    width: 35px;
    flex-grow: 0;
    align-self: flex-end;
    margin-left: 0;
  }

  .avatar {
    background: gray;
    border: 2px solid #aaa;
    flex-shrink: 0;
  }
`;

export const MenuListProfile = styled.div`
  display: flex;
  padding: ${STYLING.spacing.s};
  gap: ${STYLING.spacing.s};

  .avatar {
    background: gray;
    border: 2px solid #aaa;
  }
`;

export const MenuListProfileHandle = styled.div`
  font-weight: bold;
`;

export const MenuListProfileAddress = styled.div`
  font-family: monospace;
  font-size: 0.75rem;
`;

export const MenuListProfileOthentMail = styled.div`
  font-size: 0.75rem;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
