import React, { HTMLProps } from 'react';
import { Dropdown } from '@mui/base';
import { Popper } from '@mui/base/Popper';
import { useMenu, MenuProvider } from '@mui/base/useMenu';
import { useMenuButton } from '@mui/base/useMenuButton';
import { useMenuItem } from '@mui/base/useMenuItem';
import * as S from './styles';

// ***************************************************************************
// Menu
// ***************************************************************************

interface MenuProps {
  children: React.ReactNode; // Should just be MenuButton and MenuList as children
}

const Menu = (props: MenuProps) => {
  return <Dropdown>{props.children}</Dropdown>;
};

// ***************************************************************************
// MenuButton
// ***************************************************************************

interface MenuButtonProps extends HTMLProps<HTMLButtonElement> {
  className?: string;
}

const MenuButton = React.forwardRef(function MenuButton(props: MenuButtonProps, ref: React.Ref<any>) {
  const { getRootProps: getButtonProps } = useMenuButton({ rootRef: ref });

  return <S.MenuButton {...props} type="button" {...getButtonProps()} />;
});

// ***************************************************************************
// MenuList
// ***************************************************************************

interface MenuListProps {
  className?: string;
  children: React.ReactNode;
}

const MenuList = React.forwardRef(function MenuList(props: MenuListProps, ref: React.Ref<any>) {
  const { className, children, ...other } = props;
  const { open, triggerElement, contextValue, getListboxProps } = useMenu({ listboxRef: ref });

  return (
    <Popper open={open} anchorEl={triggerElement} style={{ zIndex: 10000 }}>
      <S.MenuList {...other} {...getListboxProps()}>
        <MenuProvider value={contextValue}>{children}</MenuProvider>
      </S.MenuList>
    </Popper>
  );
});

// ***************************************************************************
// MenuItem
// ***************************************************************************

interface MenuItemProps {
  children: React.ReactNode;
  onClick: () => void;
}

const MenuItem = React.forwardRef(function MenuItem(props: MenuItemProps, ref: React.Ref<any>) {
  const { children, onClick, ...other } = props;

  const { getRootProps } = useMenuItem({ rootRef: ref });

  return (
    <S.MenuItem {...other} {...getRootProps({ onClick: onClick ?? (() => {}) })}>
      {children}
    </S.MenuItem>
  );
});

// ***************************************************************************
// ***************************************************************************

export { Menu, MenuButton, MenuList, MenuItem };
