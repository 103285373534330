import { createSelector } from '@reduxjs/toolkit';
import { resolveTxTimestampMs } from 'components/contentList/listEntry/helpers';
import { deDuplicate } from 'helpers/arrays';
import { findTag } from 'helpers/tags';
import { ListSort } from 'store/ui/slice';

export const selectHasStamped = createSelector(
  (state: AppState) => state.stamps.myStampedIds,
  (_: any, id: any) => id,
  (myStamps, id) => {
    return myStamps.includes(id);
  }
);

export const selectStampedContentIds = createSelector(
  (state: AppState) => state.stamps.stamps,
  (stamps) => {
    return deDuplicate(stamps.map((stamp) => findTag(stamp?.node?.tags, 'Data-Source') || ''));
  }
);

export const selectStampedContentIdsSorted = createSelector(
  selectStampedContentIds,
  (state: AppState) => state.stamps.stamps,
  (state: AppState) => state.stamps.stampCountsByDataSource,
  (state: AppState, sort: ListSort) => sort,
  (stampedContentIds, stamps, stampCountsById, sort) => {
    switch (sort) {
      case 'hot':
        if (Object.keys(stampCountsById).length === 0 || stampedContentIds.length === 0) {
          return stampedContentIds;
        } else {
          return stampedContentIds.sort((a: TxId, b: TxId) => {
            return stampCountsById[b]['vouched'] - stampCountsById[a]['vouched'];
          });
        }

      default:
      case 'new':
        return stampedContentIds;

      case 'rising':
        // TODO: Add trending algorithm. Currently, it just sorts by "most recently stamped
        return stampedContentIds.sort((a: TxId, b: TxId) => {
          // Assume `stamps` is sorted by timestamp from graphQL query.
          const aIndex = stamps.findIndex((stamp) => findTag(stamp.node.tags, 'Data-Source') === a);
          const bIndex = stamps.findIndex((stamp) => findTag(stamp.node.tags, 'Data-Source') === b);
          return aIndex - bIndex;
        });
    }
  }
);

export const selectStampedContentIdForUser = createSelector(
  (state: AppState) => state.stamps.stamps,
  (state: AppState, userId: WalletId) => userId,
  (stamps, userId) => {
    return stamps
      .filter((stamp) => stamp.node.owner.address === userId)
      .map((stamp) => findTag(stamp.node.tags, 'Data-Source') || '');
  }
);

export const selectStampersForContentIds = createSelector(
  (state: AppState) => state.stamps.stampersForContentId,
  (state: AppState) => state.stamps.vouchedById,
  (_: any, contentIds: string[]) => contentIds,
  (stampersByContentId, vouchedById, contentIds) => {
    const total: string[] = [];
    contentIds.forEach((contentId) => {
      if (stampersByContentId[contentId]) {
        stampersByContentId[contentId].values.forEach((stamper) => {
          total.push(stamper);
        });
      }
    });
    const vouched = total.filter((id) => vouchedById[id]);
    return { total, vouched };
  }
);

export const selectStampersLoadingForContentId = createSelector(
  (state: AppState) => state.stamps.stampersForContentId,
  (_: any, contentId: string) => contentId,
  (stampersByContentId, contentId) => {
    const stampersRecord: { status: string; values: string[] } = stampersByContentId[contentId];
    if (stampersRecord) {
      return stampersRecord.status === 'loading';
    }
    return false;
  }
);
