import styled from 'styled-components';
import { STYLING } from 'helpers/config';

export const ScrollUp = styled.div<{ visible: boolean }>`
  position: sticky;
  top: 0.66667rem;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: var(--color-gray3);
  color: white;
  border-radius: 50%;
  font-size: 28px;
  line-height: 28px;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  border: 2px solid var(--color-background);
  margin-left: auto;

  @media (max-width: $breakpoint-xlarge) {
    position: fixed;
    right: ${STYLING.spacing.s};
    bottom: ${STYLING.spacing.s};
  }

  * > div {
    display: flex;
    justify-content: center;
    align-items: center;
    background: black;
    border-radius: 50%;
    width: 30px;
    height: 30px;

    svg {
      color: white;
      padding: 6px;
      box-sizing: border-box;
      overflow: visible;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: var(--color-gray4);
    * > div {
      background: white;
      svg {
        color: black;
      }
    }
  }
`;
