import { configureStore } from '@reduxjs/toolkit';
import reducer from 'store/reducers';
import effectors from './effectors';
import serializableCheck from './serializableCheck';
import { persistStore } from 'redux-persist';

export const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck }).prepend(effectors.middleware),
});

// @ts-ignore
window.store = store;

export const persistor = persistStore(store);

export type GetState = typeof store.getState;
export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
