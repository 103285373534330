import './style.scss';
import classnames from 'classnames';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import uiSlice, { ListSort } from 'store/ui/slice';

function ListToggle() {
  const listSort = useAppSelector((state) => state.ui.listSort);
  const dispatch = useAppDispatch();

  const getClass = (sort: ListSort) => {
    return classnames('listToggle__entry', { 'listToggle__entry--active': listSort === sort });
  };

  return (
    <div className="listToggle">
      <div className={getClass('new')} onClick={() => dispatch(uiSlice.actions.setListSort('new'))}>
        New
      </div>
      <div className={getClass('rising')} onClick={() => dispatch(uiSlice.actions.setListSort('rising'))}>
        Rising
      </div>
      <div className={getClass('hot')} onClick={() => dispatch(uiSlice.actions.setListSort('hot'))}>
        Hot
      </div>
      <div
        className={classnames('listToggle__marker', {
          'listToggle__marker--right': listSort === 'hot',
          'listToggle__marker--middle': listSort === 'rising',
        })}
      />
    </div>
  );
}

export default ListToggle;
