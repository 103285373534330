import { useAppSelector, useAppDispatch } from 'store/hooks';
import { doStampAsset } from 'store/stamps/thunks';
import { doToast } from 'store/toast/thunks';
import './style.scss';
import { selectHasStamped } from 'store/stamps/selectors';
import classnames from 'classnames';
import Icon from 'components/icon';

interface IProps {
  id: string;
}

const StampButton = (props: IProps) => {
  const dispatch = useAppDispatch();
  const { id } = props;
  const stamped = useAppSelector((state) => selectHasStamped(state, id));
  const stamping = useAppSelector((state) => state.stamps.idsBeingStamped.includes(id));

  const handleStamp = (id: string, amount?: number) => {
    dispatch(doStampAsset(id, amount))
      .then(() => dispatch(doToast('Item stamped', id)))
      .catch((e) => dispatch(doToast('Failed to stamp item', e.toString())));
  };

  return (
    <button
      className={classnames('stampButton', { 'stampButton--stamping': stamping, 'stampButton--stamped': stamped })}
      onClick={(e: any) => {
        e.stopPropagation();
        handleStamp(id);
      }}
      disabled={stamping}
      aria-disabled={stamping}
    >
      <Icon icon={'upvote'} size={24} height={26} />
    </button>
  );
};

export default StampButton;
