import { STYLING } from 'helpers/config';
import styled, { keyframes } from 'styled-components';

export const Header = styled.header`
  position: fixed;
  display: flex;
  width: 100%;
  padding: 10px;
  z-index: 99;
  box-sizing: border-box;

  background-image: linear-gradient(
    to bottom,
    var(--color-background) 20%,
    rgba(var(--color-background-base), 0.7) 60%,
    rgba(255, 255, 255, 0.3) 100%
  );
  backdrop-filter: blur(10px);

  @media (min-width: ${STYLING.cutoffs.medium}) {
    border-bottom: 2px solid var(--color-background);
  }
`;

export const HeaderLeft = styled.div`
  display: flex;
  flex: 1;
  margin-left: ${STYLING.spacing.s};
`;

export const HeaderHome = styled.div<{ hidden: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 52px;
  width: 32px;
  height: 32px;
  background: black;
  top: 10px;
  border-radius: 50%;
  opacity: ${(props) => (props.hidden ? 0 : 1)};
  pointer-events: ${(props) => (props.hidden ? 'none' : 'default')};
  transition: opacity 0.4s;

  svg {
    margin-top: 5px;
    padding: 4px;
    width: 22px;
    // filter: invert(100%);
  }
`;

export const HeaderTitle = styled.div<{ right: boolean }>`
  font-family: 'Alfa Slab One';
  font-size: 32px;
  margin-left: ${(props) => (props.right ? '82px' : '44px')};
  margin-top: -3px;
  transition: margin-left 0.4s;
`;

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  margin-right: ${STYLING.spacing.s};
  display: flex;
  flex-flow: row-reverse;
`;

export const HeaderWallet = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  background-color: black;
  color: white;
  border-radius: 20px;
  margin-left: 10px;
  padding: 0 ${STYLING.spacing.s};
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  opacity: ${(props) => (props.disabled ? 0.7 : 1)};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'default')};

  &:hover {
    cursor: pointer;
  }
`;

export const HeaderIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-left: ${STYLING.spacing.s};
  z-index: 99;

  img {
    width: 30px;
    user-select: none;
  }

  &:hover {
    cursor: pointer;
    outline: 4px solid rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
