export async function fileToBuffer(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function (e: any) {
      const buffer = new Buffer(e.target.result);
      resolve(buffer);
    };

    reader.onerror = function (e: any) {
      reject(e);
    };

    reader.readAsArrayBuffer(file);
  });
}

export async function fileToDataUrl(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function (e: any) {
      resolve(e.target.result);
    };

    reader.onerror = function (e: any) {
      reject(e);
    };

    reader.readAsDataURL(file);
  });
}
