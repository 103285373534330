import React from 'react';
import cn from 'classnames';
import TopBanner from 'components/topBanner';

import ContentList from 'components/contentList';
// import Spotlight from 'components/spotlight';
import Icon from 'components/icon';
import Loading from 'components/loading';
import useGetCurrentBlockHeight from 'hooks/useGetCurrentBlockHeight';
// import Navigation from 'components/navigation';

import { selectAssetTypesFromListFilter } from 'store/ui/selectors';
import New from './new';
import { doFetchContent, getContentQueryKey, IFetchContentOptions } from 'store/content/thunks';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useDebouncedCallback } from 'use-debounce';
import { selectContentsByQuery } from 'store/content/selectors';

import ContentListHeader from 'components/contentListHeader';

// import ImgFilter from 'assets/img/icons/filter.svg';

function Home() {
  const blockHeight = useGetCurrentBlockHeight('standard');
  const assetTypes = useAppSelector(selectAssetTypesFromListFilter);
  const days = useAppSelector((state) => state.ui.listRangeDays);
  const dispatch = useAppDispatch();
  const listType = useAppSelector((state) => state.ui.listSort);

  const [showFilters, setShowFilters] = React.useState(true);

  const options: IFetchContentOptions = {
    sort: listType,
    assetTypes: assetTypes,
    days: days,
  };
  const optionsStringified = JSON.stringify(options);

  const query = getContentQueryKey(options);
  const cursor = useAppSelector((state) => state.content.byQuery.cursor[query]);
  const isFetching = useAppSelector((state) => state.content.byQuery.isFetching[query]);
  const reduxList = useAppSelector((state) => selectContentsByQuery(state, query));

  // Temp until unified via SDK
  const [newData, setNewData] = React.useState<{ list: any[]; isLoading: boolean }>({ list: [], isLoading: true });

  const contentList = listType === 'new' ? newData.list : reduxList;
  const isLoading = listType === 'new' ? newData.isLoading : isFetching;

  const fetchContent = () => dispatch(doFetchContent(options));

  const fetchContentDebounced = useDebouncedCallback(() => fetchContent(), 300);

  const handleFetchMore = () => {
    if (cursor === null) {
      return; // No more to fetch
    } else if (isFetching) {
      return; // Already fetching
    } else {
      if (listType !== 'new') {
        fetchContentDebounced();
      }
    }
  };

  const handleNewUpdated = React.useCallback((data: { list: any[]; isLoading: boolean }) => {
    setNewData(data);
  }, []);

  React.useEffect(() => {
    fetchContent();
  }, [optionsStringified]);

  if (blockHeight === undefined) {
    return <Loading loading={true} />;
  }

  return (
    <>
      <TopBanner entries={contentList} />

      <ContentListHeader />
      <div className="contentColumns">
        {/* <Navigation show={showFilters} showTitle={false} /> */}
        {listType === 'new' && <New onUpdate={handleNewUpdated} blockHeight={blockHeight || 0} />}
        {listType === 'rising' && <ContentList key={listType} list={contentList} action={handleFetchMore} />}
        {listType === 'hot' && <ContentList key={listType} list={contentList} action={handleFetchMore} />}
      </div>
      <Loading loading={isLoading} />
    </>
  );
}

export default Home;
