import React from 'react';
import { ReactSVG } from 'react-svg';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import ListToggle from 'components/listToggle';
import TimeToggle from 'components/timeToggle';
import ScrollUp from 'components/scrollUp';
import Navigation from 'components/navigation';
import { ASSETS } from 'helpers/config';
import { STYLING } from 'helpers/config';
import { onWindowResize, checkWindowCutoff } from 'helpers/window';
import * as S from './styles';

function ContentListHeader(props: any) {
  const listType = useAppSelector((state) => state.ui.listSort);
  const [desktop, setDesktop] = React.useState(true);
  const [showFilters, setShowFilters] = React.useState(false);

  function handleWindowResize() {
    if (checkWindowCutoff(parseInt(STYLING.cutoffs.medium))) {
      setDesktop(true);
    } else {
      setDesktop(false);
    }
  }

  onWindowResize(handleWindowResize);
  React.useEffect(() => {
    handleWindowResize();
  }, []);

  return (
    <>
      <S.ContentListHeader id="ContentListHeader" desktop={desktop} filters={showFilters}>
        <S.ContentListHeaderItems>
          {!desktop && (
            <S.Filter onClick={() => setShowFilters(!showFilters)} active={showFilters}>
              <ReactSVG src={ASSETS.filter} />
            </S.Filter>
          )}
          <ListToggle />
          <TimeToggle hide={listType === 'new'} />
          <ScrollUp />
        </S.ContentListHeaderItems>
        {!desktop && (
          <S.NavigationWrapper visible={showFilters}>
            <Navigation desktop={desktop} />
          </S.NavigationWrapper>
        )}
      </S.ContentListHeader>
    </>
  );
}

export default ContentListHeader;
