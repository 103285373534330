import React from 'react';
import { doFetchComments, getCommentQueryKey } from 'permaweb-sdk/dist/implementations/rtk';

import CommentView from 'components/commentView';
import { useAppDispatch, useAppSelector } from 'store/hooks';
// import './style.scss';
import * as S from './styles';

interface Props {
  assetId: string;
  parentId?: string;
  threadId?: string;
}

const CommentList = (props: Props) => {
  const { assetId, parentId, threadId } = props;
  const dispatch = useAppDispatch();
  const queryKey = getCommentQueryKey(assetId, parentId || assetId, threadId || parentId || assetId);

  const commentIds = useAppSelector((state) => state.comments.byQuery.commentIds[queryKey]);
  const hasMore = useAppSelector((state) => Boolean(state.comments.byQuery.cursor[queryKey]));
  const numComments = useAppSelector((state) => state.comments.byQuery.total[queryKey]);
  const isReply = !!parentId && assetId !== parentId;

  const [prevNumComments, setPrevNumComments] = React.useState(numComments);

  const scrollRef = React.useRef<HTMLDivElement>(null);

  const fetchComments = React.useCallback(() => {
    dispatch(doFetchComments(assetId, parentId || assetId, threadId || parentId || assetId));
  }, [assetId, parentId, threadId, dispatch]);

  React.useEffect(() => {
    fetchComments();
  }, [dispatch, fetchComments]);

  React.useEffect(() => {
    if (prevNumComments !== numComments) {
      setPrevNumComments(numComments);
      scrollRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [prevNumComments, numComments]);

  return (
    <S.CommentList ref={scrollRef}>
      <S.CommentListHeader>{/* !isReply && <span>Comments {numComments}</span> */}</S.CommentListHeader>
      <S.CommentListContainer>
        {commentIds?.map((id: string) => <CommentView key={id} commentId={id} />)}
        {hasMore && <S.LoadMore onClick={fetchComments}>More</S.LoadMore>}
      </S.CommentListContainer>
    </S.CommentList>
  );
};

export default CommentList;
