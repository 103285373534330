import styled, { keyframes } from 'styled-components';

export const EntryPage = styled.div`
  position: relative;
  iframe {
    width: 100%;
    min-height: 600px;
    border: 0;
  }
`;
