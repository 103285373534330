import React, { useEffect } from 'react';
import { doFetchTotalCommentsPerAsset, selectContentByQueryKey } from 'permaweb-sdk/dist/implementations/rtk';
import { AssetType } from 'permaweb-sdk/dist/helpers/types';

import ListEntry from 'components/contentList/listEntry';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import * as S from './styles';
interface Props {
  /** The query key for the data in assetSlice to represent. */
  queryKey: string;
  /** Parent-component callback to handle fetching more assets for the paginated list. */
  onFetchMore?: () => void;
}

function AssetList(props: Props) {
  const { queryKey, onFetchMore } = props;
  const dispatch = useAppDispatch();
  const assets = useAppSelector((state) => selectContentByQueryKey(state, queryKey)) as Array<AssetType>;
  const onFetchMoreRef = React.useRef(onFetchMore);

  useEffect(() => {
    // Handles the stale-closure in the scroll handler that captures an old
    // version of onFetchMore.
    // [] Effect necessary? or just update in render?
    onFetchMoreRef.current = onFetchMore;
  }, [onFetchMore]);

  useEffect(() => {
    const handleScroll = () => {
      const docElem = document.documentElement;
      const scrolledToBottom = docElem.scrollTop + window.innerHeight >= docElem.scrollHeight - window.innerHeight;
      if (scrolledToBottom && onFetchMoreRef.current) {
        onFetchMoreRef.current();
      }
    };

    document.addEventListener('scroll', handleScroll);
    return () => document.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (assets.length > 0) {
      dispatch(doFetchTotalCommentsPerAsset(assets.map((ass) => ass.id)));
    }
  }, [assets]);

  return (
    <S.AssetList>
      {assets &&
        assets.map((asset: AssetType, i: any) => {
          return <ListEntry key={i} asset={asset} bannerTag={undefined} timestampMs={asset.dateCreated * 1000} />;
        })}
    </S.AssetList>
  );
}

export default AssetList;
