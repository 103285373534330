import { getCommentQueryKey } from 'permaweb-sdk/dist/implementations/rtk';

import CommentCreate from 'components/comments/commentCreate';
import CommentList from 'components/comments/commentList';
import { useAppSelector } from 'store/hooks';

import * as S from './styles';

function Comments(props: any) {
  const { assetId, parentId, threadId } = props;
  const queryKey = getCommentQueryKey(assetId, parentId || assetId, threadId || parentId || assetId);

  const numComments = useAppSelector((state) => state.comments.byQuery.total[queryKey]);

  return (
    <S.Comments>
      {numComments ? <h3>{numComments} Comments</h3> : <h3>Leave a comment</h3>}
      <CommentCreate assetId={assetId || ''} parentId={assetId} />
      <CommentList assetId={assetId || ''} />
    </S.Comments>
  );
}

export default Comments;
