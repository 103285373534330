import styled from 'styled-components';

export const Avatar = styled.div<{ type: string; stack: boolean }>`
  display: flex;
  flex-shrink: 0;
  width: ${(props) => (props.type === 'default' ? '50px' : '20px')};
  height: ${(props) => (props.type === 'default' ? '50px' : '20px')};
  border: 2px solid black;
  border-radius: 50%;
  background-color: black;
  margin-left: ${(props) => (props.stack ? '-10px' : 0)};

  img,
  svg {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    color: white;
    flex-shrink: 0;
  }

  &:hover {
    z-index: 9;
  }
`;
