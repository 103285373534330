export function getStampsQuery(cursor?: string) {
  return `
    query {
      transactions(
        first: 100, 
        ${cursor ? `after: "${cursor}",` : ''}
        tags: [
          { name: "Protocol-Name", values: ["Stamp"] }
        ]
      ) 
      {
        edges {
          node {
            id
            owner { address }
            tags { name value }
            block { height, timestamp }
          }
          cursor
        }
        pageInfo {
          hasNextPage
        }
      }
    }
  `;
}

export function gqlStampById(id: TxId) {
  return `
    query STAMP_BY_ID {
      transactions(
        ids: ["${id}"], 
        first: 1, 
      ) 
      {
        edges {
          node {
            id
            owner { address }
            tags { name value }
            block { height, timestamp }
          }
          cursor
        }
        pageInfo { hasNextPage }
      }
    }
  `;
}
