import React from 'react';

export default function useObjectURL(imgFile: File | null) {
  const [src, setSrc] = React.useState('');

  React.useEffect(() => {
    if (imgFile) {
      const objectUrl = URL.createObjectURL(imgFile);
      setSrc(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    } else {
      setSrc('');
    }
  }, [imgFile]);

  return src;
}
