import { throwIf } from 'helpers/error';
import { CONTENT_TYPES, TAGS } from 'permaweb-sdk/dist/helpers/config';
import { AssetCreateArgsType } from 'permaweb-sdk/dist/helpers/types';
import { Ans110Types } from 'permaweb-sdk/dist/helpers/typesAns110';
import { doCreateAsset } from 'permaweb-sdk/dist/implementations/rtk';
import { doToast } from 'store/toast/thunks';
import { ContentType } from 'store/ui/slice';

interface Args {
  title: string | undefined;
  description: string | undefined;
  type: Ans110Types | undefined;
  files: any[];
}

export function doSubmit(args: Args) {
  return async (dispatch: AppDispatch, getState: GetState): Promise<string> => {
    const state = getState();
    const { title, description, type, files } = args;

    // This extra promise is due to doCreateAsset returning results in
    // handler rather than promise.
    return new Promise<string>(async (resolve, reject) => {
      try {
        throwIf(!state.arweave.walletAddress, 'Wallet not connected.');
        throwIf(!title, 'Please enter a title.');
        throwIf(!description, 'Please enter a description.');
        throwIf(!type, 'Please select a type.');
        throwIf(!files || !files.length, 'Please select a file.');

        const MAX_EDITOR_LENGTH = 280;

        const args: AssetCreateArgsType = {
          appTitle: 'Interesting',
          appType: 'Social',
          appCategory: 'Community',
          content: description!,
          contentType: CONTENT_TYPES.textPlain,
          title: title!,
          description: description!.slice(0, MAX_EDITOR_LENGTH),
          type: type!,
          topics: [type!],
          owner: state.arweave.walletAddress!,
          ticker: 'ATOMIC ASSET',
          dataProtocol: null,
          dataSource: null,
          dataRootSource: undefined,
          renderWith: null,
          uploadMethod: 'default',
        };

        if (files && files.length) {
          args['dataUrls'] = files;
        }

        await dispatch(doCreateAsset(args, handlePostSuccess, handlePostFailure));
      } catch (e: any) {
        reject(e);
      }

      function handlePostSuccess(_id: string) {
        resolve(_id);
      }

      function handlePostFailure(message: string) {
        reject(new Error(message));
      }
    });
  };
}
