import styled from 'styled-components';
import { STYLING } from 'helpers/config';

export const App = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
`;

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  background: var(--color-background);
  padding: ${STYLING.spacing.m};
  padding-top: 0px;
  margin-top: 64px;
`;

export const Navigation = styled.div`
  padding-left: ${STYLING.spacing.s};
  min-width: 270px;
  box-sizing: border-box;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(${STYLING.cutoffs.large} - (270px * 2));
  max-width: 100%;
  padding: 0 ${STYLING.spacing.s};
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
`;

export const Space = styled.div`
  min-width: 270px;
`;

export const Theme = styled.div`
  position: fixed;
  top: 10px;
  width: 32px;
  height: 32px;
  left: ${STYLING.spacing.s};
  z-index: 999;
  user-select: none;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    svg {
      width: 32px;
      height: 32px;
      margin-top: 7px;
      margin-left: 4px;
      stroke: black;
    }
  }

  &:hover {
    cursor: pointer;
  }
`;

export const ThemeBackdrop = styled.div<{ activeTheme: boolean }>`
  position: fixed;
  top: 10px;
  left: ${STYLING.spacing.s};
  width: 32px;
  height: 32px;
  transform: ${(props) => (props.activeTheme ? 'scale(1)' : 'scale(350)')};
  transition: transform 1s;
  backdrop-filter: invert(100%);
  border-radius: 50%;
  pointer-events: ${(props) => (props.activeTheme ? 'default' : 'none')};
`;

export const ThemeBright = styled.div<{ activeTheme: boolean }>`
  position: fixed;
  top: 10px;
  left: ${STYLING.spacing.s};
  width: 32px;
  height: 32px;
  display: ${(props) => (props.activeTheme ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 50%;
  z-index: 999;
  user-select: none;

  * > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;

    svg {
      width: 32px;
      height: 32px;
      margin-top: 7px;
      margin-left: 4px;
      color: black;
    }
  }

  &:hover {
    cursor: pointer;
  }
`;
