import styled from 'styled-components';

import { STYLING } from 'helpers/config';

export const WalletListContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  flex-direction: column;
  box-sizing: border-box;
`;

export const WalletListItems = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 30px 0;
  gap: 20px;
`;

export const WalletListItem = styled.button`
  // height: 70px;
  width: 100px;
  padding: 14px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-gray2);
  border-radius: ${STYLING.dimensions.radius.primary};
  &:hover {
    cursor: pointer;
    background-color: var(--color-gray1);
  }
  img {
    height: 30px;
    margin-bottom: 6px;
  }
  span {
    color: var(--color-text);
    font-size: ${STYLING.font.size.m};
    font-weight: ${STYLING.font.weight.l};
  }
`;

export const WalletListCrossSiteCookie = styled.div`
  display: flex;
  align-items: center;
  font-size: smaller;
  padding: 20px;
  background: rgba(255, 155, 0, 0.1);
  border: 2px solid rgba(255, 155, 0, 0.2);
  border-radius: ${STYLING.dimensions.radius.primary};

  svg {
    width: 30px;
    height: 30px;
    margin-right: 20px;
  }

  b {
    display: block;
    margin: 0 0 2.5px 0;
  }

  p {
    color: var(--color-text);
  }
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  border-radius: ${STYLING.dimensions.radius.primary};
  backdrop-filter: blur(10px);
  z-index: 9;
`;
