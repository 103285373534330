import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { AssetType } from 'permaweb-sdk/dist/helpers/types';
// import './style.scss';

import ImgComments from 'assets/img/icons/comments.svg';
import { useAppSelector } from 'store/hooks';
import { naToUndefined, resolveBanner } from './helpers';
import StampButton from 'components/stampButton/stampButton';
import Avatar from 'components/avatar';
import Icon from 'components/icon';
import { selectStampersForContentIds, selectStampersLoadingForContentId } from 'store/stamps/selectors';
import { getType } from 'components/renderer/helpers';
import MetaRenderer from 'components/renderer/metaRenderer';
import * as S from './styles';

interface Props {
  asset: AssetType;
  bannerTag: string | undefined;
  timestampMs: number | undefined;
}

const ListEntry = (props: Props) => {
  const { asset, bannerTag, timestampMs } = props;
  const [expanded, setExpanded] = React.useState(false);
  const id = asset.id;
  const navigate = useNavigate();
  const idInList = React.useMemo(() => {
    return [id];
  }, [id]);

  const { total, vouched } = useAppSelector((state) => selectStampersForContentIds(state, idInList));
  // TODO select stampers for id
  const stampersLoading = useAppSelector((state: AppState) => selectStampersLoadingForContentId(state, id));

  const title = naToUndefined(asset.title);
  const description = naToUndefined(asset.description);
  const type = naToUndefined(asset.type);
  const dataProtocol = naToUndefined(asset.dataProtocol);
  const stampCounts = useAppSelector((state) => state.stamps.stampCountsByDataSource[id]);
  const banner = resolveBanner(bannerTag, asset.mediaIds);

  // Hide "empty" assets
  if (!title && !type) return;
  // Hide reposts
  if (type === 'repost-only') return;
  // Hide social posts without media
  if (type === 'social-post' && !naToUndefined(asset.mediaIds)) return;

  const date =
    timestampMs &&
    new Date(timestampMs)
      .toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })
      .replace(/\//g, '.');

  const owner = naToUndefined(asset.creator.walletAddress);
  var renderWith = naToUndefined(asset.renderWith);
  if (renderWith && renderWith.includes('[')) {
    renderWith = JSON.parse(renderWith)[0];
  }

  const profile = useAppSelector((state) => state.profiles.byWalletId[owner || '']);
  const totalComments: number | undefined | 'pending' = useAppSelector((state) => state.comments.totalByAssetId[id]);

  React.useEffect(() => {
    if (type) {
      const assetType = getType(type);
      if (assetType === 'image') setExpanded(true);
      else if (assetType === 'html') setExpanded(true);
      else if (assetType === 'video') setExpanded(true);
      else if (assetType === 'audio') setExpanded(true);
      else if (assetType === 'spec') setExpanded(true);
      else if (assetType === 'page') setExpanded(true);
      else if (assetType === 'post') setExpanded(true);
      else if (assetType === 'article') setExpanded(true);
      else if (assetType === 'token') setExpanded(true);
      else if (dataProtocol === 'Collection') setExpanded(true);
      else setExpanded(false);
    }
  }, [type, dataProtocol]);

  return (
    <>
      <S.ListEntry onClick={() => navigate(`/entry/${id}`)}>
        <S.Stamps>
          <S.StampActions onClick={(e) => e.stopPropagation()}>
            <StampButton id={id} />
            {stampCounts ? stampCounts.vouched : 0}
            <Icon icon="downvote" size={24} height={26} />
          </S.StampActions>
          <S.Stampers onClick={(e) => e.stopPropagation()}>
            {!vouched.length && stampersLoading && <Avatar walletAddr={'loading'} small stack />}
            {vouched &&
              vouched.slice(0, 5).map((stamper: string, i: any) => {
                if (i === 4)
                  return (
                    <div key={i} className="listEntry__stampersPlus">
                      +
                    </div>
                  );
                return <Avatar key={i} walletAddr={stamper} small stack />;
              })}
          </S.Stampers>
          <NavLink to={`/entry/${id}`}>
            <S.Comments>
              <img src={ImgComments} /> {Number(totalComments) || 0}
            </S.Comments>
          </NavLink>
        </S.Stamps>
        <S.Content>
          <S.Header>
            <S.User
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/profile/${owner}`);
              }}
            >
              {asset ? (
                <>
                  <Avatar walletAddr={owner!} />
                  {profile?.handle || 'Anonymous'}
                </>
              ) : (
                <div />
              )}
            </S.User>
            <S.Date>{date || '[dd.mm.yyyy]'}</S.Date>
            <S.Type>{type || '[TYPE]'}</S.Type>
          </S.Header>
          <S.Primary>
            <div className={`listEntry__text${asset && !banner ? ' listEntry__text--wide' : ''}`}>
              <h3>{title}</h3>
              {/* process.env.NODE_ENV === 'development' && <span>{id}</span> */}
              <p>{description}</p>
            </div>
            {asset && banner && title && (
              <S.Thumbnail>
                <img
                  src={`https://arweave.net/${banner}`}
                  className="flip"
                  onError={(e) => ((e.target as HTMLImageElement).style.display = 'none')}
                />
              </S.Thumbnail>
            )}
          </S.Primary>
          <S.Renderer onClick={(e) => e.stopPropagation()}>
            {id && type && <MetaRenderer asset={asset} type={getType(type)} address={id} expanded={expanded} />}
          </S.Renderer>
        </S.Content>
      </S.ListEntry>
    </>
  );
};

export default ListEntry;
