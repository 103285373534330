import Stamp, { StampJS } from '@permaweb/stampjs';
import { WarpFactory } from 'warp-contracts';
import { InjectedArweaveSigner } from 'warp-contracts-plugin-signature';
import Ar from './ar';
// ****************************************************************************
// StampMgr
// ****************************************************************************

class StampMgr {
  public static stamp: StampJS;

  public static fetchVouched: any;
  private constructor() {}

  static async initialize(gateway: string, walletAddress: string | null, dre?: string, warp?: string) {
    if (dre) {
      this.fetchVouched = async () =>
        await fetch(`${dre}/?id=_z0ch80z_daDUFqC9jHjfOL8nekJcok4ZRkE_UesYsk&query=$.vouched`)
          .then((r) => r.json())
          .then(({ result, stateHash }) => {
            let keys = Object.keys(result[0]);
            let res = keys.reduce((acc: { [key: string]: boolean }, cur) => {
              acc[cur] = true;
              return acc;
            }, {});
            return { result: res, stateHash };
          });
    } else if (warp) {
      // TODO implement warp version
      // @ts-ignore
      this.fetchVouched = (warp) => async () => {};
    }

    // this.fetchVouches =
    let signer = null;
    if (window.arweaveWallet && walletAddress) {
      const signer = new InjectedArweaveSigner(window.arweaveWallet); // Required if you are using Warp v1.4.11 or greater
      signer.getAddress = window.arweaveWallet.getActiveAddress;
      await signer.setPublicKey();
    }
    try {
      this.stamp = Stamp.init({
        warp: warp || WarpFactory.forMainnet(),
        arweave: Ar.getInstance('standard'),
        wallet: signer,
        dre: dre || 'https://dre-u.warp.cc/contract',
        graphql: `https://${gateway}/graphql`,
      });
    } catch (e) {
      console.error(e);
    }
  }

  public static getLib() {
    return this.stamp;
  }

  public static getVouchedFetcher() {
    return this.fetchVouched;
  }

  public static async getStampersForDataSourceIds(ids: string[]) {
    // This returns stamps regardless of vouching.
    // TODO: add vouched filter
    const getQuery = (cursor?: string) => {
      return `
    query {
      transactions(
        first: 100,
        ${cursor ? `after: "${cursor}",` : ''}
        tags: [
          { name: "Data-Source", values: ["${ids.join('","')}"] }
        ]
      ) 
      {
        edges {
          node {
            id
            owner { address }
            tags { name value }
            block { height }
          }
          cursor
        }
        pageInfo {
        hasNextPage
        }
      }
    }
  `;
    };
    const edges = [] as Array<IEdge>;
    let hasNextPage = false;
    let cursor = '';
    const data = await Ar.fetchGraphQL(getQuery());

    let edgesLength = data.transactions.edges.length;
    hasNextPage = data.transactions.pageInfo.hasNextPage;
    cursor = data.transactions.edges[edgesLength - 1].cursor;
    edges.splice(edges.length, 0, ...data.transactions.edges);
    while (hasNextPage) {
      let newData = await Ar.fetchGraphQL(getQuery(cursor));
      hasNextPage = newData.transactions.pageInfo.hasNextPage;
      edgesLength = newData.transactions.edges.length;
      cursor = newData.transactions.edges[edgesLength - 1].cursor;
      edges.splice(edges.length, 0, ...newData.transactions.edges);
    }

    const stampersByContentId = edges.reduce((acc: { [key: string]: Array<string> }, cur: { node: INode }) => {
      if (!cur || !cur.node || !cur.node.tags) {
        return acc;
      }
      const tags = cur.node.tags;
      const dataSourceTag = tags.find((t: ITag) => t.name === 'Data-Source');
      const curDataSrc = dataSourceTag ? dataSourceTag.value : null;
      const curOwner = cur.node.owner.address;
      if (!curDataSrc) {
        return acc;
      }
      if (acc[curDataSrc]) {
        if (!acc[curDataSrc].includes(curOwner)) {
          acc[curDataSrc].push(curOwner);
        }
      } else {
        acc[curDataSrc] = [curOwner];
      }
      return acc;
    }, {});
    return stampersByContentId;
  }
  public static async getHasStampedForIds(ids: string[], walletAddress: string | null) {
    if (!walletAddress) {
      return [];
    }
    let myStampsObj;
    // this function returns:
    // bool if ids is an array with only one item
    // { [key: TxId]: boolean} if ids.lenght > 1
    // so gymnastics below.

    const myStampsRes = await this.stamp.hasStamped(ids as string[]);
    if (typeof myStampsRes === 'boolean') {
      myStampsObj = { [ids[0]]: myStampsRes };
    } else {
      myStampsObj = myStampsRes;
    }
    const myStamps = Object.entries(myStampsObj).reduce((acc: any, cur) => {
      if (cur[1] === true) {
        acc.push(cur[0]);
      }
      return acc;
    }, []);
    return myStamps;
  }
}

export default StampMgr;
