import React from 'react';
import classnames from 'classnames';
import { useParams } from 'react-router-dom';
import { doFetchFollowers, doFetchFollowings, doFetchProfiles } from 'permaweb-sdk/dist/implementations/rtk';

import Avatar from 'components/avatar';
import { Tab, TabPanel, Tabs, TabsList } from 'components/base/tabs';
import FollowButton from 'components/followButton';
import FollowList from 'components/followList';
import ProfileEdit from 'components/profileEdit';
import ProfilePosts from 'components/profilePosts';
import ProfileStamps from 'components/profileStamps';
import AccountMgr from 'helpers/arweave/accountMgr';
import { arToHttp } from 'helpers/url';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { openModal } from 'store/modal/slice';
import { doFetchRecentStamps, doFetchStampersForContent } from 'store/stamps/thunks';

import * as S from './styles';

function ProfilePage() {
  const { id = '' } = useParams();
  const dispatch = useAppDispatch();

  const [bannerOk, setBannerOk] = React.useState(true);
  const profile = useAppSelector((state) => state.profiles.byWalletId[id]);
  const gateway = useAppSelector((state) => state.arweave.standardGateway);

  const name = AccountMgr.resolveName(profile);
  const handle = AccountMgr.resolveHandle(profile);
  const idIsMe = useAppSelector((state) => state.arweave.walletAddress === id);

  const followings = useAppSelector((state) => state.follow.followings[id]);
  const followingsCount = followings?.length;

  const followers = useAppSelector((state) => state.follow.followers[id]);
  const followersCount = followers?.length;

  const editProfile = () => dispatch(openModal(ProfileEdit, {}));

  const openFollowings = () => dispatch(openModal(FollowList, { id, type: 'Followings', viewFormat: 'modal' }));

  const openFollowers = () => dispatch(openModal(FollowList, { id, type: 'Followers', viewFormat: 'modal' }));

  React.useEffect(() => {
    if (!profile && id) {
      dispatch(doFetchProfiles([id]));
    }
  }, [profile, id]);

  React.useEffect(() => {
    if (!followingsCount && id) {
      dispatch(doFetchFollowings(id));
    }
  }, [followingsCount, id]);

  React.useEffect(() => {
    if (!followersCount && id) {
      dispatch(doFetchFollowers(id));
    }
  }, [followersCount, id]);

  React.useEffect(() => {
    const fallbackStampFetch = async (dispatch: AppDispatch, getState: GetState): Promise<void> => {
      // [] Kludge to fetch stamps when Profile Page is entered directly.
      // The fetch should probably be moved to the app-init function, but then
      // it would make the app slower to start.
      const state = getState();
      if (state.stamps.stampsDayRange === 0 && !state.stamps.fetchingStamps) {
        await dispatch(doFetchRecentStamps(7));
        await dispatch(doFetchStampersForContent(['all']));
      }
    };
    dispatch(fallbackStampFetch);
  }, []);

  if (!profile) {
    return (
      <S.ProfilePage>
        <S.Spinner />
      </S.ProfilePage>
    );
  }

  return (
    <S.ProfilePage>
      <S.Section>
        <S.Header>
          <S.Banner>
            {bannerOk && <img src={arToHttp(profile.banner || '', gateway)} onError={() => setBannerOk(false)} />}
          </S.Banner>
          <S.AvatarWrapper>
            <Avatar walletAddr={profile.walletAddress} />
          </S.AvatarWrapper>
        </S.Header>
      </S.Section>

      <S.Section>
        <div>
          <S.Name>{name}</S.Name>
          <S.Handle>@{handle}</S.Handle>
          {profile.bio && <S.Bio>{profile.bio}</S.Bio>}
          <S.Stats>
            <S.Stat none={followingsCount === 0} onClick={openFollowings}>
              <S.Value>{followingsCount}</S.Value>
              <S.Label>Following</S.Label>
            </S.Stat>
            <S.Stat none={followersCount === 0} onClick={openFollowers}>
              <S.Value>{followersCount}</S.Value>
              <S.Label>Followers</S.Label>
            </S.Stat>
          </S.Stats>
        </div>
        <S.Actions>
          <FollowButton address={id} />
          <S.ButtonEdit hide={!idIsMe} onClick={editProfile}>
            Edit Profile
          </S.ButtonEdit>
        </S.Actions>
      </S.Section>

      <S.Section>
        <Tabs defaultValue={0}>
          <TabsList>
            <Tab value={0}>Explore</Tab>
            <Tab value={1}>Stamps</Tab>
          </TabsList>
          <TabPanel value={0}>
            <ProfilePosts address={profile.walletAddress} />
          </TabPanel>
          <TabPanel value={1}>
            <ProfileStamps address={profile.walletAddress} />
          </TabPanel>
        </Tabs>
      </S.Section>
    </S.ProfilePage>
  );
}

export default ProfilePage;
