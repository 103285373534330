import React from 'react';
import './style.scss';

function ImageRenderer(props: any) {
  const { asset, file } = props;
  const [imageId, setImageId] = React.useState(asset.fileId ? asset.fileId : asset.id);

  React.useEffect(() => {
    if (file) {
      setImageId(file.fileTxId);
    }
  }, [file]);

  return (
    asset && (
      <div className="renderer--image">
        <div className="imageWrapper">
          <div
            style={{
              backgroundImage: `url('https://arweave.net/${imageId}')`,
            }}
          />
          <img src={`https://arweave.net/${imageId}`} alt="Preview" />
        </div>
      </div>
    )
  );
}

export default ImageRenderer;
