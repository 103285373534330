import styled from 'styled-components';
import { STYLING } from 'helpers/config';

export const TimeToggle = styled.div<{ hidden: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  width: 98px;
  height: 40px;
  border-radius: 50px;
  background-color: var(--color-gray3);
  color: black;
  padding: 4px;
  box-sizing: border-box;
  z-index: 0;
  user-select: none;
  opacity: ${(props) => (props.hidden ? 0 : 1)};
  transition: opacity 0.2s;
  outline: 2px solid var(--color-background);
  margin-left: ${STYLING.spacing.s};
  pointer-events: ${(props) => (props.hidden ? 'none' : 'default')};

  .timeToggle__entry {
    position: absolute;
    font-size: 16px;
    font-weight: 600;
    z-index: 1;
    transition: color 0.4s;

    &:nth-of-type(1) {
      left: 15px;
    }
    &:nth-of-type(2) {
      right: 13px;
    }

    &.timeToggle__entry--active {
      color: white;
      &:hover {
        cursor: default;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
`;

export const TimeToggleMarker = styled.div<{ month: boolean }>`
  position: absolute;
  width: ${(props) => (props.month ? '48px' : '43px')};
  height: 30px;
  background-color: black;
  border-radius: 50px;
  z-index: 0;
  left: ${(props) => (props.month ? '46px' : '4px')};
  transition:
    left 0.4s,
    width 0.4s;
`;
