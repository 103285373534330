import React from 'react';
import { message, createDataItemSigner } from '@permaweb/aoconnect';

interface ChatAppProps {
  assetId?: string;
  connectedProfileAddress: string;
  chatType: string;
  addTabs?: string;
  portrait?: boolean;
}

export const ChatApps = ({ assetId, connectedProfileAddress, chatType, addTabs, portrait }: ChatAppProps) => {
  const iFrameRef = React.useRef<HTMLIFrameElement | null>(null);
  const iFrameUniqueID = React.useRef('');
  const [iFrameChildURL, setIframeChild] = React.useState('');

  const chatAppProcessID = 'KlrSVr4ll3BwDCmdto4UBi1aF3oAso3vXJgw5B-WvXs';
  const groupChatProcessID = 'DuLalhMSNN3i3NL_b3n38cDb2bGnajSPLylmGA5jhu8';

  const REDIRECTS = {
    chat: `https://live-public-chat.arweave.dev`,
    groupChat: `https://live-private-chat.arweave.dev`,
  };

  const addTabsToQuery = addTabs ? `&tabs=${addTabs}` : '';

  const addPortraitToQuery = portrait ? `&chatMode=portrait` : '';

  const iframeChatAppBaseURL = REDIRECTS.chat;
  const iframeChatAppURL = `${iframeChatAppBaseURL}/?tx=${encodeURIComponent(assetId || 'Global')}&address=${encodeURIComponent(connectedProfileAddress || '')}&processId=${encodeURIComponent(chatAppProcessID)}${addTabsToQuery}${addPortraitToQuery}`;

  const iframeGroupChatAppBaseURL = REDIRECTS.groupChat;
  const iframeGroupChatAppURL = `${iframeGroupChatAppBaseURL}/?tx=${encodeURIComponent(assetId || 'Global')}&address=${encodeURIComponent(connectedProfileAddress || '')}&processId=${encodeURIComponent(groupChatProcessID)}${addTabsToQuery}${addPortraitToQuery}`;

  const fetchFinalURL = async (url: string) => {
    try {
      const response = await fetch(url, { method: 'HEAD' });
      if (response.ok) {
        const finalURL = new URL(response.url).origin;
        setIframeChild(finalURL);
      } else {
        console.error('Fetch failed with status:', response.status);
      }
    } catch (error) {
      console.error('Error fetching final URL:', error);
    }
  };

  React.useEffect(() => {
    if (['chat', 'chat_group'].includes(chatType)) {
      const url = chatType === 'chat' ? iframeChatAppBaseURL : iframeGroupChatAppBaseURL;
      fetchFinalURL(url);
    }
  }, [chatType]);

  React.useEffect(() => {
    if (['chat', 'chat_group'].includes(chatType)) {
      const handleMessage = (event: { origin: string; data: any }) => {
        if (event.origin !== iFrameChildURL) return;

        const { iframeId, type, payload } = event.data;

        if (iframeId !== iFrameUniqueID.current) return;

        switch (type) {
          case 'GroupAdd':
          case 'SendMessage':
            // Handle sending a message
            processMessage({ payload: payload });
            break;
          // Add more cases as needed for different message types
        }
      };

      window.addEventListener('message', handleMessage);

      // Clean up the event listener
      return () => {
        window.removeEventListener('message', handleMessage);
      };
    }
  }, [chatType, iFrameChildURL]);

  const processMessage = async ({ payload }: { payload: any }) => {
    try {
      await message({
        ...payload,
        signer: createDataItemSigner(window.arweaveWallet),
      });
      console.log('Action processed:', payload.tags.find((tag: { name: string }) => tag.name === 'Action').value);
    } catch (error) {
      console.error('Action failed:', error);
    }
  };

  const handleIframeLoad = () => {
    if (iFrameChildURL && ['chat', 'chat_group'].includes(chatType)) {
      console.log('sending message to iframe');
      iFrameUniqueID.current = performance.now().toString(36) + Math.random().toString(36).substring(2);
      sendMessageToIframe({ type: 'initialHandshake', data: 'init', iframeId: iFrameUniqueID.current });
    }
  };

  const sendMessageToIframe = (msg: any) => {
    if (iFrameRef.current?.contentWindow && iFrameChildURL) {
      iFrameRef.current.contentWindow.postMessage(msg, iFrameChildURL);
    }
  };

  const iframeURL = chatType === 'chat' ? iframeChatAppURL : iframeGroupChatAppURL;

  return {
    iframeURL,
    iFrameRef,
    handleIframeLoad,
  };
};

export default ChatApps;
