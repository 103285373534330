/**
 * General-purpose UI states.
 *
 * Use it for things like:
 * - Quickly push a state out from any component for global sharing.
 * - When there is no existing suitable slice to use.
 * - Replacement for `React.context`.
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MakeObject } from 'helpers/objects';

export type ContentType = 'Article' | 'Page' | 'Document' | 'Image' | 'Video' | 'Audio' | 'Spec';
export type ListSort = 'new' | 'rising' | 'hot';

interface UiState {
  flags: {
    stub?: boolean;
  };
  walletModalVisible: boolean;
  listRangeDays: number;
  listSort: ListSort;
  listTypeFilter: ContentType[];
}

const uiSlice = createSlice({
  name: 'ui',
  initialState: MakeObject<UiState>({
    flags: {},
    walletModalVisible: false,
    listRangeDays: 7,
    listSort: 'new',
    listTypeFilter: [],
  }),
  reducers: {
    setFlagOn: (state, action: PayloadAction<keyof UiState['flags']>) => {
      state.flags[action.payload] = true;
    },
    setFlagOff: (state, action: PayloadAction<keyof UiState['flags']>) => {
      state.flags[action.payload] = false;
    },
    setWalletModalVisible: (state, action: PayloadAction<boolean>) => {
      state.walletModalVisible = action.payload;
    },
    setListRangeDays: (state, action: PayloadAction<number>) => {
      state.listRangeDays = action.payload;
    },
    setListSort: (state, action: PayloadAction<UiState['listSort']>) => {
      state.listSort = action.payload;
    },
    setListTypeFilter: (state, action: PayloadAction<ContentType[]>) => {
      state.listTypeFilter = action.payload;
    },
    toggleListTypeFilter: (state, action: PayloadAction<ContentType>) => {
      const index = state.listTypeFilter.indexOf(action.payload);
      if (index === -1) {
        state.listTypeFilter.push(action.payload);
      } else {
        state.listTypeFilter.splice(index, 1);
      }
    },
  },
});

export default uiSlice;
