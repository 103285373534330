/**
 * Finds the timestamp for a transaction.
 *
 * @param node unix epoch in milliseconds.
 */
export function resolveTxTimestampMs(node: INode): number | undefined {
  const tags: Array<ITag> = node.tags;

  const dateCreated = tags?.find((i: any) => i.name === 'Date-Created')?.value;
  if (dateCreated) {
    return Number(dateCreated);
  }

  const unixTime = tags?.find((i: any) => i.name === 'Unix-Time')?.value;
  if (unixTime) {
    return Number(unixTime) * 1000;
  }

  if (node.block?.timestamp) {
    return node.block.timestamp * 1000;
  }
}

export function naToUndefined(value: string | undefined): string | undefined {
  return value && value === 'N/A' ? undefined : value;
}

export function resolveBanner(bannerTag: string | undefined, mediaIds: string | undefined) {
  let banner = bannerTag;
  if (!banner && mediaIds && mediaIds !== 'N/A') {
    const parsed = JSON.parse(mediaIds);
    if (parsed) {
      try {
        if (Array.isArray(parsed)) {
          banner = parsed[0];
        } else if (typeof parsed === 'object') {
          banner = (Object.values(parsed)[0] as any).id;
        }
      } catch (e: any) {}
    }
  }
  return banner;
}
