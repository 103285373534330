import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MakeState } from 'store/utils';

export interface Toast {
  id: number;
  message: string;
  subMessage?: string;
  duration?: 'default' | 'long';
}

export interface ToastState {
  toasts: Toast[];
}

const toastSlice = createSlice({
  name: 'toast',
  initialState: MakeState<ToastState>({
    toasts: [],
  }),
  reducers: {
    createToast: (state, action: PayloadAction<Toast>) => {
      state.toasts.push(action.payload);
    },
    removeToast: (state, action: PayloadAction<number>) => {
      state.toasts = state.toasts.filter((toast) => toast.id !== action.payload);
    },
    clearToasts: (state) => {
      state.toasts = [];
    },
  },
});

export const effAutoDismissToast = {
  actionCreator: toastSlice.actions.createToast,
  effect: async (action: Action, listenerApi: any) => {
    const { dispatch } = listenerApi;

    const newToast = (action as PayloadAction<Toast>).payload;
    const durationMs = newToast.duration === 'long' ? 10000 : 5000;

    await new Promise((resolve) => setTimeout(resolve, durationMs));
    dispatch(toastSlice.actions.removeToast(newToast.id));
  },
};

export default toastSlice;
