import React, { useState, ReactNode } from 'react';
import { ReactSVG } from 'react-svg';
import { HashRouter as Router, Routes, Route, Outlet, Navigate } from 'react-router-dom';

import Modal from 'components/base/modal';
import Header from 'components/header';
import Renderer from 'components/renderer';

import Home from 'pages/home';
import Explore from 'pages/explore';
import Entry from 'pages/entry';
import ProfilePage from 'pages/profile';
import Navigation from 'components/navigation';
import { STYLING } from 'helpers/config';
import { ASSETS } from 'helpers/config';
import { onWindowResize, checkWindowCutoff } from 'helpers/window';

import * as S from './styles';

interface CommonUIProps {
  children: ReactNode;
}

const CommonUI: React.FC<CommonUIProps> = ({ children }) => {
  const [theme, setTheme] = useState(true);
  const [desktop, setDesktop] = useState(true);

  function handleWindowResize() {
    if (checkWindowCutoff(parseInt(STYLING.cutoffs.medium))) {
      setDesktop(true);
    } else {
      setDesktop(false);
    }
  }

  function toggleTheme() {
    document.body.setAttribute('theme', theme ? 'dark' : 'bright');
    setTheme(!theme);
  }

  onWindowResize(handleWindowResize);
  React.useEffect(() => {
    handleWindowResize();
  }, []);

  return (
    <S.App id="App">
      <S.Theme id="Theme" onClick={() => toggleTheme()}>
        <S.ThemeBackdrop id="ThemeBackdrop" activeTheme={theme} />
        <ReactSVG src={ASSETS.dark} />
      </S.Theme>
      <S.ThemeBright id="ThemeBright" activeTheme={theme} onClick={() => toggleTheme()}>
        <ReactSVG src={ASSETS.bright} />
      </S.ThemeBright>
      <Header theme={theme} />
      <S.Container id="Container">
        {desktop && (
          <S.Navigation id="Navigation">
            <Navigation desktop={desktop} />
          </S.Navigation>
        )}
        <S.ContentWrapper id="ContentWrapper">
          <S.Content id="Content">{children}</S.Content>
        </S.ContentWrapper>
        {desktop && <S.Space id="Space" />}
      </S.Container>
    </S.App>
  );
};

const UI: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="*"
          element={
            <CommonUI>
              <Routes>
                <Route index element={<Home />} />
              </Routes>
            </CommonUI>
          }
        />
        <Route
          path="/explore/*" // console.log('getType: ', type);
          element={
            <CommonUI>
              <Routes>
                <Route index element={<Explore />} />
              </Routes>
            </CommonUI>
          }
        />
        <Route
          path="/entry/:id/*"
          element={
            <CommonUI>
              <Routes>
                <Route index element={<Entry />} />
              </Routes>
            </CommonUI>
          }
        />
        <Route
          path="/profile/:id/*"
          element={
            <CommonUI>
              <Routes>
                <Route index element={<ProfilePage />} />
              </Routes>
            </CommonUI>
          }
        />
        <Route path="/asset/:id/*" element={<Renderer />} />
        <Route path="/*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
};

export default UI;
