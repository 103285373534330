import './style.scss';
import React from 'react';
import { Tabs as MuiTabs, TabsList as MuiTabsList, TabPanel as MuiTabPanel, Tab as MuiTab } from '@mui/base';
import classnames from 'classnames';

// ***************************************************************************
// Tabs
// ***************************************************************************

interface TabsProps {
  value?: number | string | null; // The value of the currently selected Tab. If you don't want any selected Tab, you can set this prop to null.
  defaultValue?: number | string; // Default value when used as uncontrolled component.
  onChange?: () => void; // Callback invoked when new value is being set.
  children: React.ReactNode;
}

function Tabs(props: TabsProps) {
  return <MuiTabs className="tabs" {...props} />;
}

// ***************************************************************************
// TabsList
// ***************************************************************************

interface TabsListProps {
  children: React.ReactNode;
}

function TabsList(props: TabsListProps) {
  return <MuiTabsList className="tabs-list" {...props} />;
}

// ***************************************************************************
// Tab
// ***************************************************************************

interface TabProps {
  value?: number | string; // Own value; falls back to the child position index otherwise.
  defaultValue?: number | string; // Default value when used as uncontrolled component.
  onChange?: () => void; // Callback invoked when new value is being set.
  disabled?: boolean;
  children?: React.ReactNode;
}

function Tab(props: TabProps) {
  return <MuiTab className="tab" {...props} />;
}

// ***************************************************************************
// TabPanel
// ***************************************************************************

interface TabPanelProps {
  value: number | string;
  className?: string;
  children?: React.ReactNode;
}

function TabPanel(props: TabPanelProps) {
  return <MuiTabPanel className={classnames('tab-panel', props.className)} {...props} />;
}

// ***************************************************************************
// ***************************************************************************

export { Tabs, TabsList, Tab, TabPanel };
