import styled from 'styled-components';
import { STYLING } from 'helpers/config';

export const ListEntry = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  max-width: 100%;
  background-color: var(--color-gray2);
  margin-bottom: 6px;
  border-radius: ${STYLING.spacing.s};
  transition: height 0.4s;
  box-sizing: border-box;

  &:hover {
    cursor: pointer;
    background-color: var(--color-gray3);
  }
`;

export const Stamps = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 900;

  a {
    color: black;
    text-decoration: none;
    margin-top: auto;
    margin-bottom: ${STYLING.spacing.s};
    margin-left: 4px;
    z-index: 1;
  }
`;

export const StampActions = styled.div`
  margin-top: ${STYLING.spacing.s};
  display: flex;
  align-items: center;

  .icon--downvote {
    fill: white;
    margin-left: 4px;
    margin-right: 4px;

    &:hover {
      fill: #ff8543;
    }
  }
`;

export const Stampers = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${STYLING.spacing.xs};
  margin-left: 14px;

  .avatar {
    border-radius: 50%;
    margin-left: -9px;
    border: 2px solid black;
    z-index: 1;
    img {
      transition: filter 0.4s;
      filter: invert(0%);
    }

    &:hover {
      z-index: 2;
      transform: scale(1.2);
    }
  }
  .listEntry__stampersPlus {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background: white;
    border: 2px solid black;
    border-radius: 50%;
    margin-left: -9px;
    font-size: 22px;
    padding-top: 3px;
    padding-right: 1px;
    box-sizing: border-box;
    z-index: 1;
  }
`;

export const Comments = styled.div`
  display: flex;
  align-items: center;
  height: 34px;
  background: var(--color-gray4);
  border-radius: 18px;
  padding: 4px 10px;
  box-sizing: border-box;
  font-family: 'Arial';
  font-size: 16px;
  font-weight: 900;
  color: white;

  img {
    margin-top: -2px;
    height: 22px;
    margin-right: 8px;
    filter: invert(100%);
  }

  &:hover {
    cursor: pointer;
    background-color: var(--color-gray5);
    color: white;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 100%;
  min-width: 0;
`;

export const Header = styled.div`
  display: flex;
  padding: ${STYLING.spacing.s} ${STYLING.spacing.s} 0 ${STYLING.spacing.s};
  font-size: 12px;
  box-sizing: border-box;
`;

export const User = styled.div`
  display: flex;
  align-items: center;
  padding-right: ${STYLING.spacing.s};
  font-weight: 900;
  div,
  img {
    width: 26px;
    height: 26px;
    filter: invert(0%);
    transition: filter 0.4s;
    margin-right: 8px;
    &.avatar--default {
      width: 22px;
      height: 22px;
    }
  }
  .avatar--default {
    display: flex;
    justify-content: center;
    img {
      margin-right: 0;
    }
  }

  &:hover {
    opacity: 0.7;
  }
`;

export const Date = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${STYLING.spacing.s};
`;

export const Type = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  width: 200px;
  margin-left: auto;
  user-select: none;
  background-color: #d9d9d9;
  margin-top: -10px;
  margin-right: -10px;
  border-radius: 0 10px 0 10px;
  font-weight: 900;
  opacity: 0.7;
  font-style: italic;
`;

export const Primary = styled.div`
  display: flex;
  padding: 0 ${STYLING.spacing.s};
  box-sizing: border-box;

  .listEntry__text {
    display: flex;
    flex: 6;
    flex-direction: column;
    margin-top: ${STYLING.spacing.s};
    margin-right: ${STYLING.spacing.s};
    box-sizing: border-box;

    a {
      color: black;
      text-decoration: none;
    }
    h3 {
      font-size: 20px;
      line-height: 25px;
      margin-bottom: 0;
      font-weight: 900;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;

      a {
        color: black;
        text-decoration: none;
      }
    }

    p {
      font-size: 12px;
      line-height: 14px;
      margin: 2px 0 ${STYLING.spacing.s} 0;
      margin-right: ${STYLING.spacing.s};
    }
  }
`;
export const Thumbnail = styled.div`
  display: flex;
  justify-content: center;
  flex: 2;
  width: 100px;
  margin-bottom: ${STYLING.spacing.s};

  img {
    width: 100%;
    height: 54px;
    border-radius: var(--border-radius);
    object-fit: cover;
    filter: invert(0%);
    transition: filter 0.4s;
  }
`;

export const Renderer = styled.div`
  margin-left: ${STYLING.spacing.xs};
  margin-right: ${STYLING.spacing.s};
  margin-bottom: ${STYLING.spacing.s};
  height: 100%;
`;
