import useScrollPosition from 'hooks/useScrollPosition';
import { ASSETS } from 'helpers/config';
import { ReactSVG } from 'react-svg';
import * as S from './styles';

function ScrollUp() {
  const scrollPosition = useScrollPosition();

  const scrollUp = () => {
    document.documentElement.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <S.ScrollUp visible={scrollPosition > 300} onClick={() => scrollUp()}>
      <ReactSVG src={ASSETS.arrowUp} />
    </S.ScrollUp>
  );
}

export default ScrollUp;
