export function throwIf(condition: boolean, message: string, cause?: any) {
  if (condition) {
    const error: any = new Error(message, { cause });
    const stackFrames = error.stack.split('\n');
    stackFrames.splice(1, 1); // Remove the current stack frame (throwIf)
    error.stack = stackFrames.join('\n');
    throw error;
  }
}

export function suppressError(error: any) {
  // Do nothing
}

export function suppressToConsole(error: any) {
  console.error(error);
}

export function logErrorAndThrow(error: any): never {
  console.error(error);
  throw error;
}

export function sendAlert(error: any) {
  const msg = typeof error === 'string' ? error : error.response?.data || error.message;
  console.error(msg);
  alert(msg);
}

export function getUnknownError(e: unknown) {
  let message;
  if (typeof e === 'string') {
    message = e;
  } else if (e instanceof Error) {
    message = e.message;
  }
  return message;
}
