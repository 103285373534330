import './style.scss';
import React from 'react';
import ToastView from 'components/base/toast/toastView';
import { useAppSelector } from 'store/hooks';

function ToastList() {
  const toasts = useAppSelector((state) => state.toast.toasts);

  return (
    <div className="toasts">
      {toasts.map((toast, index) => (
        <ToastView key={index} toastId={toast.id} stackIndex={index} />
      ))}
    </div>
  );
}

export default ToastList;
