import { combineReducers } from '@reduxjs/toolkit';
import localForage from 'localforage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { createFilter } from 'redux-persist-transform-filter';
import { persistReducer } from 'redux-persist';

import { reducers as sdkReducers } from 'permaweb-sdk/dist/implementations/rtk';

import contentSlice from 'store/content/slice';
import exampleSlice from 'store/example/slice';
import modalSlice from 'store/modal/slice';
import stampsSlice from 'store/stamps/slice';
import toastSlice from 'store/toast/slice';
import uiSlice from 'store/ui/slice';
import uploadSlice from 'store/upload/slice';

// ****************************************************************************
// Persistence configuration
// ****************************************************************************

const rootPersistConfig = {
  key: 'root',
  version: 1,
  storage: localForage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['arweave', 'settings', 'ui'],
  transforms: [
    createFilter('arweave', ['walletType']),
    createFilter('ui', ['listRangeDays', 'listSort']),
    // createFilter('example', ['data']),
    // createBlacklistFilter('settings', ['loadedLanguages']),
  ],
};

// ****************************************************************************
// Root reducer
// ****************************************************************************

const rootReducer = combineReducers({
  ...sdkReducers,
  content: contentSlice.reducer,
  example: exampleSlice.reducer,
  modal: modalSlice.reducer,
  stamps: stampsSlice.reducer,
  toast: toastSlice.reducer,
  ui: uiSlice.reducer,
  upload: uploadSlice.reducer,
});

export type RootReducer = ReturnType<typeof rootReducer>;

// ****************************************************************************
// ****************************************************************************

const finalReducer = persistReducer<RootReducer>(rootPersistConfig, rootReducer);
export default finalReducer;
