import React, { useState, useRef, useEffect } from 'react';
import { GATEWAYS } from 'permaweb-sdk/dist/helpers/config';

import ContentList from 'components/contentList';
import Loading from 'components/loading';
import Ar from 'helpers/arweave/ar';
import { useAppSelector } from 'store/hooks';
import { selectAssetTypesFromListFilter } from 'store/ui/selectors';

function Explore(props: any) {
  const [list, setList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const assetTypes = useAppSelector(selectAssetTypesFromListFilter);
  const assetTypesStringified = JSON.stringify(assetTypes); // TMP, the effects will be removed anyway
  const lockUpdateTrigger = useRef(false);
  const cursor = useRef(null);

  function getListQuery(quantity: number, cursor?: any) {
    let x = `query {
        transactions(
          first: ${quantity}, 
          ${cursor ? `after: "${cursor}", ` : ''} 
          tags: [
            { 
              name: "Implements", 
              values: ["ANS-110"] 
            }
            ${
              assetTypes.length > 0
                ? `,
            { 
              name: "Type", 
              values: ${JSON.stringify(assetTypes)}
            }`
                : ''
            }
          ]) {          
          edges {
            cursor
            node {
              id
              tags {
                name
                value
              }
            }
          }
        }
      }
    `;
    return x;
  }

  function parseEntries(query: any) {
    for (let entry of query.transactions.edges) {
      if (!entry.node.owner) {
        let owner = entry.node.tags.find((i: any) => i.name === 'Initial-Owner');
        if (!owner) {
          query.transactions.edges.shift();
          continue;
        }
        entry.node.owner = {
          address: owner.value,
        };
      }
      let content = entry.node.tags.find((i: any) => i.name === 'Media-Ids')?.value || null;
      if (!content) continue;
      content = JSON.parse(content);
      content = Object.values(content)[0];
      if (!content) continue;

      let banner = {
        name: 'Banner',
        value: content.id,
      };
      entry.node.tags.push(banner);
    }
    query.transactions.edges = query.transactions.edges.filter((entry: any) =>
      entry.node.owner ||
      (entry.node.tags.find((i: any) => i.name === 'Initial-Owner') &&
        (entry.node.owner = {
          address: entry.node.tags.find((i: any) => i.name === 'Initial-Owner').value,
        }))
        ? true
        : false
    );

    setList((prevList) => [...prevList, ...query.transactions.edges]);
    cursor.current = query.transactions.edges.length
      ? query.transactions.edges[query.transactions.edges.length - 1].cursor
      : null;
    lockUpdateTrigger.current = false;
  }

  async function fetchData(query: any) {
    try {
      const result = await Ar.fetchGraphQL(query, GATEWAYS.goldsky);
      parseEntries(result);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      lockUpdateTrigger.current = false;
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (!lockUpdateTrigger.current) {
      lockUpdateTrigger.current = true;
      setIsLoading(true);
      setList([]);
      fetchData(getListQuery(50));
    }
  }, [assetTypesStringified]);

  const updateList = () => {
    if (!lockUpdateTrigger.current) {
      lockUpdateTrigger.current = true;
      fetchData(getListQuery(20, cursor.current));
    }
  };

  return (
    <div className="content">
      <div className="contentColumns">
        <ContentList list={list} action={() => updateList()} />
      </div>
      <Loading loading={isLoading} />
    </div>
  );
}

export default Explore;
