import Arweave from 'arweave';
import { AppInfo } from 'helpers/arweave/config.types';
import { APP_LOGO_URL, APP_NAME } from 'helpers/config';
import { throwIf } from 'helpers/error';
import { convertResponseToJson } from 'helpers/fetch';
import { API_CONFIG, GATEWAYS } from 'permaweb-sdk/dist/helpers/config';

export type TArweaveInstance = 'standard'; //  | 'search' | 'bundled' | 'warp';
type GQLObject = { query: string; operationName: string; variables?: any };

class Ar {
  public static readonly APP_INFO: AppInfo = {
    name: String(APP_NAME),
    logo: String(APP_LOGO_URL),
  };

  public static initInstance(name: TArweaveInstance, gateway: string = 'arweave.net') {
    this._instances[name] = Arweave.init({
      // @ts-ignore boo string type
      host: GATEWAYS[gateway] || gateway,
      port: API_CONFIG.port,
      protocol: API_CONFIG.protocol,
      timeout: API_CONFIG.timeout,
      logging: API_CONFIG.logging,
    });
  }

  public static getInstance(name: TArweaveInstance = 'standard'): Arweave {
    throwIf(!this._instances[name], `Instance ${name} not initialized`);
    return this._instances[name]!;
  }

  // [] OBSOLETE: kill, replace with SDK's queryGQL(), or just getGQLData
  public static async fetchGraphQL(input: string | GQLObject, gateway?: string) {
    const gw = gateway || this.getInstance('standard').getConfig().api.host;

    return fetch(`https://${gw}/graphql`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(typeof input === 'string' ? { query: input } : input),
    })
      .then(convertResponseToJson)
      .then((json) => {
        throwIf(json.errors, `${this.fmtError('GraphQL', json)}`);
        throwIf(!json.data, `${this.fmtError('GraphQL - no data', json)}`);
        return json.data;
      });
  }

  public static async dispatchTx(tx: any, arweaveInstance?: Arweave) {
    const arweave = arweaveInstance || this.getInstance('standard');
    const config = arweave.getConfig().api;
    const usingArLocal = config.host?.includes('arlocal') || config.host?.includes('localhost'); // No gatewayID to work with :(

    throwIf(!window.arweaveWallet, 'dispatchTx: no wallet');

    if (usingArLocal) {
      const balance = await arweave.wallets.getBalance('use_wallet');
      if (Number(balance) < 1) {
        await this.mintAR(1, arweave);
      }

      await arweave.transactions.sign(tx);
      const result = await arweave.transactions.post(tx);
      throwIf(!result || result.status !== 200, this.fmtError('TX Post failed', result), { cause: result });
      return tx.id;
    } else {
      const result = await window.arweaveWallet.dispatch(tx);
      throwIf(!result || !result.id, this.fmtError('TX Dispatch failed', result), { cause: result });
      return result.id;
    }
  }

  public static async mintAR(amount: number, arweaveInstance?: Arweave) {
    const arweave = arweaveInstance || this.getInstance('standard');
    const config = arweave.getConfig().api;
    const usingArLocal = config.host?.includes('arlocal') || config.host?.includes('localhost'); // No gatewayID to work with :(

    throwIf(!window.arweaveWallet, 'mintAR: no wallet');
    throwIf(!usingArLocal, 'mintAR: arweave instance is not using arlocal');

    const walletAddress = await arweave.wallets.getAddress('use_wallet');
    const winston = arweave.ar.arToWinston(amount.toString());
    await arweave.api.get(`mint/${walletAddress}/${winston}`);
    await arweave.api.get('mine');
  }

  public static async getCurrentBlockHeight(arweaveInstance?: Arweave): Promise<number> {
    const arweave = arweaveInstance || this.getInstance('standard');
    return arweave.network.getInfo().then((info) => info.height);
  }

  public static estimateBlockHeight(currentBlockHeight: number, daysAgo: number) {
    const avgBlockTimeInMinutes = 2;
    const minutesInADay = 60 * 24;
    const blocksPerDay = minutesInADay / avgBlockTimeInMinutes;
    return currentBlockHeight - blocksPerDay * daysAgo;
  }

  // --------------------------------------------------------------------------

  private static _instances: { [key: string]: Arweave | null } = {
    standard: null,
    search: null,
    bundled: null,
  };

  private static fmtError(label: string, json: any) {
    return `\n${'-'.repeat(30)}\n[${label}] ${JSON.stringify(json, null, 2)}`;
  }

  private constructor() {
    // No instances
  }
}

export default Ar;
