import { useRef, useState, useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import { NavLink } from 'react-router-dom';
import { GATEWAYS } from 'permaweb-sdk/dist/helpers/config';
import { ASSETS } from 'helpers/config';
import { findTag } from 'helpers/tags';
import Ar from 'helpers/arweave/ar';

import * as S from './styles';

function Banner(props: any) {
  const topBannerRef = useRef<HTMLDivElement>(null);
  const { entries, size, rotate } = props;
  const [marginLeft, setMarginLeft] = useState(0);
  const [width, setWidth] = useState(0);
  const [index, setIndex] = useState(1);
  const [pause, setPause] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [items, setItems] = useState<any[]>([]);

  const query = `query {
    transactions(first: 50, tags: [{ name: "Media-Ids", op: NEQ, values: "[]"}, 
      { name: "Media-Ids", match: WILDCARD, op: EQ values: "[*]"}]) {
      edges {
        node {
          id
          tags {
            name
            value
          }
        }
      }
    }
  }`;

  const queryAsset = (ids: string) => `query {
    transactions(ids: ${ids}) {
      edges {
        node {
          id
          owner {
            address
          }
          tags {
            name
            value
          }
        }
      }
    }
  }`;

  function parseEntries(query: any, list: boolean) {
    let i = 0;
    let newMediaIds = [];
    for (let entry of query.transactions.edges) {
      if (list) {
        i++;
        let mediaIds = entry.node.tags.find((i: any) => i.name === 'Media-Ids').value;
        if (mediaIds.includes('[')) {
          mediaIds = JSON.parse(mediaIds)[0];
        } else if (mediaIds.includes('{')) {
          mediaIds = JSON.parse(mediaIds);
        }
        newMediaIds.push(mediaIds);
      } else {
        const contentType = entry.node.tags.find((i: any) => i.name === 'Content-Type').value;
        if (contentType && contentType.includes('image')) {
          const newEntry = {
            id: entry.node.id,
            banner: entry.node.id,
          };
          setItems((prevArray: any) => Array.from(new Set([...prevArray, newEntry])));
        }
      }
    }
    if (list) fetchData(queryAsset(JSON.stringify(newMediaIds)), false);
  }

  async function fetchData(query: any, list: boolean) {
    if (list) setItems([]);
    try {
      const result = await Ar.fetchGraphQL(query, GATEWAYS.goldsky);
      parseEntries(result, list);
      if (!list) {
        setIndex(2);
        setIsReady(true);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    setIsReady(false);
    if (entries) {
      let newItems = [];
      for (let key in entries) {
        let banner = findTag(entries[key].node.tags, 'Banner');

        if (!banner) {
          const mediaIds = findTag(entries[key].node.tags, 'Media-Ids');
          if (mediaIds) {
            const parsed = JSON.parse(mediaIds);
            if (parsed) {
              try {
                if (Array.isArray(parsed)) {
                  banner = parsed[0];
                } else if (typeof parsed === 'object') {
                  banner = (Object.values(parsed)[0] as any).id;
                }
              } catch (e: any) {}
            }
          } else if (findTag(entries[key].node.tags, 'Content-Type')?.includes('image')) {
            banner = entries[key].node.id;
          }
        }

        if (!banner) {
          continue;
        }

        let newItem = {
          id: entries[key].node.id,
          title: entries[key].node.tags?.find((t: any) => t.name === 'Title')?.value,
          banner: banner,
          thumbnail: entries[key].node.tags?.find((t: any) => t.name === 'Thumbnail')?.value,
        };
        newItems.push(newItem);
      }

      if (newItems.length > 2) {
        setIndex(newItems.length * 2 + 1);
        setItems(newItems.concat(newItems).concat(newItems).concat(newItems).concat(newItems));
        setIsReady(true);
      } else fetchData(query, true);
    }
  }, [entries]);

  useEffect(() => {
    if (topBannerRef.current) {
      setWidth(topBannerRef.current.offsetWidth);
    }
  }, [topBannerRef]);

  useEffect(() => {
    if (width) {
      const interval = setInterval(() => {
        if (!pause && rotate) {
          setIndex(index + 1 <= items.length ? index + 1 : 1);
        }
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [marginLeft, width, pause, index]);

  useEffect(() => {
    if (width) {
      setMarginLeft((index - 1) * (width * -1));
    }
  }, [index, width]);

  useEffect(() => {
    if (width) {
      setMarginLeft((index - 1) * (width * -1));
    }
  }, [index, width]);

  function dropEntry(id: any) {
    setItems((prevItems) => prevItems.filter((item) => item.id !== id));
  }

  return (
    entries &&
    items && (
      <S.TopBanner ref={topBannerRef}>
        {isReady &&
          items.map((item, i) => {
            return (
              <NavLink
                to={`/entry/${entries && item.id}`}
                key={i}
                className="topBanner__image flip"
                style={{
                  transform: `${i === index ? 'scale(0.95)' : i === index - 1 || i === index + 1 ? 'scale(.8)' : 'scale(0)'}`,
                  flex: `${i === index ? '1' : i === index - 1 || i === index + 1 ? '1' : '0'}`,
                }}
              >
                <div
                  style={{ backgroundImage: `url('https://arweave.net/${item.banner}')` }}
                  className="topBanner__imageBackground"
                />
                <img
                  src={`https://arweave.net/${item.banner}`}
                  onError={(e: any) => {
                    dropEntry(item.id);
                  }}
                />
              </NavLink>
            );
          })}
        <S.Navigation side={'left'} onClick={() => setIndex(index > 1 ? index - 1 : items.length)}>
          <ReactSVG src={ASSETS.arrowLeft} />
        </S.Navigation>
        <S.Navigation side={'right'} onClick={() => setIndex(index < items.length ? index + 1 : 1)}>
          <ReactSVG src={ASSETS.arrowRight} />
        </S.Navigation>
      </S.TopBanner>
    )
  );
}

export default Banner;
