import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MakeObject } from 'helpers/objects';

/**
 * `requestId`: arbitrary string (as long as unique) to identify the upload
 * request/session. Each Component can spawn concurrent upload requests,
 * for example.
 */

type UploadMode = 'upload_file' | 'direct_url';

export interface UploadState {
  requests: {
    [requestId: string]: {
      mode: UploadMode;
      file: File | null;
      directUrl?: string; // not really a request, but parking it here.
    };
  };
  results: {
    [requestId: string]: {
      uploading?: boolean;
      progress?: number;
      cost?: string;
      costWarning?: string;
      txId?: TxId; // The transaction ID of the successful upload.
      uploadError?: Error;
    };
  };
}

const uploadSlice = createSlice({
  name: 'upload',
  initialState: MakeObject<UploadState>({
    requests: {},
    results: {},
  }),
  reducers: {
    initRequest: (state, action: PayloadAction<{ id: string; mode?: UploadMode; directUrl?: string }>) => {
      const { id, mode = 'upload_file', directUrl } = action.payload;
      state.requests[id] = { file: null, mode, directUrl };
      state.results[id] = {};
    },
    deleteRequest: (state, action: PayloadAction<string>) => {
      delete state.requests[action.payload];
      delete state.results[action.payload];
    },
    setMode: (state, action: PayloadAction<{ id: string; mode: UploadMode }>) => {
      const { id, mode } = action.payload;
      state.requests[id].mode = mode;
    },
    setDirectUrl: (state, action: PayloadAction<{ id: string; url: string }>) => {
      const { id, url } = action.payload;
      state.requests[id].directUrl = url;
    },
    setFile: (state, action: PayloadAction<{ id: string; file: File; cost?: string; warning?: string }>) => {
      const { id, file, cost, warning } = action.payload;
      state.requests[id].file = file;

      delete state.results[id].txId;
      delete state.results[id].uploadError;
      delete state.results[id].costWarning;

      state.results[id].cost = cost;
      state.results[id].costWarning = warning;
    },
    uploadStarted: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload;
      state.results[id].uploading = true;
    },
    uploadProgress: (state, action: PayloadAction<{ id: string; progress: number }>) => {
      const { id, progress: number } = action.payload;
      state.results[id].progress = number;
    },
    uploadDone: (state, action: PayloadAction<{ id: string; txId: TxId }>) => {
      const { id, txId } = action.payload;
      state.results[id].txId = txId;
      state.results[id].uploading = false;
      delete state.results[id].uploadError;
    },
    uploadFail: (state, action: PayloadAction<{ id: string; err: Error }>) => {
      const { id, err } = action.payload;
      delete state.results[id].txId;
      state.results[id].uploading = false;
      state.results[id].uploadError = err;
    },
  },
});

export default uploadSlice;
