import { STYLING } from 'helpers/config';
import styled from 'styled-components';

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${STYLING.spacing.s};
  z-index: 0;
  min-width: 0;
`;
