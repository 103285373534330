import ImgUser from 'assets/img/icons/user.svg';
import { FullProfileType } from 'permaweb-sdk/dist/helpers/types';
import { updateProfile, UpdateProfileArgs } from 'permaweb-sdk/dist/api';

/**
 * Helper class for profile-related items.
 *
 * Serves as an intermediate bridge between app and SDK (previously arweave-account)
 * to inject tweaks and whatnot.
 */
class AccountMgr {
  private static gateway: string;

  static DEFAULT_AVATAR = 'ar://OrG-ZG2WN3wdcwvpjz1ihPe4MI24QBJUpsJGIdL85wA';
  static DEFAULT_BANNER = 'ar://a0ieiziq2JkYhWamlrUCHxrGYnHWUAMcONxRmfkWt-k';

  static initialize(gateway: string) {
    this.gateway = gateway;
  }

  static resolveName(profile: FullProfileType | null) {
    return profile?.displayName || profile?.channelTitle || profile?.handle || this.resolveHandle(profile);
  }

  static resolveHandle(profile: FullProfileType | null) {
    // this is tricky. some ppl have no names.
    // generate name from address in a clever way?
    // example of a real profile in the wild:
    if (profile) {
      return profile.handle || this.getUniqueHandle(profile.walletAddress);
    }
    return null;
  }

  static resolveAvatar(profile: FullProfileType | null | undefined, oauthProfile: any) {
    const oauthAvatar = oauthProfile?.picture;
    const profileAvatar = this.getUrlFromUri(profile?.avatar || '', ImgUser);

    if (profileAvatar === AccountMgr.DEFAULT_AVATAR || !profileAvatar) {
      // Override MetaWeave default for profiles created using arweave-account.
      return oauthAvatar || ImgUser;
    }

    return profileAvatar || oauthAvatar || ImgUser;
  }

  private static getUniqueHandle(addr: WalletId, handleName?: string) {
    if (handleName) return `@${handleName}#${addr.slice(0, 3)}${addr.slice(addr.length - 3)}`;
    else return `${addr.slice(0, 5)}...${addr.slice(addr.length - 5)}`;
  }

  private static getUrlFromUri(URI: string, fallback?: string) {
    let resource;

    if (/^[a-zA-Z0-9\-_]{43}$/.test(URI)) {
      // Look for simple txids to be compatible with arweave account protocol v0.2
      return `https://${this.gateway}/${URI}`;
    } else if ((resource = URI.match(/^ar:\/\/([a-zA-Z0-9\-_]{43})$/))) {
      // ar://<txid>
      return `https://${this.gateway}/${resource[1]}`;
    } else if (/^https?:\/\/.+$/.test(URI)) {
      // http URLs
      return URI;
    } else {
      // corrupted data (default avatar)
      return fallback || `https://${this.gateway}/${AccountMgr.DEFAULT_AVATAR}`;
    }
  }

  private constructor() {
    // No instances allowed
  }
}

export default AccountMgr;
