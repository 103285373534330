import toastSlice from 'store/toast/slice';

let _idCounter = 0; // unique id used instead of index to prevent wrong deletion.

export function doToast(message: string, subMessage?: string, duration?: 'default' | 'long') {
  return (dispatch: AppDispatch) => {
    const id = _idCounter++;
    dispatch(
      toastSlice.actions.createToast({
        id: id,
        message: message,
        subMessage: subMessage,
        duration: duration || 'default',
      })
    );

    if (process.env.NODE_ENV === 'development') {
      const msg = JSON.stringify({ message, subMessage, duration, timestampMs: Date.now() }, null, 2);
      console.groupCollapsed('Toast' + msg.slice(1, msg.length - 1));
      console.trace();
      console.groupEnd();
    }

    return id;
  };
}

export function doRemoveToast(id: number) {
  return (dispatch: AppDispatch) => {
    dispatch(toastSlice.actions.removeToast(id));
  };
}

export function doClearToasts() {
  return (dispatch: AppDispatch) => {
    dispatch(toastSlice.actions.clearToasts());
  };
}
