import styled from 'styled-components';

// import { STYLING } from 'helpers/config';

export const Wrapper = styled.div`
  height: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  flex-direction: column;

  h2 {
    color: var(--color-text);
  }
`;

export const CardsWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  max-width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const Cards = styled.div<{ marginLeft: number }>`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  left: ${(props) => `calc(${props.marginLeft} * -100%)`};
  transition: left 0.2s;

  h2 {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  input {
    width: 100%;

    border: unset;
    border-radius: 18px;
  }
`;

export const CardRows = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding-bottom: 20px;
`;

export const InputCheck = styled.div<{ check: boolean }>``;

export const CardRow = styled.div`
  display: flex;
  width: 60%;
  justify-content: center;
`;

export const InputWrapper = styled.div<{ check: boolean; handle?: boolean }>`
  position: relative;
  width: 100%;
  span {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 30px;
    top: 0;
    left: 0;
    padding-left: 3px;
    border-radius: 18px 0 0 18px;
  }
  div {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    right: 0;
    top: 0;
    width: 30px;
    opacity: ${(props) => (props.check ? 1 : 0.2)};
    color: ${(props) => (props.check ? props.theme.colors.primary : 'default')};
  }
  input {
    padding-left: ${(props) => (props.handle ? '38px' : '10px')};
    padding-right: 30px;
  }
`;

export const Card1 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 100%;
  height: 100%;
`;

export const Card2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  min-width: 100%;
  height: 100%;
`;

export const Card3 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  min-width: 100%;
  height: 100%;
`;

export const Card4 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  min-width: 100%;
  height: 100%;
`;

export const AvatarPreview = styled.div`
  position: relative;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  flex-shrink: 0;
  overflow: hidden;

  div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    border-radius: 50%;

    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
      stroke: var(--color-text);
      padding: 16px;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
    overflow: hidden;
    font-size: 0;

    &:hover {
      cursor: pointer !important;
    }
  }
`;

export const AvatarDropArea = styled.div<{ isDraggingOver: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 100%;
  border-radius: var(--border-radius);
  border: 2px dashed white;
  background-color: ${(props) => (props.isDraggingOver ? props.theme.colors.container.alt4.background : 'none')};

  input {
    display: none;
  }
`;

export const Navigation = styled.div`
  display: flex;
  justify-content: center;
  width: 80%;
  padding: 20px 0;
  margin-top: auto;

  button {
    &:first-of-type {
      margin-right: auto;
    }
    &:last-of-type {
      margin-left: auto;
    }
  }
`;

export const Progress = styled.div<{ activeCard: number }>`
  display: flex;
  gap: 10px;
  margin-top: auto;
  div {
    width: 16px;
    height: 16px;
    border: 1px solid var(--color-primary);
    border-radius: 50%;
    background: transparent;
    transition: background-color 0.2s;

    &:nth-of-type(1) {
      background: ${(props) => (props.activeCard >= 0 ? props.theme.colors.primary : `transparent`)};
    }
    &:nth-of-type(2) {
      background: ${(props) => (props.activeCard >= 1 ? props.theme.colors.primary : `transparent`)};
    }
    &:nth-of-type(3) {
      background: ${(props) => (props.activeCard >= 2 ? props.theme.colors.primary : `transparent`)};
    }
  }
`;

export const ProfilesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 80%;
  height: 100%;
  overflow-y: scroll;
  padding: 20px;

  border-radius: var(--border-radius);

  scrollbar-width: thin;
  ::-webkit-scrollbar {
    width: 5px;
    border-radius: 30px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-clip: padding-box;
  }
`;

export const LWrapper = styled.div`
  padding: 0 0 10px 0;
`;

export const TopicsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 80%;
  height: 100%;
  overflow-y: scroll;
  padding: 20px;

  border-radius: var(--border-radius);
  color: var(--color-text);

  scrollbar-width: thin;
  ::-webkit-scrollbar {
    width: 5px;
    border-radius: 30px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-clip: padding-box;
  }
`;

export const Topic = styled.div`
  display: flex;
  align-items: center;
  div[type='button'] {
    margin-left: auto;
  }
`;
