/**
 * Converts an Arweave URL (ar://xxx) to an HTTP URL (https://xxx).
 * @param url
 * @param gateway
 */
export function arToHttp(url: string | undefined, gateway: string) {
  if (url && /^[a-z0-9_-]{43}$/i.test(url)) {
    return `https://${gateway}/${url}`;
  }

  if (url?.startsWith('ar://')) {
    return url.replace('ar://', `https://${gateway}/`);
  }

  return url;
}
