import Loading from 'components/loading';
import { useAppSelector } from 'store/hooks';

interface Props {
  /** The query key for the loading state in assetSlice to represent. */
  queryKey: string;
}

function AssetListLoading(props: Props) {
  const { queryKey } = props;
  const fetching = useAppSelector((state) => state.assets.assetResultsByQueryKey[queryKey]?.fetching);
  return <Loading loading={fetching} />;
}

export default AssetListLoading;
