import React from 'react';
import classnames from 'classnames';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import uploadSlice from 'store/upload/slice';

import { FullProfileType } from 'permaweb-sdk/dist/helpers/types';

import './style.scss';
import Form from './form';
import Preview from './preview';
import Uploads from './uploads';

export const UPLOAD_REQUEST_IDS = {
  banner: 'EditProfile_banner',
  avatar: 'EditProfile_avatar',
};

function ProfileEdit() {
  const dispatch = useAppDispatch();
  const profile = useAppSelector((state) => state.arweave.profile);

  const formHook = useForm<FullProfileType>({
    defaultValues: { ...profile }, // load from existing profile
    delayError: 500,
    mode: 'onChange',
    shouldUseNativeValidation: false,
  });

  React.useEffect(() => {
    return () => {
      dispatch(uploadSlice.actions.deleteRequest(UPLOAD_REQUEST_IDS.banner));
      dispatch(uploadSlice.actions.deleteRequest(UPLOAD_REQUEST_IDS.avatar));
    };
  }, []);

  return (
    <FormProvider {...formHook}>
      <div className={classnames('profileEdit', { 'profileEdit--disabled': formHook.formState.isSubmitting })}>
        <div className="profileEdit__header">Edit Profile</div>
        <div className="profileEdit__body">
          <div className="profileEdit__lhs">
            <Form />
          </div>
          <div className="profileEdit__rhs">
            <Uploads />
            <Preview />
          </div>
        </div>
      </div>
    </FormProvider>
  );
}

export default ProfileEdit;
