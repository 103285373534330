import { ReactSVG } from 'react-svg';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ASSETS } from 'helpers/config';
import uiSlice, { ContentType } from 'store/ui/slice';
import * as S from './styles';

const Checkbox = (props: { desktop: boolean; type: ContentType }) => {
  const { type } = props;
  const isChecked = useAppSelector((state) => state.ui.listTypeFilter.includes(type));

  return (
    <S.Checkbox desktop={props.desktop}>
      <input />
      <S.Checkmark checked={isChecked} onClick={(e: any) => e.stopPropagation()} />
    </S.Checkbox>
  );
};

const NavigationEntry = (props: { desktop: boolean; type: ContentType }) => {
  const dispatch = useAppDispatch();
  const onToggle = () => {
    dispatch(uiSlice.actions.toggleListTypeFilter(props.type));
  };

  return (
    <S.NavigationEntry onClick={onToggle}>
      {!props.desktop && <Checkbox desktop={props.desktop} type={props.type} />}
      <S.NavigationEntryLabel>
        {/* <ReactSVG src={ASSETS[props.type]} /> */}
        {props.type}
      </S.NavigationEntryLabel>
      {props.desktop && <Checkbox desktop={props.desktop} type={props.type} />}
    </S.NavigationEntry>
  );
};

function Navigation(props: any) {
  const { desktop } = props;
  const showNavigation = !location.href.includes('entry');

  return (
    <S.Navigation desktop={desktop} show={showNavigation}>
      <S.NavigationContainer>
        <S.NavigationHeader>Type</S.NavigationHeader>
        <S.NavigationEntries desktop={desktop}>
          <NavigationEntry desktop={desktop} type="Article" />
          <NavigationEntry desktop={desktop} type="Page" />
          <NavigationEntry desktop={desktop} type="Document" />
          <NavigationEntry desktop={desktop} type="Image" />
          <NavigationEntry desktop={desktop} type="Video" />
        </S.NavigationEntries>
      </S.NavigationContainer>
    </S.Navigation>
  );
}

export default Navigation;
