import styled from 'styled-components';
import { STYLING } from 'helpers/config';

export const CommentView = styled.div`
  display: flex;
  flex-flow: row;
  margin-top: ${STYLING.spacing.m};
  gap: ${STYLING.spacing.s};

  p {
    font-size: small;
    margin-top: ${STYLING.spacing.xs};
    margin-bottom: 0;
    word-break: break-word;
  }
`;

export const Comment = styled.div`
  width: 100%;
`;

export const AuthorName = styled.div`
  font-weight: bold;
  font-size: small;
  align-self: flex-start;
  display: inline-block;
  &:hover {
    color: rgba(black, 0.7);
  }
`;

export const Date = styled.div`
  color: #a8a8a8;
  font-size: x-small;
  display: inline-block;
  margin-left: ${STYLING.spacing.s};
`;

export const Actions = styled.div`
  display: flex;
  margin-top: ${STYLING.spacing.s};
  justify-content: flex-start;
  align-items: center;
  gap: ${STYLING.spacing.s};
`;

export const Action = styled.div`
  display: flex;
  align-items: center;
  border-radius: 16px;
  height: 32px;
  background-color: var(--color-gray3);
  padding: 4px 10px;
  box-sizing: border-box;
  font-family: 'Arial';
  font-size: 16px;
  font-weight: 900;

  img {
    margin-top: -2px;
    height: 24px;
    margin-right: 8px;
  }

  &:hover {
    cursor: pointer;
    background-color: var(--color-gray4);
    color: white;
    img {
      filter: invert(100%);
    }
  }

  &.commentView__action--stamps {
    img {
      background: white;
      border: 2px solid black;
      border-radius: 8px;
      width: 24px;
      padding: 2px;
      box-sizing: border-box;
    }
  }
`;

export const Reply = styled.div`
  margin-top: ${STYLING.spacing.s};
`;

export const ReplyCount = styled.div`
  font-weight: bold;
  font-size: small;
  margin-top: ${STYLING.spacing.m};

  img {
    width: 0.8rem;
    margin-right: ${STYLING.spacing.s};
  }

  &:hover {
    cursor: pointer;
    color: rgba(black, 0.8);
  }
`;
