import React from 'react';
import Ar from 'helpers/arweave/ar';
import { useAppSelector } from 'store/hooks';
import { naToUndefined } from '../../contentList/listEntry/helpers';
import { getType } from 'components/renderer/helpers';
import './style.scss';

function PostRenderer(props: any) {
  const { asset } = props;
  const mediaIds = naToUndefined(asset.mediaIds) ? JSON.parse(asset.mediaIds) : undefined;
  const [media, setMedia] = React.useState<any>([]);
  const gateway = useAppSelector((state) => state.arweave.standardGateway);

  function getQuery(ids: any) {
    return `query {
      transactions(ids: ${JSON.stringify(ids)}) {
        edges {
          node {
            id
            owner {
              address
            }
            data {
              size
              type
            }
            tags {
              name
              value
            }
          }          
        }        
      }
    }`;
  }

  async function fetchData(query: any, gateway: string) {
    try {
      const result = await Ar.fetchGraphQL(query, gateway);
      return result;
    } catch (err) {
      console.error('Error fetching data:', err);
      return;
    }
  }

  React.useEffect(() => {
    const fetchItems = async () => {
      try {
        const result = await fetchData(getQuery(mediaIds), gateway);

        let mediaArray = [];
        for (let entry of result.transactions.edges) {
          let newEntry = {
            id: entry.node.id,
            contentType: entry.node.tags?.find((t: any) => t.name === 'Content-Type')?.value,
          };
          mediaArray.push(newEntry);
        }
        setMedia(mediaArray);
      } catch (err) {
        console.error(err);
      }
    };

    fetchItems();
  }, [asset]);

  return (
    <div className="renderer--post">
      {asset && (
        <>
          {media &&
            media.map((entry: any, i: number) => {
              return getType(entry.contentType) === 'image' ? (
                <div className="imageWrapper">
                  <div
                    className="imageWrapper__background"
                    style={{ backgroundImage: `url('https://arweave.net/${entry.id}')` }}
                  />
                  <img key={i} src={`https://arweave.net/${entry.id}`} alt={`Image ${i}`} />
                </div>
              ) : getType(entry.contentType) === 'video' ? (
                <video controls>
                  <source src={`https://arweave.net/${entry.id}`} />
                </video>
              ) : (
                ''
              );
            })}
        </>
      )}
    </div>
  );
}

export default PostRenderer;
