import React from 'react';
import './style.scss';

function AssetHeader(props: any) {
  const { asset } = props;

  return (
    asset && (
      <div className="assetHeader">
        <h2>{asset.title}</h2>
        {asset.banner && <img className="assetHeader__banner" src={`https://arweave.net/${asset.banner}`} />}
        {asset.description !== asset.title && <p>{asset.description}</p>}
      </div>
    )
  );
}

export default AssetHeader;
