import React from 'react';
import { NavLink } from 'react-router-dom';
import { CommentType, doFetchCommentContent } from 'permaweb-sdk/dist/implementations/rtk';

import ImgComments from 'assets/img/icons/comments.svg';
import ImgStamp from 'assets/img/stamp.svg';
import ImgVoteDown from 'assets/img/icons/vote_down.svg';
import ImgVoteUp from 'assets/img/icons/vote_up.svg';
import Avatar from 'components/avatar';
import CommentCreate from 'components/comments/commentCreate';
import CommentList from 'components/comments/commentList';

import AccountMgr from 'helpers/arweave/accountMgr';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import * as S from './styles';

interface Props {
  commentId: string;
  threadId?: string; // @see CommentTags
  hideReplyCount?: boolean;
}

const CommentView = (props: Props) => {
  const { commentId, threadId, hideReplyCount } = props;
  const dispatch = useAppDispatch();

  const comment: CommentType = useAppSelector((state) => state.comments.byId.comments[commentId]);
  const replyCount = useAppSelector((state) => state.comments.byId.replyCount[commentId]);
  const isTopLevel = comment?.rootSource === comment?.dataSource;

  const profile = useAppSelector((state) => state.profiles.byWalletId[comment?.ownerAddress]);
  const handle = AccountMgr.resolveHandle(profile);

  // const dt = dayjs(comment.timestampMs);
  // const timestampDisplay = comment.timestampMs ? `${dt.fromNow()}` : "";
  // const timestampTooltip = comment.timestampMs ? `${dt.format("HH:mm • MMM D, YYYY")}` : "";
  const timestampDisplay = comment?.dateCreated ? `${new Date(comment.dateCreated).toLocaleString()}` : '';
  const timestampTooltip = comment?.dateCreated ? `${comment.dateCreated}` : '';

  const [replying, setReplying] = React.useState(false);
  const [showReplies, setShowReplies] = React.useState(isTopLevel);

  const onCreate = () => setReplying(false);

  React.useEffect(() => {
    dispatch(doFetchCommentContent(commentId));
  }, [commentId, dispatch]);

  if (!comment) {
    return null;
  }

  return (
    <S.CommentView>
      <Avatar walletAddr={comment.ownerAddress} />

      <S.Comment>
        <NavLink to={`/profile/${comment.ownerAddress}`}>
          <S.AuthorName title={comment.ownerAddress}>{handle}</S.AuthorName>
        </NavLink>

        <S.Date title={timestampTooltip}>{timestampDisplay}</S.Date>

        <p>{comment.data}</p>

        <S.Actions>
          <S.Action onClick={() => setReplying((prev) => !prev)}>
            <img src={ImgComments} />
            Reply
          </S.Action>
          <S.Action className="commentView__action--stamps">
            <img src={ImgStamp} /> --
          </S.Action>
        </S.Actions>

        {replying && (
          <S.Reply>
            <CommentCreate assetId={comment.rootSource} parentId={commentId} threadId={threadId} onCreate={onCreate} />
          </S.Reply>
        )}

        {!hideReplyCount && replyCount !== undefined && (
          <S.ReplyCount onClick={() => setShowReplies((prev) => !prev)}>
            <img src={showReplies ? ImgVoteUp : ImgVoteDown} />
            {replyCount === 1 ? 'Reply' : 'Replies'}({replyCount})
          </S.ReplyCount>
        )}

        {showReplies && (
          <div>
            <div>
              <div>
                <CommentList assetId={comment.rootSource} parentId={commentId} />
              </div>
            </div>
          </div>
        )}
      </S.Comment>
    </S.CommentView>
  );
};

export default CommentView;
