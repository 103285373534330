import styled, { keyframes } from 'styled-components';
import { ASSETS } from 'helpers/config';

const wave = keyframes`
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
`;

const swell = keyframes`
  0%,
  100% {
    transform: translate3d(0, -25px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
`;

export const Loading = styled.div<{ visible: boolean }>`
  height: 5%;
  width: 100%;
  position: fixed;
  bottom: ${(props) => (props.visible ? 0 : '-250px')};
  left: 0;
  transition: bottom 3s;

  /*
  &.ocean--visible {
    .wave {
      &:nth-of-type(1) {
        animation: ${wave} 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
      }
      &:nth-of-type(2) {
        animation:
          ${wave} 5s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
          ${swell} 7s ease -1.25s infinite;
      }
    }
  }
  */
`;

export const Wave = styled.div<{ visible: boolean }>`
  background: ${`url(${ASSETS.wave}) repeat-x`};
  position: absolute;

  width: 6400px;
  height: 198px;
  transform: translate3d(0, 0, 0);
  filter: invert(0%);
  transition: filter 0.4s;

  &:nth-of-type(1) {
    top: -158px;
    animation: ${wave} 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) forwards;
  }
  &:nth-of-type(2) {
    top: -125px;
    animation:
      ${wave} 5s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s forwards,
      ${swell} 7s ease -1.25s forwards;
  }
`;

/*
[theme='dark'] {
  .ocean {
    .wave {
      filter: invert(100%);
    }
  }
}
*/
