import React from 'react';
import { useFormContext } from 'react-hook-form';
import AccountMgr from 'helpers/arweave/accountMgr';
import { arToHttp } from 'helpers/url';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import uploadSlice from 'store/upload/slice';
import { doSelectFileForUpload } from 'store/upload/thunks';

import { UPLOAD_REQUEST_IDS } from './index';
import useObjectURL from './hooks';

export default function Uploads() {
  const formHook = useFormContext();

  // Form values (TxIds):
  const banner: TxId = formHook.watch('banner');
  const avatar: TxId = formHook.watch('avatar');
  // Current-selected FILEs:
  const newBannerFile = useAppSelector((state) => state.upload.requests[UPLOAD_REQUEST_IDS.banner]?.file);
  const newAvatarFile = useAppSelector((state) => state.upload.requests[UPLOAD_REQUEST_IDS.avatar]?.file);
  // Buffer of the selected FILE:
  const newBannerBuffer = useObjectURL(newBannerFile);
  const newAvatarBuffer = useObjectURL(newAvatarFile);
  // Finalize (<img> src):
  const bannerSrc = newBannerBuffer || banner;
  const avatarSrc = newAvatarBuffer || avatar;

  const dispatch = useAppDispatch();
  const gateway = useAppSelector((state) => state.arweave.standardGateway);

  const bannerPrice = useAppSelector(
    (state) =>
      state.upload.results[UPLOAD_REQUEST_IDS.banner]?.costWarning ||
      state.upload.results[UPLOAD_REQUEST_IDS.banner]?.cost
  );
  const avatarPrice = useAppSelector(
    (state) =>
      state.upload.results[UPLOAD_REQUEST_IDS.avatar]?.costWarning ||
      state.upload.results[UPLOAD_REQUEST_IDS.avatar]?.cost
  );

  const bannerRef = React.useRef<HTMLInputElement>(document.createElement('input'));
  const avatarRef = React.useRef<HTMLInputElement>(document.createElement('input'));

  const handleFileChange = (type: 'banner' | 'avatar') => (e: any) => {
    const ref = type === 'banner' ? bannerRef : avatarRef;
    ref.current.click();
    dispatch(uploadSlice.actions.initRequest({ id: UPLOAD_REQUEST_IDS[type] }));
    dispatch(doSelectFileForUpload({ id: UPLOAD_REQUEST_IDS[type], file: e.target.files[0] }));
  };

  const handleRemove = (type: 'banner' | 'avatar') => (e: any) => {
    formHook.setValue(type, '');
  };

  const Image = (props: any) => {
    const src = arToHttp(props.src, gateway);
    const fallbackSrc = arToHttp(AccountMgr.DEFAULT_AVATAR, gateway);
    return <img src={src} onError={(e: any) => (e.target.src = fallbackSrc)} />;
  };

  const ImagePreview = (props: { className: 'banner' | 'avatar' }) => {
    const { className } = props;
    const ref = className === 'banner' ? bannerRef : avatarRef;
    const src = className === 'banner' ? bannerSrc : avatarSrc;

    return (
      <div className={className} onClick={() => ref.current.click()}>
        {src && <Image src={src} />}
        {!src && <span>Upload {`${className}`}</span>}
        <input
          ref={ref}
          type="file"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={handleFileChange(className)}
        />
      </div>
    );
  };

  return (
    <>
      <div className="profileEdit__uploads">
        <ImagePreview className="banner" />
        <ImagePreview className="avatar" />
      </div>

      <div className="profileEdit__uploadPrice">
        {(newBannerFile?.size || 0) > 100 * 1024 && bannerPrice && <div>Banner Price: {bannerPrice}</div>}
        {(newAvatarFile?.size || 0) > 100 * 1024 && avatarPrice && <div>Avatar Price: {avatarPrice}</div>}
      </div>

      <div className="profileEdit__removals">
        <button onClick={handleRemove('banner')}>Remove Banner</button>
        <button onClick={handleRemove('avatar')}>Remove Avatar</button>
      </div>
    </>
  );
}
