import { throwIf } from 'helpers/error';
import { FullProfileType } from 'permaweb-sdk/dist/helpers/types';
import { doUpdateProfile } from 'permaweb-sdk/dist/implementations/rtk';

import { doToast } from 'store/toast/thunks';
import { fileToDataUrl } from 'store/upload/helpers';

import { UPLOAD_REQUEST_IDS } from './index';

export function doFinalizeProfileUpdate(newProfile: FullProfileType) {
  return async (dispatch: AppDispatch, getState: GetState): Promise<void> => {
    const state = getState();
    const walletAddress = state.arweave.walletAddress;
    const newBannerFile = state.upload.requests[UPLOAD_REQUEST_IDS.banner]?.file;
    const newAvatarFile = state.upload.requests[UPLOAD_REQUEST_IDS.avatar]?.file;

    throwIf(!walletAddress, 'No wallet address');

    let avatar = newProfile.avatar;
    if (newAvatarFile) {
      avatar = await fileToDataUrl(newAvatarFile);
    }

    let banner = newProfile.banner;
    if (newBannerFile) {
      banner = await fileToDataUrl(newBannerFile);
    }

    try {
      await dispatch(
        doUpdateProfile({
          address: walletAddress!,
          handle: newProfile.handle || '',
          displayName: newProfile.displayName || '',
          bio: newProfile.bio || '',
          avatar: avatar || '',
          banner: banner || '',
        })
      );

      dispatch(doToast('Profile updated'));
    } catch (err: any) {
      dispatch(doToast('Profile update failed', err.message || err, 'long'));
      throw err;
    }
  };
}
