import { useNavigate } from 'react-router-dom';
import { doWalletDisconnect } from 'permaweb-sdk/dist/implementations/rtk';

import Avatar from 'components/avatar';
import { Menu, MenuButton, MenuItem, MenuList } from 'components/base/menu';
import WalletBalance from 'components/walletBalance';
import AccountMgr from 'helpers/arweave/accountMgr';
import { useLanguageProvider } from 'providers/LanguageProvider';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import * as S from './styles';

const TestMenu = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const languageProvider = useLanguageProvider();
  const language = languageProvider.object[languageProvider.current];

  const profile = useAppSelector((state) => state.arweave.profile);
  const handle = AccountMgr.resolveHandle(profile);

  const walletType = useAppSelector((state) => state.arweave.walletType);
  const walletAddr = useAppSelector((state) => state.arweave.walletAddress) || '';
  const shortWalletAddr = `${walletAddr.slice(0, 5)}···${walletAddr.slice(-5)}`;
  // const othentEmail = walletType === 'othent' ? Othent.getUserInfo()?.email : '';

  return (
    <Menu>
      <MenuButton>
        <S.ProfileWrapper>
          {!handle && (
            <>
              <span>{`loading...`}</span>
            </>
          )}
          {handle && (
            <>
              <Avatar walletAddr="active_user" small />
              <span>{handle}</span>
            </>
          )}
        </S.ProfileWrapper>
      </MenuButton>
      <MenuList>
        <S.MenuListProfile>
          <Avatar walletAddr="active_user" />
          <div>
            <S.MenuListProfileHandle className="userMenu__handle">{handle}</S.MenuListProfileHandle>
            <S.MenuListProfileAddress title={walletAddr}>{shortWalletAddr}</S.MenuListProfileAddress>
            {/* <S.MenuListProfileOthentMail title={othentEmail}>{othentEmail}</S.MenuListProfileOthentMail> */}
          </div>
        </S.MenuListProfile>
        <hr />
        <WalletBalance />
        <hr />
        <MenuItem onClick={() => navigate(`/profile/${walletAddr}`)}>Profile</MenuItem>
        <MenuItem onClick={() => dispatch(doWalletDisconnect())}>{`${language.signOut}`}</MenuItem>
      </MenuList>
    </Menu>
  );
};

export default TestMenu;
