import Arweave from 'arweave';

import { API_CONFIG, GATEWAYS } from './config';

export function checkAddress(address: string | null) {
  if (!address) return false;
  return /^[a-z0-9_-]{43}$/i.test(address);
}

export function formatAddress(address: string | null, wrap: boolean) {
  if (!address) return '';
  if (!checkAddress(address)) return address;
  const formattedAddress = address.substring(0, 5) + '...' + address.substring(36, address.length - 1);
  return wrap ? `(${formattedAddress})` : formattedAddress;
}

export function getARAmountFromWinc(amount: number) {
  const arweave = Arweave.init({
    host: GATEWAYS.arweave,
    protocol: API_CONFIG.protocol,
    port: API_CONFIG.port,
    timeout: API_CONFIG.timeout,
    logging: API_CONFIG.logging,
  });
  return Math.floor(+arweave.ar.winstonToAr(amount.toString()) * 1e6) / 1e6;
}

export function formatARAmount(amount: number) {
  return `${amount.toFixed(4)} AR`;
}
