import AccountMgr from 'helpers/arweave/accountMgr';
import Ar from 'helpers/arweave/ar';
import StampMgr from 'helpers/arweave/stampMgr';
import { doToast } from 'store/toast/thunks';

export function doInitHelpers() {
  return async (dispatch: AppDispatch, getState: GetState) => {
    const state = getState();
    const walletAddress = state.arweave.walletAddress;

    Ar.initInstance('standard', state.arweave.standardGateway);

    AccountMgr.initialize(state.arweave.standardGateway);

    // [] TODO: this should eventually be in SDK:
    await StampMgr.initialize(state.arweave.standardGateway, walletAddress, 'https://dre-1.warp.cc/contract');
  };
}

export function doReportInitErrors() {
  return async (dispatch: AppDispatch, getState: GetState) => {
    await new Promise((resolve) => setTimeout(resolve, 2000));

    const state = getState();
    const errors: string[] = state.sdkStatus.initSdkErrors;
    errors.forEach((e) => dispatch(doToast('Initialization error', e, 'long')));
  };
}
