import React from 'react';
import { ReactSVG } from 'react-svg';
import { useNavigate } from 'react-router-dom';
import { FullProfileType } from 'permaweb-sdk/dist/helpers/types';
import { doFetchProfiles } from 'permaweb-sdk/dist/implementations/rtk';

import defaultProfilePic from 'assets/img/profile.png';
import AccountMgr from 'helpers/arweave/accountMgr';
import { ASSETS } from 'helpers/config';
import ImgUser from 'assets/img/icons/user.svg';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import * as S from './styles';

interface Props {
  walletAddr: TxId | 'active_user';
  className?: string;
  small?: boolean;
  stack?: boolean;
}

function Avatar(props: Props) {
  const { walletAddr, small } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const profile: FullProfileType | null | undefined = useAppSelector((state) => {
    if (walletAddr === 'active_user') {
      return state.arweave.profile;
    } else {
      return state.profiles.byWalletId[walletAddr || ''];
    }
  });

  // const oauthProfile = walletAddr === 'active_user' ? Othent.getUserInfo() : null;
  // const oauthProfile = walletAddr === 'active_user' ? Othent.getUserInfo() : null;
  const avatar = AccountMgr.resolveAvatar(profile, null);
  const type = avatar !== ImgUser ? (!small ? 'default' : 'small') : 'placeholder';

  React.useEffect(() => {
    if (walletAddr?.length === 43 && profile === undefined) {
      dispatch(doFetchProfiles([walletAddr]));
    }
  }, [walletAddr, profile, dispatch]);

  return (
    <S.Avatar
      className="flip"
      type={type}
      stack={props.stack || false}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        navigate(`/profile/${walletAddr}`);
      }}
    >
      {walletAddr === 'loading' && <span>...</span>}
      {walletAddr !== 'loading' &&
        (type !== 'placeholder' ? (
          <img src={avatar} onError={(e: any) => (e.target.src = defaultProfilePic)} />
        ) : (
          <ReactSVG src={ASSETS.user} />
        ))}
    </S.Avatar>
  );
}

export default Avatar;
