import styled from 'styled-components';
import { STYLING } from 'helpers/config';

export const TopBanner = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  aspect-ratio: 16/3;
  overflow: hidden;
  margin-bottom: 40px;
  filter: invert(0%);
  transition: filter 0.4s;
  padding: 0 40px;
  box-sizing: border-box;

  .topBanner__image {
    flex-shrink: 0;
    min-width: 0;
    width: 30%;
    height: 100%;
    z-index: 0;
    overflow: hidden;
    outline: 1px solid black;
    border-radius: 50px;
    background-color: black;
    outline: 1px solid var(--color-gray3);
    outline-offset: 1px;

    transition:
      flex 1s,
      transform 1s;

    .topBanner__imageBackground {
      position: absolute;
      top: -20px;
      left: -20px;
      right: -20px;
      bottom: -20px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      filter: blur(10px);
      opacity: 0.4;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: contain;
      transition: border 0.4s;
      border-radius: 50px;
      transform: scale(1);
      transition: transform 0.4s;
    }

    &:hover {
      img {
        transform: scale(1.2);
      }
    }
  }
`;

export const Navigation = styled.div<{ side: string }>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: calc(50% - 25px);
  background: rgba(0, 0, 0, 0.1);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  user-select: none;

  left: ${(props) => (props.side === 'left' ? 0 : 'unset')};
  right: ${(props) => (props.side === 'right' ? 0 : 'unset')};

  * > div {
    margin-top: 6px;

    svg {
      overflow: visible;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: var(--color-gray4);
    svg {
      color: #ffffff;
    }
  }
`;

/*
[theme='dark'] {
  .topBanner__image {
    outline: 1px solid white;
    filter: invert(100%);
  }
}
*/
