import React from 'react';
import classnames from 'classnames';
import { ANS_110_TYPE_LIST, Ans110Types } from 'permaweb-sdk/dist/helpers/typesAns110';

import ImgUpload from 'assets/img/icons/upload.svg';
import Avatar from 'components/avatar';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal } from 'store/modal/slice';
import { doToast } from 'store/toast/thunks';

import './style.scss';
import { doSubmit } from './helpers';
import Files from './files';

export const ALLOWED_FILE_TYPES = 'image/png, image/jpeg, image/gif, video/mp4';

interface Props {
  onSuccess?: (id: string) => void;
}

export default function UploadAsset(props: Props) {
  const dispatch = useAppDispatch();

  const fileRef = React.useRef<HTMLInputElement>(null);
  const [files, setFiles] = React.useState<(string | File | ArrayBuffer)[]>([]);
  const titleRef = React.useRef<HTMLInputElement>(null);
  const descriptionRef = React.useRef<HTMLTextAreaElement>(null);
  const [type, setType] = React.useState<Ans110Types | undefined>('social-post');

  const [busy, setBusy] = React.useState(false);

  const walletAddress = useAppSelector((state) => state.arweave.walletAddress);
  const profile = useAppSelector((state) => state.arweave.profile);

  const name =
    profile?.channelTitle ||
    profile?.displayName ||
    (profile?.handle ? `@${profile.handle}` : '') ||
    walletAddress?.slice(0, 4) + '...' + walletAddress?.slice(-4);

  // From Y
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length) {
      let newFilesData: (string | ArrayBuffer)[] = [];
      let filesProcessed = 0;

      Array.from(e.target.files).forEach((file) => {
        if (file.type.startsWith('image/') || file.type.startsWith('video/')) {
          const reader = new FileReader();

          reader.onload = (event: ProgressEvent<FileReader>) => {
            if (event.target?.result) {
              newFilesData.push(event.target.result);
              filesProcessed++;

              if (filesProcessed === e.target.files?.length) {
                const updatedFiles = [...files, ...newFilesData];
                setFiles(updatedFiles);
              }
            }
          };

          reader.readAsDataURL(file);
        } else {
          filesProcessed++;
          if (filesProcessed === e.target.files?.length && newFilesData.length > 0) {
            const updatedFiles = [...files, ...newFilesData];
            setFiles(updatedFiles);
          }
        }
      });
    }
  };

  const handleCancel = () => {
    dispatch(closeModal());
  };

  const handleSubmit = () => {
    const title = titleRef?.current?.value;
    const description = descriptionRef?.current?.value;

    setBusy(true);
    dispatch(doSubmit({ title, description, type, files }))
      .then((id) => {
        props.onSuccess && props.onSuccess(id); // Can't use useNavigate() within a modal, so gotta pass it back to caller.
        dispatch(doToast('Asset created!', id, 'long'));
        dispatch(closeModal());
      })
      .catch((e: any) => dispatch(doToast(e.message)))
      .finally(() => setBusy(false));
  };

  return (
    <div className={classnames('uploadAsset', { 'uploadAsset--disabled': busy })}>
      <div className="uploadAsset__header">
        <Avatar walletAddr={'active_user'} />
        <div>{name}</div>
      </div>

      <div className="uploadAsset__body">
        <div className="uploadAsset__fileSelect" onClick={() => fileRef?.current?.click()}>
          <input
            ref={fileRef}
            type="file"
            accept={ALLOWED_FILE_TYPES}
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          <img src={ImgUpload} height="28" />
          <label>Upload Media</label>
        </div>

        <div className="uploadAsset__field">
          <label>Title</label>
          <input type="text" placeholder="Add a title" ref={titleRef} />
        </div>

        <div className="uploadAsset__field">
          <label>Description</label>
          <textarea placeholder="Fill in a description for the content" ref={descriptionRef} />
        </div>

        {/*<div className="uploadAsset__field uploadAsset__field--radio">*/}
        {/*  {TYPES.map((x) => {*/}
        {/*    return (*/}
        {/*      <div className="radioField" key={x} onClick={() => setType(x)}>*/}
        {/*        <input type="radio" value={x} checked={type === x} onChange={() => setType(x)}/>*/}
        {/*        <label>{x}</label>*/}
        {/*      </div>*/}
        {/*    );*/}
        {/*  })}*/}
        {/*</div>*/}

        <div className="uploadAsset__field uploadAsset__field--select">
          <label>Type</label>
          <select value={type} onChange={(e) => setType(e.target.value as Ans110Types)}>
            {ANS_110_TYPE_LIST.map((x) => {
              return (
                <option key={x} value={x}>
                  {x}
                </option>
              );
            })}
          </select>
        </div>

        <Files files={files} setFiles={setFiles} />
      </div>

      <div className="uploadAsset__submit">
        <button type="button" className="" onClick={handleCancel}>
          Cancel
        </button>
        <button type="button" className="primary" onClick={handleSubmit}>
          Post
        </button>
      </div>

      {busy && <div className="uploadAsset__spinner" />}
    </div>
  );
}
