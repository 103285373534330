import React from 'react';

import { GATEWAYS, TAGS } from 'permaweb-sdk/dist/helpers/config';
import { getQueryKey } from 'permaweb-sdk/dist/helpers/utils';
import { doFetchAssets } from 'permaweb-sdk/dist/implementations/rtk';

import AssetList from 'components/assetList';
import AssetListLoading from 'components/assetListLoading';
import { useAppDispatch } from 'store/hooks';

export interface IProps {
  address: string;
}

export default function ProfilePosts(props: IProps) {
  const { address } = props;
  const dispatch = useAppDispatch();

  const query = React.useMemo(() => {
    return {
      tags: { [TAGS.keys.implements]: 'ANS-110' },
      ids: null,
      cursor: null,
      owners: [address],
      gateway: GATEWAYS.goldsky,
      minBlock: undefined,
      paginator: 10,
    };
  }, [address]);

  const key = getQueryKey(query);

  const handleNextPage = () => {
    dispatch((dispatch: AppDispatch, getState: GetState) => {
      const state = getState();
      const cursor = state.assets.assetResultsByQueryKey[key]?.lastCursor;
      const fetching = state.assets.assetResultsByQueryKey[key]?.fetching;
      if (cursor && !fetching) {
        dispatch(doFetchAssets({ ...query, cursor }));
      }
    });
  };

  React.useEffect(() => {
    // First page
    dispatch(doFetchAssets(query));
  }, [query]);

  return (
    <div>
      <AssetList queryKey={key} onFetchMore={handleNextPage} />
      <AssetListLoading queryKey={key} />
    </div>
  );
}
