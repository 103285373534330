import { doWalletBalance } from 'permaweb-sdk/dist/implementations/rtk';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import * as S from './styles';

interface Props {
  className?: string;
}

function WalletBalance(props: Props) {
  const dispatch = useAppDispatch();

  const fetching = useAppSelector((state) => state.arweave.flags.fetchingBalance);
  const ar = useAppSelector((state) => state.arweave.walletBalance.ar);
  const u = useAppSelector((state) => state.arweave.walletBalance.u);

  const arStr = isNaN(Number(ar)) ? '---' : ar;
  const uStr = isNaN(Number(u)) ? '---' : u;

  function update() {
    dispatch(doWalletBalance());
  }

  return (
    <S.WalletBalance updating={Boolean(fetching)} onClick={update}>
      <S.WalletBalanceEntries>
        <S.WalletBalanceEntry>
          <S.WalletBalanceEntryLabel>AR Balance</S.WalletBalanceEntryLabel>
          <S.WalletBalanceEntryValue>{arStr} AR</S.WalletBalanceEntryValue>
        </S.WalletBalanceEntry>
        <S.WalletBalanceEntry>
          <S.WalletBalanceEntryLabel>$U Balance</S.WalletBalanceEntryLabel>
          <S.WalletBalanceEntryValue>{uStr} $U</S.WalletBalanceEntryValue>
        </S.WalletBalanceEntry>
      </S.WalletBalanceEntries>
    </S.WalletBalance>
  );
}

export default WalletBalance;
