import { STYLING } from 'helpers/config';
import styled from 'styled-components';

export const Navigation = styled.div<{ desktop: boolean; show: boolean }>`
  position: ${(props) => (props.desktop ? 'fixed' : 'relative')};
  overflow: hidden;
  top: ${(props) => (props.desktop ? '64px' : 0)};
  left: ${(props) => (props.show ? STYLING.spacing.s : '-300px')};
  min-width: 250px;
  flex: 1;
  flex-shrink: 0;
  box-sizing: border-box;
  transition:
    flex 0.4s,
    min-width 0.4s,
    padding 0.4s,
    left 0.4s;
`;

export const NavigationContainer = styled.div`
  background-color: var(--color-gray3);
  border-radius: var(--border-radius);
  overflow: hidden;
  border: 2px solid var(--color-background);
  padding-bottom: ${STYLING.spacing.xxs};
`;

export const NavigationHeader = styled.div`
  width: 100%;
  border-bottom: 2px solid var(--color-background);
  background-color: var(--color-gray4);
  color: white;
  padding-top: 6px;
  margin-bottom: 8px;
  padding-left: ${STYLING.spacing.s};
  font-weight: ${STYLING.font.weight.l};
`;

export const NavigationEntries = styled.div<{ desktop: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.desktop ? 'column' : 'row')};
`;

export const NavigationEntry = styled.div`
  display: flex;
  justify-content: center;
  padding-left: ${STYLING.spacing.s};
  padding-top: 2px;
  padding-bottom: 2px;
  padding-right: ${STYLING.spacing.xxs};
  font-size: ${STYLING.font.size.s};
  font-weight: ${STYLING.font.weight.l};
  flex: 1;

  &:hover {
    cursor: pointer;
    color: white;
    background: var(--color-gray4);
  }
`;

export const NavigationEntryLabel = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
`;

export const Checkbox = styled.label<{ desktop: boolean }>`
  display: flex;
  justify-content: center;
  position: relative;
  padding-left: 24px;
  font-size: 14px;
  font-weight: 500;
  opacity: 1;
  margin-left: ${(props) => (props.desktop ? 'auto' : 0)};
  // margin-top: 1px;

  input {
    position: absolute;
    opacity: 0;
    display: none;
    cursor: pointer;
    height: 0;
    width: 0;
  }
`;

export const Checkmark = styled.span<{ checked: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border-radius: 6px;
  border: 2px solid black;
  background-color: white;

  &:after {
    content: '';
    position: absolute;
    display: ${(props) => (props.checked ? 'block' : 'none')};
    left: 4px;
    top: 0px;
    width: 5px;
    height: 10px;
    border: solid black;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
