import { Action } from '@reduxjs/toolkit';
import StampMgr from 'helpers/arweave/stampMgr';
import { selectStampedContentIds } from 'store/stamps/selectors';
import stampsSlice from 'store/stamps/slice';

export const effResolveMyStampedIds = {
  predicate: (action: Action, state: AppState, prevState: AppState) => {
    return (
      state.arweave.walletAddress !== prevState.arweave.walletAddress || state.stamps.stamps !== prevState.stamps.stamps
    );
  },
  effect: async (action: Action, listenerApi: any) => {
    const { dispatch, getState } = listenerApi;
    const state: AppState = getState();
    const walletAddress = state.arweave.walletAddress;

    if (!walletAddress) {
      dispatch(stampsSlice.actions.updateMyStampedIds([]));
      return;
    }

    const contentIds = selectStampedContentIds(state);
    if (contentIds.length === 0) {
      dispatch(stampsSlice.actions.updateMyStampedIds([]));
      return;
    }

    const myStampedIds = await StampMgr.getHasStampedForIds(contentIds, walletAddress);
    dispatch(stampsSlice.actions.updateMyStampedIds(myStampedIds));
  },
};
