import styled from 'styled-components';
import { STYLING } from 'helpers/config';

export const ProfilePage = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${STYLING.spacing.m};
`;

export const Spinner = styled.div`
  margin-top: ${STYLING.spacing.m};
  height: 4px;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: loading 4s ease-in-out infinite;
  border-radius: 2px;

  @keyframes loading {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
`;

export const Header = styled.div`
  width: 100%;
`;

export const Banner = styled.div`
  background-color: var(--color-gray4);
  // max-width: 100%;
  height: 225px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const AvatarWrapper = styled.div`
  margin-top: calc(${STYLING.spacing.l} * -1);
  margin-left: ${STYLING.spacing.m};

  div,
  img,
  svg {
    width: 150px !important;
    height: 150px !important;
    flex-shrink: 0;
  }
`;

export const Name = styled.div`
  font-weight: bold;
`;

export const Handle = styled.div`
  font-size: smaller;
  opacity: 0.7;
`;

export const Bio = styled.div`
  margin-top: ${STYLING.spacing.m};
  margin-bottom: ${STYLING.spacing.m};
`;

export const Section = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Stats = styled.div`
  display: flex;
  gap: ${STYLING.spacing.l};
  margin-top: ${STYLING.spacing.m};
  margin-bottom: ${STYLING.spacing.m};

  *:hover {
    cursor: pointer;
  }
`;

export const Stat = styled.div<{ none: boolean }>`
  display: flex;
  gap: ${STYLING.spacing.s};
  text-align: center;
  pointer-events: ${(props) => (props.none ? 'none' : 'default')};
`;

export const ButtonEdit = styled.button<{ hide: boolean }>`
  width: 120px;
  height: 40px;
  border-radius: 50px;
  border-style: unset;
  background-color: var(--color-gray3);
  font-size: 18px;
  font-weight: 600;
  color: black;
  text-decoration: none;
  margin-left: auto;
  display: ${(props) => (props.hide ? 'none' : 'flex')};

  &:hover {
    cursor: pointer;
    background-color: var(--color-gray4);
    color: white;
  }

  &.followButton--disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`;

export const Actions = styled.div`
  display: flex;
  gap: ${STYLING.spacing.m};
`;

export const Value = styled.div`
  font-weight: bold;
`;

export const Label = styled.div`
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
`;
