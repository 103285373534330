import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Ar from 'helpers/arweave/ar';
import Loading from 'components/loading';
import Comments from 'components/comments';
import Renderer from 'components/renderer';
import { useAppSelector } from 'store/hooks';

import * as S from './styles';

function Entry() {
  const { id } = useParams();
  const gateway = useAppSelector((state) => state.arweave.standardGateway);
  const [isLoading, setIsLoading] = useState(true);
  const [entry, setEntry] = useState<any>(null);
  const iFrame = useRef<HTMLIFrameElement | null>(null);

  const query = `query {
    transaction(id: "${id}") {
      id
      owner {
        address
      }
      data {
        size
        type
      }
      tags {
        name
        value
      }
    }
  }`;

  useEffect(() => {
    fetchData(query, gateway);
  }, [id]);

  async function fetchData(query: any, gateway: any) {
    try {
      const result = await Ar.fetchGraphQL(query, gateway);
      parseEntry(result);
      setIsLoading(false);
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  }

  function parseEntry(result: any) {
    result = result.transaction;
    let newEntry = {
      id: result.id,
      type: result.tags.find((i: any) => i.name === 'Type')?.value || undefined,
      title: result.tags.find((i: any) => i.name === 'Title')?.value || undefined,
      banner: result.tags.find((i: any) => i.name === 'Banner')?.value || undefined,
      thumbnail: result.tags.find((i: any) => i.name === 'Thumbnail')?.value || undefined,
      owner: {
        id: result.owner.address,
      },
    };

    setEntry(newEntry);
  }

  useEffect(() => {
    if (iFrame && iFrame.current) iFrame.current.src = `https://alex-renderers.arweave.dev/?tx=${id}`;
  }, [entry]);

  return (
    <S.EntryPage>
      {entry && <Renderer type={entry.type} address={id} />}
      <Loading loading={isLoading} />
      <Comments assetId={id} />
    </S.EntryPage>
  );
}

export default Entry;
