import { deDuplicate } from 'helpers/arrays';
import Ar from 'helpers/arweave/ar';
import { getUnknownError, throwIf } from 'helpers/error';
import { findTag } from 'helpers/tags';
import { getStampsQuery, gqlStampById } from 'store/stamps/query';
import { selectHasStamped } from 'store/stamps/selectors';
import stampsSlice from 'store/stamps/slice';
import StampMgr from 'helpers/arweave/stampMgr';

export function doFetchRecentStamps(days: number = 7) {
  return async (dispatch: AppDispatch, getState: GetState) => {
    const SecondsInADay = 86400;

    const state = getState();
    const fetching = state.stamps.fetchingStamps;
    if (fetching) {
      // prevent double dispatch, refactor to createAsyncThunk "condition"
      return;
    }
    dispatch(doFetchVouched());
    dispatch(stampsSlice.actions.stampsFetching(true));
    const allEdges = [] as Array<IEdge>;

    try {
      const now = Math.floor(Date.now() / 1000);
      const targetTimestamp = now - SecondsInADay * days;

      const data = await Ar.fetchGraphQL(getStampsQuery());
      let numEdges = data.transactions.edges.length;
      if (!numEdges) {
        // error
      }
      const firstConfirmedEdge = data.transactions.edges.find((e: IEdge) => e.node.block !== null);
      const newestTimestamp = firstConfirmedEdge.node.block.timestamp;
      const newestCursor = firstConfirmedEdge.cursor;
      let lastEdge = data.transactions.edges[numEdges - 1];
      let oldestCursor = lastEdge.cursor;
      let oldestTimestamp = lastEdge.node.block.timestamp;
      let hasNextPage = data.transactions.pageInfo.hasNextPage;
      let cursor = numEdges > 0 ? lastEdge.cursor : '';

      const stampEdges: Array<IEdge> = data.transactions.edges;
      allEdges.splice(allEdges.length, 0, ...stampEdges);
      while (hasNextPage && oldestTimestamp > targetTimestamp) {
        let newData = await Ar.fetchGraphQL(getStampsQuery(cursor));
        hasNextPage = newData.transactions.pageInfo.hasNextPage;
        numEdges = newData.transactions.edges.length;
        lastEdge = newData.transactions.edges[numEdges - 1];
        oldestCursor = lastEdge.cursor;
        oldestTimestamp = lastEdge.node.block.timestamp;
        cursor = lastEdge.cursor;
        allEdges.splice(allEdges.length, 0, ...newData.transactions.edges);
      }

      dispatch(
        stampsSlice.actions.stampsFetched({
          stamps: allEdges,
          stampsDayRange: days,
          newestTimestamp: newestTimestamp,
          oldestTimestamp: oldestTimestamp,
          newestCursor: newestCursor,
          oldestCursor: oldestCursor,
        })
      );
    } catch (e) {
      const message = getUnknownError(e);
      console.error(message);
      dispatch(stampsSlice.actions.stampsFetching(false));
    }
  };
}

export function doFetchStampersForContent(contentIds: Array<string>) {
  return async (dispatch: AppDispatch, getState: GetState) => {
    let ids = contentIds;

    if (ids.length === 1 && ids[0] === 'all') {
      const state = getState();
      ids = deDuplicate(state.stamps.stamps.map((stamp) => findTag(stamp?.node?.tags, 'Data-Source') || ''));
    }

    dispatch(stampsSlice.actions.stampersForContentFetching(ids));
    const stamperData = await StampMgr.getStampersForDataSourceIds(ids);
    dispatch(stampsSlice.actions.stampersForContentFetched({ data: stamperData, ids }));
  };
}
export function doFetchVouched() {
  return async (dispatch: AppDispatch, getState: GetState) => {
    const state = getState();
    dispatch(stampsSlice.actions.vouchedFetching(true));
    const vouchFetcher = StampMgr.getVouchedFetcher();
    const vouched = await vouchFetcher();
    const vouchedStateHash = state.stamps.vouchedStateHash;
    if (vouchedStateHash !== vouched.stateHash) {
      dispatch(stampsSlice.actions.vouchedFetched(vouched));
    }
    dispatch(stampsSlice.actions.vouchedFetching(false));
  };
}
export function doFetchCountsForIds(ids: Array<string>) {
  return async (dispatch: AppDispatch, getState: GetState) => {
    // This function has now been reduced to just calling doFetchStampersForContent.
    // Leaving it here for now, in case we want to bring back StampMgr.getLib().counts(ids).
    try {
      await dispatch(doFetchStampersForContent(ids)); // const counts: CountResult[] = await StampMgr.getLib().counts(ids);
    } catch (e) {
      const message = getUnknownError(e);
      console.error(message);
    }
  };
}

export function doStampAsset(id: TxId, amount?: number) {
  return async (dispatch: AppDispatch, getState: GetState) => {
    try {
      const state = getState();
      const hasStamped = selectHasStamped(state, id);
      const walletAddress = state.arweave.walletAddress;

      if (state.stamps.idsBeingStamped.includes(id)) {
        return;
      }

      throwIf(hasStamped, 'Item already stamped by you.');
      throwIf(!walletAddress, 'Wallet not connected');
      throwIf(!id, 'No id provided to stamp');

      dispatch(stampsSlice.actions.stampingStarted(id));

      const stamp: any = await StampMgr.getLib().stamp(id, amount || 0, [{ name: '', value: '' }]);

      const stampSuccess = stamp?.bundlrResponse?.id || stamp?.id;
      throwIf(!stampSuccess, 'Bundlr failed'); // does bundlrResponse provide error msg?

      const data = await Ar.fetchGraphQL(gqlStampById(stamp.id));
      if (data.transactions.edges.length > 0) {
        const stampEdge = data.transactions.edges[0];
        dispatch(stampsSlice.actions.stampingDone({ id, stampEdge }));
      }

      await dispatch(doFetchCountsForIds([id]));
    } catch (e: any) {
      console.log('doStampAsset:', e);
      dispatch(stampsSlice.actions.stampingDone({ id }));
      throw e;
    }
  };
}
