import styled from 'styled-components';

import { STYLING } from 'helpers/config';

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  margin-left: ${STYLING.spacing.s};
`;

export const PWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const LAction = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  border: none;
  height: 28px;
  border-radius: 50px;
  span {
    color: white;
    font-size: ${STYLING.font.size.xs};
    font-weight: ${STYLING.font.weight.xl};
    padding: 0 ${STYLING.spacing.xs};
  }
  &:hover {
    cursor: pointer;
  }
  @media (max-width: ${STYLING.cutoffs.medium}) {
    display: none;
  }
`;

export const FlexAction = styled.div`
  display: flex;
  align-items: center;
  svg {
    height: 25px;
    width: 20px;
    margin: 0 -2.5px 0 11.5px;
  }
`;

export const Dropdown = styled.ul`
  width: 362px;
  max-width: 90vw;
  padding: 15px 0 10px 0;
  position: absolute;
  z-index: 6;
  top: 62px;
  right: 30px;
  border-radius: ${STYLING.dimensions.radius.primary};

  @media (max-width: ${STYLING.cutoffs.medium}) {
    right: 15px;
  }
`;

export const DHeaderWrapper = styled.div`
  width: 100%;
`;

export const DHeaderFlex = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid var(--color-border);
  &:hover {
    cursor: pointer;
  }
`;

export const DHeader = styled.div`
  margin: 0 0 0 10px;
  p {
    color: var(--color-text);
    font-size: ${(props) => props.theme.typography.size.small};
    font-weight: ${(props) => props.theme.typography.weight.bold};
    &:hover {
      // color: var(--color-text);
    }
  }
  span {
    color: var(--color-text);
    font-size: ${(props) => props.theme.typography.size.xxSmall};
    font-weight: ${(props) => props.theme.typography.weight.medium};
    &:hover {
      // color: var(--color-text);
    }
  }
  p,
  span {
    transition: all 100ms;
    &:hover {
      cursor: pointer;
    }
  }
`;

export const BWrapper = styled.div`
  padding: 15px;
  border-top: 1px solid var(--color-border);
  border-bottom: 1px solid var(--color-border);
  p {
    color: var(--color-text);
    font-size: ${(props) => props.theme.typography.size.small};
    font-weight: ${(props) => props.theme.typography.weight.bold};
  }
  span {
    color: var(--color-text);
    font-size: ${(props) => props.theme.typography.size.small};
    font-weight: ${(props) => props.theme.typography.weight.medium};
  }
  > * {
    &:not(:last-child) {
      margin: 0 0 10px 0;
    }
    &:last-child {
      margin: 0;
    }
  }
`;

export const BHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BHeaderActions = styled.div`
  display: flex;
  align-items: center;
  > * {
    &:not(:last-child) {
      margin: 0 10px 0 0;
    }
    &:last-child {
      margin: 0;
    }
  }
  span {
    font-size: ${(props) => props.theme.typography.size.xxSmall} !important;
  }
`;

export const DBodyWrapper = styled.ul`
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid var(--color-border);
  li {
    text-align: center;
    height: 37.5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: ${(props) => props.theme.typography.size.small};
    font-family: ${(props) => props.theme.typography.family.primary};
    font-weight: ${(props) => props.theme.typography.weight.bold};
    color: var(--color-text);
    border: 1px solid transparent;
    padding: 0 15px;
    transition: all 100ms;
    &:hover {
    }
  }
`;

export const DFooterWrapper = styled(DBodyWrapper)`
  padding: 10px 0 0 0;
  border-top: 1px solid var(--color-border);
  border-bottom: none;
`;

export const LoginWrapper = styled.div``;
