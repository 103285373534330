export enum WalletEnum {
  arConnect = 'arconnect',
  arweaveApp = 'arweave.app',
  othent = 'othent',
}

export type RefType = { current: HTMLElement };

export type UploadStepType = 'details' | 'license' | 'checks';

export type ProfileType = {
  txId: string;
  channelTitle: string | null;
  displayName: string | null;
  handle: string | null;
  avatar: string | null;
  walletAddress: string;
  profileIndex: string | null;
  banner: string | null;
};
