export function convertResponseToJson(response: Response): Promise<any> {
  return new Promise((resolve, reject) => {
    if (response.ok) {
      resolve(response.json());
    } else {
      response
        .text()
        .then((text) => reject(new Error(text)))
        .catch(() => reject(new Error(response.statusText)));
    }
  });
}

export function convertResponseToText(response: Response): Promise<string> {
  return new Promise((resolve, reject) => {
    if (response.ok) {
      resolve(response.text());
    } else {
      response
        .text()
        .then((text) => reject(new Error(text)))
        .catch(() => reject(new Error(response.statusText)));
    }
  });
}

// [] TODO: combine the above into 1 function, checking the content-type
