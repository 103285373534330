import { GATEWAYS } from 'permaweb-sdk/dist/helpers/config';
import { GQLFetchArgsType } from 'permaweb-sdk/dist/helpers/types';
import { doFetchAssets } from 'permaweb-sdk/dist/implementations/rtk';
import { selectStampedContentIdForUser } from 'store/stamps/selectors';
import { doFetchRecentStamps, doFetchStampersForContent } from 'store/stamps/thunks';
import { doToast } from 'store/toast/thunks';

/**
 * Unclear how stamp-fetching will change, so for now fetching the first page
 * for "my stamped items" involves fetching stampers (if not already), then
 * returning the finalized query to the UI.
 *
 * @param address
 */
export function doFirstPage(address: WalletId) {
  return async (dispatch: AppDispatch, getState: GetState): Promise<GQLFetchArgsType | null> => {
    try {
      let state = getState();

      if (state.stamps.fetchingStamps) {
        return null; // kludge to circumvent the StrictMode.
      }

      if (state.stamps.stampsDayRange === 0) {
        await dispatch(doFetchRecentStamps(30));
        await dispatch(doFetchStampersForContent(['all']));
        state = getState();
      }

      const contentIds = selectStampedContentIdForUser(state, address);

      const query = {
        ids: contentIds,
        cursor: null,
        gateway: GATEWAYS.goldsky,
        minBlock: undefined,
        tags: {},
        owners: null,
        paginator: 10,
      };

      dispatch(doFetchAssets(query));
      return query;
    } catch (e: any) {
      dispatch(doToast('Failed to load stamps list', e.message));
      return null;
    }
  };
}
