import classnames from 'classnames';
import React from 'react';
import { doFetchFollowers, doFetchFollowings } from 'permaweb-sdk/dist/implementations/rtk';

import Avatar from 'components/avatar';
import FollowButton from 'components/followButton';
import AccountMgr from 'helpers/arweave/accountMgr';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import * as S from './styles';

interface Props {
  id: WalletId;
  type: 'Followers' | 'Followings';
  viewFormat: 'modal' | 'page';
}

export default function FollowList(props: Props) {
  const { id, type, viewFormat } = props;

  const dispatch = useAppDispatch();
  const idIsMe = useAppSelector((state) => state.arweave.walletAddress === id);
  const profiles = useAppSelector((state) => state.profiles.byWalletId);
  const list = useAppSelector((state) =>
    type === 'Followers' ? state.follow.followers[id] : state.follow.followings[id]
  );
  const isFetching = useAppSelector((state) =>
    type === 'Followers' ? state.follow.flags.fetchingFollowers : state.follow.flags.fetchingFollowings
  );

  React.useEffect(() => {
    if (!list && id) {
      const thunk = type === 'Followers' ? doFetchFollowers : doFetchFollowings;
      const timeout = setTimeout(() => dispatch(thunk(id)), 1000);
      return () => timeout && clearTimeout(timeout);
    }
  }, [type, list, id]);

  if (!list) {
    return (
      <S.FollowList>
        <S.Spinner />
      </S.FollowList>
    );
  }

  return (
    <S.FollowList modal={viewFormat === 'modal'}>
      {!list && (
        <>
          <h1>{type}</h1>
          <S.Spinner />
        </>
      )}

      {list && list.length === 0 && (
        <>
          <h1>{type}</h1>
          <p>Empty!</p>
        </>
      )}

      {list && list.length > 0 && (
        <>
          <h1>
            {type} {`(${list.length})`}
          </h1>
          <S.List>
            {list.map((id: TxId) => {
              const profile = profiles[id];
              const tooltip = JSON.stringify(profile, null, 2);
              const name = AccountMgr.resolveName(profile);
              const handle = AccountMgr.resolveHandle(profile);

              return (
                <S.Row key={id} title={tooltip}>
                  <Avatar walletAddr={id} />

                  <S.Infos title={tooltip}>
                    <S.Name>{name}</S.Name>
                    <S.Handle>@{handle}</S.Handle>

                    {profile?.bio && (
                      <S.Bio title={profile.bio}>
                        {profile.bio.slice(0, 80)}
                        {profile.bio.length > 80 && '...'}
                      </S.Bio>
                    )}
                  </S.Infos>

                  {idIsMe && type === 'Followings' && <FollowButton address={id} />}
                </S.Row>
              );
            })}
          </S.List>
        </>
      )}
    </S.FollowList>
  );
}
