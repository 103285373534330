import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MakeState } from 'store/utils';

export interface ExampleState {
  todos: string[];
  cursor: string | null | undefined;
}

const exampleSlice = createSlice({
  name: 'example',
  initialState: MakeState<ExampleState>({
    todos: [],
    cursor: null,
  }),
  reducers: {
    todoAdded: (state, action: PayloadAction<string>) => {
      state.todos.push(action.payload);
    },
    todoRemoved: (state, action: PayloadAction<string>) => {
      state.todos = state.todos.filter((todo) => todo !== action.payload);
    },
    todosFetched: (state, action: PayloadAction<ITransactions>) => {
      const { edges, pageInfo } = action.payload;
      state.todos = edges.map((edge) => edge.node.id);
      state.cursor = pageInfo.hasNextPage ? edges[edges.length - 1].cursor : null;
    },
  },
});

/**
 * 4 ways to specify the action to listen to:
 *   1. type: 'example/todoAdded'
 *   2. actionCreator: exampleSlice.actions.todoAdded
 *   3. matcher: isAnyOf(exampleSlice.actions.todoAdded, exampleSlice.actions.todoRemoved)
 *   4. predicate: (action, curState, prevState) => boolean
 */
export const effSendAnalyticsExample = {
  actionCreator: exampleSlice.actions.todoAdded,
  effect: async (action: Action, listenerApi: any) => {
    alert('Analytics sent');
  },
};

export default exampleSlice;
