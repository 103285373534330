import React from 'react';
import { ReactSVG } from 'react-svg';
import Icon from 'components/icon';
import Renderer from 'components/renderer';
import { ASSETS } from 'helpers/config';
import { useAppSelector } from 'store/hooks';
import { getPrimaryRenderer, getCompatibleRenderers } from 'permaweb-sdk/dist/helpers/renderer';

import './style.scss';

import interesting from 'assets/img/icons/renderer/interesting.png';
import alex from 'assets/img/icons/renderer/alex.png';
import odysee from 'assets/img/icons/renderer/odysee.png';
import ahem from 'assets/img/icons/renderer/ahem.png';
import ChatApps from 'components/miniApps/chatApps';

const icons: any = {
  'interesting-renderer': interesting,
  'alex-renderers': alex,
  'odysee-renderer': odysee,
  'ahem-renderer': ahem,
};

function MetaRenderer(props: any) {
  const { asset, expanded } = props;
  const rendererRef = React.useRef<HTMLDivElement>(null);
  const [isMinimized, setIsMinimized] = React.useState(false);
  const [showTabLabels, setShowTabLabels] = React.useState(true);
  const myProfile = useAppSelector((state) => state.arweave.profile);
  const renderer = {
    primary: getPrimaryRenderer(asset.renderWith),
    optional: getCompatibleRenderers(asset.type, asset.contentType, getPrimaryRenderer(asset.renderWith)).reverse(),
  };

  const useInterestingRenderer =
    (!renderer.primary?.key && !renderer.optional.length) ||
    renderer.primary?.key === 'interesting-renderer' ||
    renderer.optional.some((renderer) => renderer.key === 'interesting-renderer') ||
    asset.dataProtocol === 'Collection';
  const showAhemRenderer =
    renderer.primary?.key !== 'alex-renderers' &&
    !renderer.optional.some((renderer) => renderer.key === 'alex-renderers');
  const [activeTab, setActiveTab] = React.useState(
    useInterestingRenderer ? 'interesting' : renderer.primary?.key ? renderer.primary?.key : renderer.optional[0]?.key
  );
  const { iframeURL, iFrameRef, handleIframeLoad } = ChatApps({
    connectedProfileAddress: myProfile && myProfile.walletAddress,
    chatType: activeTab,
    addTabs: `${asset.id}:Content,InterestingChat:Sitewide,Global:Global`,
  });

  /*
  if (asset.id === 'gJFnURgFKHfFgSv-VQ8k42WtA8x4KFuSY_Oax_qK9i8') {
    console.log('asset: ', asset);
    console.log('renderer: ', renderer);
  }
  */

  function copyToClipboard() {
    navigator.clipboard.writeText(asset.id);
  }

  function replaceImage(e: any, name: string) {
    const placeholder = document.createElement('div');
    placeholder.innerHTML = name.charAt(0);
    e.target.replaceWith(placeholder);
  }

  React.useEffect(() => {
    setIsMinimized(false);
  }, [activeTab]);

  React.useEffect(() => {
    setIsMinimized(!expanded);
  }, [expanded]);

  React.useEffect(() => {
    const handleResize = () => {
      if (rendererRef.current) {
        const rendererWidth = rendererRef.current.clientWidth;

        if ((rendererWidth / 100) * 30 < 200 && showTabLabels) {
          setShowTabLabels(false);
        } else {
          setShowTabLabels(true);
        }
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="metaRenderer" ref={rendererRef}>
      <div className="metaRenderer__header">
        <div className="metaRenderer__id" onClick={copyToClipboard}>
          <p>{asset.id}</p>
          <Icon icon="copy" size={14} />
        </div>
        <div className="metaRenderer__tabs">
          <div
            className={`metaRenderer__tab${activeTab === 'bazar' ? ' metaRenderer__tab--active' : ''}`}
            onClick={() => setActiveTab('bazar')}
          >
            <ReactSVG src={ASSETS.bazar} className="padding flip" />
            {showTabLabels && <span>BazAR</span>}
          </div>
          <div
            className={`metaRenderer__tab${activeTab === 'chat' ? ' metaRenderer__tab--active' : ''}`}
            onClick={() => setActiveTab('chat')}
          >
            <div>C</div>
            {showTabLabels && <span>Chat</span>}
          </div>
          <div
            className={`metaRenderer__tab${activeTab === 'chat_group' ? ' metaRenderer__tab--active' : ''}`}
            onClick={() => setActiveTab('chat_group')}
          >
            <div>G</div>
            {showTabLabels && <span>Group Chat</span>}
          </div>
          {renderer.primary && (
            <div
              className={`metaRenderer__tab${activeTab === renderer.primary.key ? ' metaRenderer__tab--active' : ''}`}
              onClick={() => setActiveTab(renderer.primary.key)}
            >
              {icons[renderer.primary.key] ? (
                <img src={icons[renderer.primary.key]} alt={renderer.primary.name} />
              ) : renderer.primary.name ? (
                <div>{renderer.primary.name.charAt(0)}</div>
              ) : (
                ''
              )}
              {showTabLabels && <span>{renderer.primary.name}</span>}
            </div>
          )}
          {renderer.optional &&
            renderer.optional.map((renderer: any, i: number) => {
              if (renderer.key === 'interesting-renderer') return;
              if (renderer.key === 'ahem-renderer' && !showAhemRenderer) return;
              return (
                <div
                  key={i}
                  className={`metaRenderer__tab${activeTab === renderer.key ? ' metaRenderer__tab--active' : ''}`}
                  onClick={() => setActiveTab(renderer.key)}
                >
                  <>
                    {icons[renderer.key] ? (
                      <img
                        src={icons[renderer.key]}
                        alt={renderer.name}
                        onError={(e) => replaceImage(e, renderer.Name)}
                      />
                    ) : (
                      <div>{renderer.name.charAt(0)}</div>
                    )}
                  </>
                  {showTabLabels && <span>{renderer.name}</span>}
                </div>
              );
            })}
          {useInterestingRenderer && (
            <div
              className={`metaRenderer__tab${activeTab === 'interesting' ? ' metaRenderer__tab--active' : ''}`}
              onClick={() => setActiveTab('interesting')}
            >
              <img src={icons['interesting-renderer']} alt="Interesting" />
              {showTabLabels && <span>Interesting</span>}
            </div>
          )}
        </div>
        <div
          className={`metaRenderer__scale${!isMinimized ? ' metaRenderer__scale--minimize' : ''}`}
          onClick={() => setIsMinimized(!isMinimized)}
        >
          <Icon icon="arrowDown" size={24} height={18} />
        </div>
      </div>
      <div className={`rendererWrapper${isMinimized ? ' rendererWrapper--minimized' : ''}`}>
        {(() => {
          switch (activeTab) {
            case 'interesting':
              return <Renderer asset={asset} />;
            case 'ahem-renderer':
              return <Renderer asset={asset} />;
            case 'chat':
            case 'chat_group':
              return <iframe src={iframeURL} onLoad={handleIframeLoad} ref={iFrameRef} loading="lazy" />;
            case 'bazar':
              return <iframe src={`https://bazar.arweave.dev/#/asset/${asset.id}`} loading="lazy" />;
            case 'none':
              return;
            default:
              return <iframe src={`https://${activeTab}.arweave.dev/?tx=${asset.id}`} loading="lazy" />;
          }
        })()}
      </div>
    </div>
  );
}

export default MetaRenderer;
