export const pushIfUnique = <T>(arr: T[], item: T): void => {
  if (!arr.includes(item)) {
    arr.push(item);
  }
};

export const spliceIfExists = <T>(arr: T[], item: T): void => {
  const index = arr.indexOf(item);
  if (index > -1) {
    arr.splice(index, 1);
  }
};

export const deDuplicate = <T>(arr: T[]): T[] => Array.from(new Set(arr));

export const EMPTY_ARRAY = Object.freeze([]);
