import React from 'react';
import { doFetchProfiles, doFollow } from 'permaweb-sdk/dist/implementations/rtk';

import AccountMgr from 'helpers/arweave/accountMgr';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { doToast } from 'store/toast/thunks';
import * as S from './styles';

interface Props {
  address: TxId;
}

function FollowButton(props: Props) {
  const dispatch = useAppDispatch();
  const { address } = props;
  const walletAddress = useAppSelector((state) => state.arweave.walletAddress) || '';
  const isFollowing = useAppSelector((state) => state.follow.followings[walletAddress]?.includes(address));
  const busy = useAppSelector((state) => state.follow.flags.togglingFollow);
  const profile = useAppSelector((state) => state.profiles.byWalletId[address]);

  const followButtonText = isFollowing ? 'Unfollow' : 'Follow';
  const handle = AccountMgr.resolveHandle(profile);

  const str = {
    follow: {
      pass: `You are now following ${handle}`,
      fail: `Failed to follow ${handle}`,
    },
    unfollow: {
      pass: `No longer following ${handle}`,
      fail: `Failed to unfollow ${handle}`,
    },
  };

  const toggleFollow = () => {
    dispatch(doFollow(address, !isFollowing))
      .then(() => {
        const msg = str[isFollowing ? 'unfollow' : 'follow'].pass;
        dispatch(doToast(msg, ''));
      })
      .catch((e: any) => {
        const msg = str[isFollowing ? 'unfollow' : 'follow'].fail;
        dispatch(doToast(msg, e.message));
      });
  };

  React.useEffect(() => {
    if (!profile) {
      dispatch(doFetchProfiles([address]));
    }
  }, [address, profile, dispatch]);

  return (
    <S.FollowButton disabled={Boolean(busy)} onClick={toggleFollow}>
      {followButtonText}
    </S.FollowButton>
  );
}

export default FollowButton;
