import { WalletPermission } from 'permaweb-sdk/dist/helpers/types';
import { SdkConfig } from 'permaweb-sdk/dist/implementations/rtk';
import { assets } from './assets';
export const APP_NAME = process.env.REACT_APP_NAME || 'Interesting';
export const APP_LOGO_URL = process.env.REACT_APP_LOGO_URL || '';
export const APP_VERSION = process.env.REACT_APP_VERSION || '0.0.0';

import { UploadStepType, WalletEnum } from './types';

export const APP = {
  name: 'Interesting',
};

export const WALLET_PERMISSIONS: WalletPermission[] = [
  'ACCESS_ADDRESS',
  'ACCESS_PUBLIC_KEY',
  'SIGN_TRANSACTION',
  'DISPATCH',
  'SIGNATURE',
];

export const SDK_CONFIG: SdkConfig = {
  app: {
    title: APP_NAME,
    type: 'Social',
    category: 'Community',
  },
  balances: {
    currencies: ['ar', 'u', 'turbo'],
    poll: {
      intervalMs: 2 * 60000,
      currencies: ['ar', 'u'],
    },
  },
  comments: {
    pageSize: 10,
    replyStyle: 'nested',
    ticker: 'ATOMIC ASSET - COMMENT',
    dataProtocol: 'comment',
    dataProtocolVersion: '0.1',
  },
  walletPermissions: WALLET_PERMISSIONS,
};

export const GATEWAYS = {
  arweave: 'arweave.net',
  goldsky: 'arweave-search.goldsky.com',
};

export const API_CONFIG = {
  protocol: 'https',
  port: 443,
  timeout: 40000,
  logging: false,
};

export const AR_WALLETS = [
  { type: WalletEnum.arConnect, logo: assets.wallets.arconnect },
  { type: WalletEnum.othent, logo: assets.wallets.othent },
];

export const ASSET_CONTRACT_SRC = 'Of9pi--Gj7hCTawhgxOwbuWnFI1h24TTgO5pw8ENJNQ';
export const ALLOWED_BANNER_TYPES = 'image/png, image/jpeg, image/gif';
export const ALLOWED_AVATAR_TYPES = 'image/png, image/jpeg, image/gif';

export const DOM = {
  loader: 'loader',
  notification: 'notification',
  overlay: 'overlay',
};

export const STYLING = {
  cutoffs: {
    large: '1600px',
    medium: '1150px',
    small: '900px',
    xSmall: '600px',
    xxSmall: '450px',
    secondary: '540px',
  },
  dimensions: {
    button: {
      height: '35px',
      width: '150px',
    },
    form: {
      small: '40px',
      max: '40px',
    },
    nav: {
      headerHeight: '60px',
      panelWidthClosed: '94px',
      panelWidthOpen: '220px',
    },
    radius: {
      primary: '6px',
      alt1: '15px',
      alt2: '5px',
    },
  },
  spacing: {
    xxxxs: '0.2rem',
    xxxs: '0.33333rem',
    xxs: '0.4rem',
    xs: '0.5rem',
    s: '0.66667rem',
    m: '1rem',
    l: '2rem',
    xl: '3rem',
    xxl: '4rem',
  },
  font: {
    size: {
      xxxs: '0.5rem',
      xxs: '0.65rem',
      xs: '0.7777rem',
      s: '0.8333rem',
      m: '1rem',
      l: '1.3rem',
    },
    weight: {
      s: '400',
      m: '500',
      l: '600',
      xl: '700',
      xxl: '900',
    },
  },
};

export const ASSETS = assets;
