import React from 'react';
import Icon from 'components/icon';
import ChatApps from '../miniApps/chatApps';
import { useAppSelector } from '../../store/hooks';
import './style.scss';

function Chat(props: any) {
  const [showChat, setShowChat] = React.useState(false);
  const [isPrivate, setIsPrivate] = React.useState<boolean>(false);
  const [chatPosition, setChatPosition] = React.useState('right');
  const [maxWidth, setMaxWidth] = React.useState(0);
  const [leftSpace, setLeftSpace] = React.useState(0);
  const chatType = isPrivate ? 'chat_group' : 'chat';
  const myProfile = useAppSelector((state) => state.arweave.profile);

  const handleChatToggle = () => {
    setShowChat(!showChat);
  };

  const handleChatModeToggle = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setIsPrivate(!isPrivate);
  };

  React.useEffect(() => {
    const handleResize = () => {
      /*
      const content = document.querySelector('#Container');
      console.log('content: ', content)
      const contentWidth = content?.clientWidth || 0;
      const space = (window.innerWidth - contentWidth) / 2;

      if (space > 200) {
        setMaxWidth(space - 10);
        setChatPosition('right');
      } else {
        const width = contentWidth / 6 - 6;
        setMaxWidth(width >= 240 ? width : 240);
        setLeftSpace(space + 2);
        setChatPosition('left');
      }
      */
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { iframeURL, iFrameRef, handleIframeLoad } = ChatApps({
    connectedProfileAddress: myProfile && myProfile.walletAddress,
    chatType: chatType,
    addTabs: `InterestingChat:Site,Global:Global`,
    portrait: true,
  });

  return (
    <div
      className={`chatWrapper${showChat ? ' chatWrapper--visible' : ''}`}
      style={{
        width: maxWidth,
        right: chatPosition === 'right' ? '10px' : 'unset',
        left: chatPosition === 'right' ? 'unset' : `${leftSpace}px`,
      }}
    >
      <div className="chatWrapper__header" onClick={handleChatToggle}>
        <span>Chat</span>
        <div
          className={`chatGroupToggle${isPrivate ? ' chatGroupToggle--right' : ''}`}
          onClick={(e) => handleChatModeToggle(e)}
        >
          <div className="chatGroupToggle__marker" />
          <Icon icon="private" />
          <Icon icon="public" />
        </div>
        <Icon icon="arrowDown" size={24} />
      </div>
      <iframe src={iframeURL} onLoad={handleIframeLoad} ref={iFrameRef} loading="lazy" />
    </div>
  );
}

export default Chat;
