export function getType(type: string): string | undefined {
  if (!type) return '';
  if (type.toLowerCase().includes('collection')) return 'collection';
  else if (type.toLowerCase().includes('document')) return 'document';
  else if (type.toLowerCase().includes('image')) return 'image';
  else if (type.toLowerCase().includes('video')) return 'video';
  else if (type.toLowerCase().includes('audio')) return 'audio';
  else if (type.toLowerCase().includes('html')) return 'html';
  else if (type.toLowerCase().includes('text')) return 'text';
  else if (type.toLowerCase().includes('spec')) return 'spec';
  else if (type.toLowerCase().includes('page')) return 'page';
  else if (type.toLowerCase().includes('post')) return 'post';
  else if (type.toLowerCase().includes('article')) return 'article';
  else if (type.toLowerCase().includes('token')) return 'token';
}

/*
application/json|11077076
|5756654
    image/png|3548584
text/html|2283151
text/plain|688409
    image/jpeg|613049
application/octet-stream|326015
application/x.arweave-manifest+json|233846
    image/webp|149975
video/mp4|104229
application/typescript|93845
application/javascript|91593
    image/gif|86882
text/json|81440
video/MP2T|69340
video/mp2t|65896
model/gltf-binary|65603
application/pdf|49828
    image/svg+xml|49028
application/gzip|40834
text/css|37770
application/vnd.tcpdump.pcap|33963
null|19530
glb|18870
application/epub+zip|12221
    encrypted(image/jpeg)|10901
text/markdown|10656
|10155
application/zip|8167
    image/jpg|6808
audio/mpeg|4073
false|4011
application/toml|3269
font/woff2|2980
application/x-bittorrent|2936
font/woff|2643
    image/vnd.microsoft.icon|1853
audio/x-flac|1663
Account-Data|1573
application/x-mpegURL|1518
font/ttf|1422
vr/glb|1190
application/vnd.apple.mpegurl|1177
application/x-tar|1147
text/xml|1116
    image/mp4|977
text/yaml|867
    image/vnd.djvu|800
video/quicktime|730
text/x-scss|679
application/xhtml+xml|677
application/rls-services+xml|659
application/dat|573
Following-List|561
application/vnd.ms-fontobject|549
application/xml|541
audio/mp3|531
audio/wav|497
encrypted(image/png)|492
text/html; charset=UTF-8|463
    image/x-icon|425
    image/tiff|421
model/vnd.usdz+zip|313
audio/mp4|300
application/x-bzip2|299
video/x-msvideo|269
Post|255
text/csv|253
27dbe0188207f0a91d741a890aa4cdcf7e9fc2e428acd26f6bed43bba2d1aff8|250
video/webm|237
font/otf|234
Encrypted|234
application/node|212
text/mdx|200
audio/x-wav|188
encrypted(application/pdf)|173
    image/x-canon-cr2|172
masterbrews/png|167
encrypted(video/mp4)|164
application/txt|157
text/less|149
    data:image/png|148
application/vnd.apple.keynote|147
    image|140
video/x-m4v|135
Comment|135
application/manifest+json|133
application/png|131
application/x-rar-compressed|125
data:image/jpeg|117
video/x-matroska|116
application/x-msdownload|114
application/vnd.openxmlformats-officedocument.wordprocessingml.document|108
text|107
application/x-zip-compressed|106
file/json|104
image/x-sony-arw|102
image/jpeg |101
application/x-authorware-bin|98
 application/json|98
file/png|89
image/heic|80
audio/ogg|80
audio/flac|80
audio/midi|71
application/x-sh|67
model/gltf+json|66
unknown|62
x-arweave/name-update|61
image/vnd.adobe.photoshop|61
application/html|60
7f60d779f59a4f32a83be3ce2065e70f50d6254a6ba43820258a8afe47da64ee|59
data:video/mp4|57
model/glb+json|51
application/x-7z-compressed|50
application/jpeg|50
application/vnd.openxmlformats-officedocument.presentationml.presentation|49
application/x-empty|48
image/apng|47
text/javascript|46
application/rtf|46
text/plain; charset=utf-8|43
encrypted(image/gif)|41
video/x-flv|39
text/xml; charset=utf-8|39
application/x-x509-ca-cert|39
application/wasm|38
application/postscript|37
text/cache-manifest|35
application/glb|35
audio|34
text/vtt|32
file/bin|32
text/x-c|30
application/vnd.openxmlformats-officedocument.spreadsheetml.sheet|30
image/.jpeg|29
application/vrm|29
image/jpeg  |28
application/x-xz|28
application/gltf|28
image/bmp|26
application/json; charset=utf-8|26
application/jpg|25
application/vnd.rar|24
text/stylus|23
application/elixir|23
image/x-panasonic-rw2|22
encrypted(text/plain)|22
application/bson|22
video/mpeg|21
image/jpg
|21
application/x-msdos-program|21
application/pgp-signature|21
0.png|21
application/vnd.docker.distribution.manifest.v2+json|20
video/mp4
|19
application/x-httpd-php|19
application/oebps-package+xml|19
video/mov|18
application/vnd.unity|18
video/3gpp|17
model/obj|17
audio/wave|17
application/text|17
txt|16
img|16
image/svg|16
cover|16
application/x-gzip|16
application/msword|16
text/jsx|15
images/math-doge|15
fc7ddac4154119a23be273c7c56cddbae3879e0a48418a160234c3c67a4872e0|15
application/vnd.ms-excel|15
application/x-apple-diskimage|14
text/rtf|13
json|13
data:image/gif|13
data:audio/mpeg|13
application/vnd.oasis.opendocument.text|13
wasm|12
png|12
model/glb|12
application/x-tgif|12
application/x-javascript|12
encrypted(video/quicktime)|11
encrypted(image/vnd.microsoft.icon)|11
application/x-cfb|11
encrypted(audio/mpeg)|10
 video/mp4|10
application/x-font-ttf|9
application/vnd.ms-pki.stl|9
ItemLore|9
test|8
img/png|8
image/png;base64|8
image/jpeg;base64|8
image/*|8
encrypted(application/x-subrip)|8
appllication/pdf|8
application/x-sql|8
application/x-krita|8
encrypted(application/zip)|7
audio/x-m4a|7
audio/x-aiff|7
application/vnd.android.package-archive|7
application/JPEG|7
81081ef4e2cefee9b0280a0b5d81660783e6dc8b512c6fc34c540c221ec10180|7
694dd4ff868ec10df735167e4410def68adb640cb3884cc1d572f7d767885062|7
    image/x-png|7
video/x-ms-wmv|6
video/ogg|6
text/result|6
multipart/form-data|6
f3e52de980070323faf85aeea7b12e5e467e607daf8976ddf8670b66e30f0425|6
encrypted(video/x-matroska)|6
documents|6
]Account-Data|6
0|6
image/json|5
encrypted(img/png)|5
application/vnd.ms-excel.sheet.macroenabled.12|5
application/bin|5
[object Object]|5
WP Post|5
Question|5
    image/png|5
video/*|4
utf-8|4
json/application|4
encrypted(image/svg+xml)|4
dbd4afe852443b97a295bebf7af6f6d4cf098d12c2f096b502da75d221b0fa3b|4
application/x.arweave-content-policy|4
application/x-mobipocket-ebook|4
application/x-iso9660-image|4
application/vnd.ms-xpsdocument|4
application/schema+json; charset=utf-8|4
application/java-vm|4
application/java-archive|4
PERSISTENCE|4
Answer|4
video/avi|3
text/html; charset=utf-8|3
text/html; charset=iso-8859-1|3
model/x3d+xml|3
html|3
file/mp3|3
encrypted(video/x-msvideo)|3
encrypted(image/bmp)|3
encrypted(audio/ogg)|3
data:image/png;base64|3
data:image/png;|3
binary|3
audio/opus|3
audio/basic|3
application/x-web-app-manifest+json|3
application/x-subrip|3
application/x-shockwave-flash|3
application/x-photoshop|3
application/x-debian-package|3
application/x-chess-pgn|3
application/vnd.lotus-1-2-3|3
application/vnd.adobe.photoshop|3
application/mp3|3
application/image/png|3
application/image|3
application/font-sfnt|3
application/dash+xml|3
application/00|3
Thought|3
0e81d08d9bf3c807bf74623a30e9c81776c6be2fdc61d06acceaedda7c51ab0d|3
zip|2
undefined|2
text/calendar|2
plan/text|2
multipart/related|2
model/mtl|2
metadata/json|2
message/rfc822|2
image/x-xcf|2
image/tif|2
image/ppng|2
image/jpeg; charset=binary|2
image/jp2|2
f4e98344541784f2eabcf6fcd1daf050afd9a1bfa2c59819356fe0543752f311|2
encrypted(video/webm)|2
encrypted(video/ogg)|2
encrypted(image/webp)|2
encrypted(audio/wave)|2
encrypted(application/epub+zip)|2
data/run-record|2
data,image/png;base64|2
data,image/gif;base64|2
d44547dc2adeeed4589e0630e8284a2a75b004d0f4ef78588c70fc5fe101b947|2
cc1d63551e24d8b4c77f3cd873dda02c2b463adc8871a855562b25a5f27fb7a5|2
audio/x-ms-wma|2
audio/vnd.wave|2
audio/*|2
application/zip+wacz|2
application/x-ms-shortcut|2
application/x-lzh-compressed|2
application/x-font-woff|2
application/x-compress|2
application/www|2
application/vnd.microsoft.portable-executable|2
application/vnd.docker.distribution.manifest.list.v2+json|2
application/vnd.curl.car|2
application/undefined|2
application/net:443/W7ywyZSIBSSqrzrIU48Q-yKQC84QKWsvpsdVVWpoVQc|2
application/mp4|2
application/ld+json|2
application/datamessenger|2
application/.png|2
application/.json|2
JSON|2
9b781e07c701fcbae7a04aa35dd1a0cf2dac6aa51143e816303e9626d90396d1|2
3f5938b40e8bf38f4ec3aed639ff3339d1667495f405e51b0e7b94464e59cb8d|2
31a2957b057c0bd3d9332fa619efc9bfbc0d1640dae75fd63dcb91133fa632a4|2
30c4b934e785e136510178dd906ac6307daad5326c12c942876c2a1af81c8282|2
1710b6df76400e4b25dd1af243011f8bf6f416bac28d1d6e66a5c632a37a460d|2
{"iv":"fc989735c9cb93ad4a4d18533b638424","encryptedText":"269616d69243c70d75c83cd4efb08249"}|1
{"iv":"f7b2dbcf2136770a756d1f15307708eb","encryptedText":"d378f0695c68766a7e2c4e00f581eb30d34905d7ab1b744900c193bba050dfbb"}|1
{"iv":"ec948a649ffeb5e7eec38c17b85c93c6","encryptedText":"1e22dc40157fc3ec7d4594b519eae3a2"}|1
{"iv":"dbef07f120de3c2bef1b0e5089751208","encryptedText":"50b046e1fc1c071437f1061bde17eabb28a7167d6fd961aef30f74557145be0a06be17beff275e597fc144fa18301bf188391da163e5f2c88ddbf59fae147305b58fbfd0d42679cf6b890e86eb846a70"}|1
{"iv":"d1e23dcec5ae454aafbab994ec4f9b85","encryptedText":"8a07cccd1c8b18c098a90aa40b15f51a4ae291a5331fc887389c58d2585cdec8cbf83298925128b10e40f9271bd000d923d1b440fa76ac7055b02342e3ffd7d23148ac83082234ad8c0c5d28399f0c97"}|1
{"iv":"a56bcdd0a9788c760cd7f971c47dd6dc","encryptedText":"9557ce4da743294c035380d9bc6fcb25"}|1
{"iv":"8e98d4ae2b4cce449039960136ed9f0e","encryptedText":"46e7ab52bc2e74b837a530d84f8adedd6087bb2bd68793d4daf60fd49ed8a31ce44f0462fc933fe895ba39b80db20fd3ec9d043ec365a5589e882ee6c4d7dd70b4548842dfaa664737f20a1c3d68a27d"}|1
{"iv":"831cee5fe91eec5e62b4264dccd4dfc4","encryptedText":"442e971a015f5b8a80f3369dc6f1a8a8"}|1
{"iv":"3a7bd53fc17d68a99a767269cf0ce3dc","encryptedText":"88ce38c0fb1347b68812234db29556f2"}|1
{"iv":"2179ed7ba9c13be744b2d62639def5fa","encryptedText":"daec2f29ed5511977e4238a2e0270e00"}|1
{"iv":"0b6e944afebd416ba200a51ae1f5ad8e","encryptedText":"b6216ca44d7458cd76d902b4b892b73a"}|1
video/mp4
Set-Cookie: foo=bar|1
video/iso.segment|1
video/MP2P|1
video|1
true|1
text/x-python-script|1
text/x-java-source|1
text/tab-separated-values|1
text/pain|1
text/coffeescript|1
text/JSON|1
tex/html|1
test-value|1
plain/text|1
my-super-type|1
multipart/form-data; boundary=--------------------------666261727119601603016345|1
multipart/form-data; boundary=--------------------------474109930061069346445990|1
movie/mp4|1
model/stl|1
model/jpg|1
me.jpeg|1
jpg|1
jpeg|1
inode/x-empty|1
inode/dir|1
image/x-adobe-dng|1
image/svg+xml,<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http|1
image/png,<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http|1
image/png   |1
image/NEF|1
image/JPG|1
image/.gif|1
idontsetpropercontenttypes|1
gzip|1
gltf|1
foo|1
f502c2336e9b72aafa22a66d04e9f2907e935651ee38625de47e3a100bca51d4|1
encrypted(video/x-ms-wmv)|1
encrypted(video/mpeg)|1
encrypted(text/html)|1
encrypted(image/tiff)|1
encrypted(image/heic)|1
encrypted(audio/x-flac)|1
encrypted(audio/x-aiff)|1
encrypted(audio/x-aac)|1
encrypted(audio/mp4)|1
encrypted(application/x-msdos-program)|1
e78173c973ee4e7efbd25537f702dd0e5ef5b7389de061df3cb3cf50f1edd7ed|1
e5df8991e112ce188d3416bcf66b7a1d4de9cd91127e255a60fa03bca9033886|1
document/pdf|1
directory|1
data:image/jpeg;|1
data:image/gif;|1
d6ae9dca399fcbe9402f8d22d56a83084f918c6861b8486b5a5bef77900e4bc5|1
crunchy/roll|1
bananas|1
audio/vnd.dolby.dd-raw|1
audio/aac|1
application/xslt+xml|1
application/x-tcl|1
application/x-pkcs12|1
application/x-perl|1
application/x-iwork-keynote-sffkey|1
application/x-font-otf|1
application/wacz+zip|1
application/vnd.oasis.opendocument.spreadsheet|1
application/vnd.ms-powerpoint|1
application/vnd.ms-htmlhelp|1
application/vnd.lotus-organizer|1
application/vnd.geo+json|1
application/test|1
application/ogg|1
application/octet-stream=blender.desktop|1
application/octet-stream; charset=utf-8|1
application/net:443/an_ROEwOlLUHYMJhRAb0JKC49-Ei8GXge4OxaKkSSbY|1
application/mbox|1
application/json;utf-8|1
application/javascript
Set-Cookie: foo=bar|1
application/image/jpeg|1
application/geo+json|1
application/epub+zip;base64|1
application/bdoc|1
application/bacon|1
application/32|1
application/1|1
application/06|1
af6427d9bb476fee2e1e4a044894d6cedd561a8db43b731a462f3f239f3fd05b|1
[object File]|1
None|1
NFTPriceData/json|1
Alert-Download-PNG.png|1
9c208fe113ce9ed93a69d5f474fe5653fb0b4a2ef7c333fc574d3d0def257872|1
9140ed5444840b646bd52deb86aa5e3c4942ede9e12512d979eb0cc626ad0d0a|1
87983393abe8371d4789937c50c74046a758bf52a3756434821e8c43c48003df|1
7f8651cb3b5c0a1ce1a553feb49e3260a4d49ce7803ff4bb1f1c9245a6d6fbf9|1
670dafd1cbbd9b2dcbdc135ebeaab7b5846a9ce0946635624382337d2d690ef6|1
59bfe9e08828603ca52ac5c26079899f51cacb7e54a788185388c65db74c1cbe|1
47135c426114832fec3aad29b5d874094b71762a00cec65942a14ab36c68288d|1
446b429216e01bdcd5fe8a7dbbdc19827857c6fdacfae2e667d72c23a4c30263|1
3f115d7cfc7e5a8d8764c7569df9e1b499f51c78d95cf672558b95f55571a7bd|1
31108d954e6a722414c77af768183c63a3712c02a8e8cd3b53d5e2447a02acf3|1
.mp4|1
.mp3|1
 data|1
*/

/*
MP4: video/mp4
WebM: video/webm
Ogg Theora: video/ogg or video/ogv
3GPP: video/3gpp or video/3gpp2
QuickTime: video/quicktime
AVI: video/x-msvideo
Matroska: video/x-matroska
WMV: video/x-ms-wmv
FLV: video/x-flv
MKV: video/x-matroska
MPEG: video/mpeg
MPEG-2: video/mpeg2
MPEG-4: video/mp4
H.264: video/h264
H.265: video/h265
Theora: video/theora
DV: video/x-dv
XviD: video/x-xvid
VP8: video/vp8
VP9: video/vp9
HEVC: video/hevc
H.263: video/h263
*/

/*
MP3: audio/mpeg
AAC: audio/aac
OGG: audio/ogg
WAV: audio/wav or audio/wave
WebM Audio: audio/webm
FLAC: audio/flac
MIDI: audio/midi or audio/x-midi
AMR: audio/amr
WMA: audio/x-ms-wma
AU: audio/basic
AIFF: audio/x-aiff or audio/aiff
MP4 Audio: audio/mp4
Opus: audio/opus
3GPP Audio: audio/3gpp or audio/3gpp2
MPEG Audio Playlist: audio/mpegurl or audio/x-mpegurl
XM: audio/xm
IT: audio/it
S3M: audio/s3m
MOD: audio/mod
Midnight Commander: audio/x-mod
*/
