import React from 'react';
import { getDataUrlContentType } from 'permaweb-sdk/dist/helpers/utils';

import './style.scss';

export interface IProps {
  files: any[];
  setFiles: (files: any[]) => void;
}

function getFile(dataUrl: string) {
  const contentType = getDataUrlContentType(dataUrl);
  if (contentType.includes('image')) {
    return <img src={dataUrl} alt={'Image upload'} />;
  }
  if (contentType.includes('video')) {
    return <video src={dataUrl} controls />;
  }
  return null;
}

export default function Files(props: IProps) {
  const { files, setFiles } = props;

  const handleRemoveFile = (indexToRemove: number) => {
    const updatedFiles = files.filter((_, index) => index !== indexToRemove);
    setFiles(updatedFiles);
  };

  return files && files.length > 0 ? (
    <div className="files">
      {files.map((dataUrl: string, index: number) => {
        return (
          <div key={index} className="files__item" title="Remove item" onClick={() => handleRemoveFile(index)}>
            {getFile(dataUrl)}
            <div className="files__itemActions" />
          </div>
        );
      })}
    </div>
  ) : null;
}
