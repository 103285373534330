import classnames from 'classnames';
import { FullProfileType } from 'permaweb-sdk/dist/helpers/types';
import { useFormContext } from 'react-hook-form';

interface Props {
  state: keyof FullProfileType;
}

export default function Error(props: Props) {
  const formHook = useFormContext<FullProfileType>();
  return <div className={classnames('profileEdit__error')}>{formHook.formState.errors[props.state]?.message}</div>;
}
