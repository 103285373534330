import './style.scss';

function VideoRenderer(props: any) {
  const { asset } = props;

  return (
    <div className="renderer--video">
      <video controls>
        <source src={`https://arweave.net/${asset.id}`} type="video/mp4" />
      </video>
    </div>
  );
}

export default VideoRenderer;
