import { useWatch } from 'react-hook-form';

export default function Preview() {
  const handleName = useWatch({ name: 'handleName' });
  const displayName = useWatch({ name: 'displayName' });
  const bio = useWatch({ name: 'bio' });

  return (
    <div className="profileEdit__preview">
      <label>Handle</label>
      <span>{handleName}</span>

      <label>Display Name</label>
      <span>{displayName || '-'}</span>

      <label>Bio</label>
      <span>{bio || '-'}</span>
    </div>
  );
}
