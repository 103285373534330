import styled, { keyframes } from 'styled-components';
import { STYLING } from 'helpers/config';

const blink = keyframes`
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
`;

export const WalletBalance = styled.div<{ updating: boolean }>`
  padding: ${STYLING.spacing.s};
  animation: ${(props) => (props.updating ? `${blink} 0.5s infinite` : 'none')};
`;

export const WalletBalanceEntries = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${STYLING.spacing.s};
`;

export const WalletBalanceEntry = styled.div``;

export const WalletBalanceEntryLabel = styled.div``;

export const WalletBalanceEntryValue = styled.div`
  opacity: 0.8;
  font-size: smaller;
`;
