import React, { useEffect, useMemo } from 'react';
import { structureAsset } from 'permaweb-sdk/dist/gql';
import { doFetchTotalCommentsPerAsset } from 'permaweb-sdk/dist/implementations/rtk';

import { resolveTxTimestampMs } from 'components/contentList/listEntry/helpers';
import { findTag } from 'helpers/tags';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectStampersForContentIds } from 'store/stamps/selectors';

import ListEntry from './listEntry';
import * as S from './styles';

function ContentList(props: any) {
  const { list, action } = props;
  const dispatch = useAppDispatch();
  const idList = useMemo(() => list.map((l: IEdge) => l.node.id), [list]);
  const { total, vouched } = useAppSelector((state) => selectStampersForContentIds(state, idList));

  const handleScroll = () => {
    if (
      document.documentElement.scrollTop + window.innerHeight >=
      document.documentElement.scrollHeight - window.innerHeight
    ) {
      action();
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (list.length > 0 && vouched) {
      const assetOwnerAndStampers: TxId[] = list.flatMap((entry: any) => [
        entry.node.owner.address,
        ...(vouched.length ? vouched : []),
      ]);

      dispatch(doFetchTotalCommentsPerAsset(list.map((entry: any) => entry.node.id)));
    }
  }, [list, vouched]);

  return (
    <S.List>
      {list &&
        // -- TMP: convert to AssetType. Eventually, the fetch will be replaced with SDK anyway --
        list.map((entry: any, i: any) => {
          const asset = structureAsset(entry, null);

          if (asset.creator.walletAddress) {
            // `structureAsset` uses Initial-Owner to fill this. Some TXes
            // don't have that, so fill with TX owner.
            asset.creator.walletAddress = entry.node.owner.address;
          }

          const timestampTs = resolveTxTimestampMs(entry.node);
          const bannerTag = findTag(entry.node.tags, 'Banner');

          return (
            <React.Fragment key={i}>
              <ListEntry asset={asset} timestampMs={timestampTs} bannerTag={bannerTag} />
            </React.Fragment>
          );
        })}
    </S.List>
  );
}

export default ContentList;
