import React from 'react';
import { ReactSVG } from 'react-svg';

// import { getFullProfile, getGQLData } from 'permaweb-sdk/dist/gql';
import { WalletEnum } from 'permaweb-sdk/dist/implementations/rtk';
import { doWalletConnect, doWalletDisconnect } from 'permaweb-sdk/dist/implementations/rtk/store/arweave/thunks';

// import { Loader } from 'components/atoms/Loader';
import { ASSETS } from 'helpers/config';
import { useLanguageProvider } from 'providers/LanguageProvider';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import uiSlice from 'store/ui/slice';

import * as S from './styles';

const AR_WALLETS = [
  { type: WalletEnum.othent, logo: ASSETS.wallets.othent },
  { type: WalletEnum.arConnect, logo: ASSETS.wallets.arconnect },
  // { type: WalletEnum.arweaveApp, logo: ASSETS.wallets.arweaveApp },
];

export default function WalletList(props: any) {
  const dispatch = useAppDispatch();
  const languageProvider = useLanguageProvider();
  const [advisory] = React.useState(getOthentAdvisory());
  const [showPopup, setShowPopup] = React.useState(false);
  const language = languageProvider.object[languageProvider.current];
  const connectedProfile = useAppSelector((state) => state.arweave.profile);

  const handleConnect = (type: WalletEnum) => {
    setShowPopup(true);
    dispatch(doWalletDisconnect());
    Promise.resolve()
      .then(() => dispatch(doWalletConnect(type)))
      .catch((err: any) => {
        console.log('err: ', err);
        setShowPopup(false);
      });
  };

  React.useEffect(() => {
    if (connectedProfile) {
      if (connectedProfile.handle && connectedProfile.displayName && connectedProfile.avatar)
        dispatch(uiSlice.actions.setWalletModalVisible(false));
      else {
        console.log('handleOnboarding');
        props.handleOnboarding(true);
      }
    }
  }, [connectedProfile]);

  return (
    <S.WalletListContainer>
      {showPopup && <S.LoadingWrapper>{/* <Loader relative /> */}</S.LoadingWrapper>}
      {language.LoginSignup}
      <S.WalletListItems>
        {AR_WALLETS.map((wallet: any, index: number) => (
          <S.WalletListItem key={index} onClick={() => handleConnect(wallet.type)}>
            <img src={`${wallet.logo}`} alt={''} />
            <span className="flip">{wallet.type.charAt(0).toUpperCase() + wallet.type.slice(1)}</span>
          </S.WalletListItem>
        ))}
      </S.WalletListItems>
      {advisory && (
        <S.WalletListCrossSiteCookie className="flip">
          <ReactSVG src={ASSETS[advisory.icon]} />
          <p>
            <b className="flip">{advisory.title}</b>
            <span className="flip">{advisory.message}</span>
          </p>
        </S.WalletListCrossSiteCookie>
      )}
    </S.WalletListContainer>
  );
}

function getOthentAdvisory() {
  const isSafari = () => {
    return (
      /Safari/.test(navigator.userAgent) &&
      /Apple Computer/.test(navigator.vendor) &&
      !/CriOS/.test(navigator.userAgent) &&
      !/FxiOS/.test(navigator.userAgent)
    );
  };

  // @ts-ignore
  if (Boolean(navigator.brave)) {
    return {
      icon: 'brave',
      title: 'Brave Users',
      message: 'Allow cross-site cookies to connect to Othent.',
    };
  } else if (isSafari()) {
    return {
      icon: 'safari',
      title: 'Safari Users',
      message: 'Disable Cross-Site Tracking prevention to connect to Othent.',
    };
  }

  return null;
}
