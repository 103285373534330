import { ComponentType } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MakeState } from 'store/utils';

export interface ModalState {
  modalList: ComponentType<any>[];
  modalPropsList: any[];
}

// ****************************************************************************
// Actions
// ****************************************************************************

// Note: these wrappers exists only for the sake of Generics
// (to automatically infer the prop types based on given component).
// https://stackoverflow.com/questions/70930616/using-generic-parameter-in-redux-toolkit-createslice-reducer-with-prepare

export function openModal<T>(component: ComponentType<T>, props: T) {
  return modalSlice.actions.openModal({ component, props });
}

export function closeModal() {
  return modalSlice.actions.closeModal();
}

// ****************************************************************************
// modalSlice
// ****************************************************************************

const modalSlice = createSlice({
  name: 'modal',
  initialState: MakeState<ModalState>({
    modalList: [],
    modalPropsList: [],
  }),
  reducers: {
    openModal: (state: ModalState, action: PayloadAction<{ component: ComponentType<any>; props: any }>) => {
      state.modalList.push(action.payload.component);
      state.modalPropsList.push(action.payload.props);
    },
    closeModal: (state: ModalState) => {
      state.modalList.pop();
      state.modalPropsList.pop();
    },
  },
});

export default modalSlice;
