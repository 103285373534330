import { ArweaveWebIrys } from '@irys/sdk/build/esm/web/tokens/arweave';
import Ar from 'helpers/arweave/ar';
import { getUnknownError, throwIf } from 'helpers/error';
import uploadSlice from 'store/upload/slice';
import { fileToBuffer } from './helpers';

/**
 * Selects and pre-processes the file for upload
 * (e.g. calculating cost and whatnot, stored in `state.results[id]`).
 *
 * @param params
 */
export function doSelectFileForUpload(params: { id: string; file: File }) {
  const { id: requestId, file } = params;
  return async (dispatch: AppDispatch, getState: GetState): Promise<void> => {
    throwIf(!window.arweaveWallet, 'Wallet not connected');

    const state = getState();
    const bundleGateway = state.arweave.bundlerGateway;
    const uploadConfig = { url: `https://${bundleGateway}`, wallet: { provider: window.arweaveWallet } };

    try {
      const bundlr = new ArweaveWebIrys(uploadConfig);
      await bundlr.ready();
      const priceAtomic = await bundlr.getPrice(file.size);
      const priceConverted = bundlr.utils.fromAtomic(priceAtomic);
      dispatch(uploadSlice.actions.setFile({ id: requestId, file: file, cost: priceConverted.toString() }));
    } catch (e: any) {
      console.error(e);
      dispatch(uploadSlice.actions.setFile({ id: requestId, file: file, warning: getUnknownError(e) }));
    }
  };
}

/**
 * Starts the Arweave file-upload request.
 * Small files are dispatched directly, while larger ones go through the bundler.
 * @param requestId
 * @param additionalTags
 */
export function doUploadFile(requestId: string, additionalTags: ITag[] = []) {
  return async (dispatch: AppDispatch, getState: GetState): Promise<TxId | undefined> => {
    try {
      const state = getState();
      const file = state.upload.requests[requestId].file!;

      throwIf(!file, 'No file supplied');
      throwIf(!state.arweave.walletAddress || !window.arweaveWallet, 'Wallet not connected');
      throwIf(!state.upload.requests[requestId].file, 'No File Supplied');

      dispatch(uploadSlice.actions.uploadStarted({ id: requestId }));

      const fileBuffer = await fileToBuffer(file);
      const tags = [{ name: 'Content-Type', value: file.type }, ...additionalTags];

      if (file.size < 100 * 1024) {
        // --- dispatch file ---
        const arweave = Ar.getInstance('standard');
        const tx = await arweave.createTransaction({ data: fileBuffer as any });
        tags.forEach((tag) => tx.addTag(tag.name, tag.value));
        const txid = await Ar.dispatchTx(tx, arweave);

        dispatch(uploadSlice.actions.uploadDone({ id: requestId, txId: txid }));
        return txid;
      } else {
        // --- bundle file ---
        const bundleGateway = state.arweave.bundlerGateway;
        const uploadConfig = { url: `https://${bundleGateway}`, wallet: { provider: window.arweaveWallet } };

        const bundlr = new ArweaveWebIrys(uploadConfig);
        await bundlr.ready();

        const uploader = bundlr.uploader.chunkedUploader;
        uploader.setBatchSize(1);
        uploader.setChunkSize(500000);

        uploader.on('chunkUpload', (chunkInfo) => {
          const progress = Math.floor((chunkInfo.totalUploaded / file.size) * 100);
          dispatch(uploadSlice.actions.uploadProgress({ id: requestId, progress: progress }));
        });

        uploader.on('chunkError', (e) => {
          console.error('UPLOADER ERROR', e); // should it dispatch uploadFail, or already awaited, or duplicate?
        });

        const txResponse = await uploader.uploadData(fileBuffer as any, { tags } as any);
        dispatch(uploadSlice.actions.uploadDone({ id: requestId, txId: txResponse.data.id }));
        return txResponse.data.id;
      }
    } catch (err: any) {
      console.error(err);
      dispatch(uploadSlice.actions.uploadFail({ id: requestId, err }));
      throw err;
    }
  };
}

// ****************************************************************************
// resizeImage
// ****************************************************************************

// type TxId = string;
// type CCOptions = ClientCompressOptions;
// type CCResult = ClientCompressResult;
//
// const Default: CCOptions = {
//   targetSize: 0.1, // 100 KB
//   quality: 1.0,
//   minQuality: 0.1,
//   qualityStepSize: 0.05,
//   throwIfSizeNotReached: true,
// };
//
// /**
//  *
//  * @param imageFile
//  * @param options
//  */
// export function resizeImage(imageFile: File, options: CCOptions = Default) {
//   return async (dispatch: AppDispatch, getState: GetState): Promise<CCResult> => {
//     const compress = new Compress(options);
//     return compress.compress([imageFile]).then((result: CCResult[]) => {
//       return result[0];
//     });
//   };
// }
