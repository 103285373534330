import { STYLING } from 'helpers/config';
import styled from 'styled-components';

export const CommentList = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  margin-top: ${STYLING.spacing.l};
  height: calc(100% - 120px);
  // height:100%;
  max-height: 100%;
  box-sizing: border-box;
  // flex: 1;
  mask-image: -webkit-gradient(linear, left 85%, left bottom, from(rgb(0, 0, 0)), to(rgba(0, 0, 0, 0)));
  padding-bottom: ${STYLING.spacing.m};

  h3 {
    padding-top: -100px;
    z-index: 9999;
    span {
      color: #a8a8a8;
      margin-left: ${STYLING.spacing.m};
    }
  }
`;

export const CommentListHeader = styled.div`
  display: flex;
  font-size: xx-large;
  font-weight: bold;
  gap: ${STYLING.spacing.s};
`;

export const CommentListContainer = styled.div`
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: ${STYLING.spacing.m};

  &:after {
    content: '';
    display: block;
    height: ${STYLING.spacing.m};
  }

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--color-card-text);
  }

  &::-webkit-scrollbar-corner {
    background: unset;
  }
`;

export const LoadMore = styled.button`
  margin-top: ${STYLING.spacing.l};
  cursor: pointer;

  background: var(--color-card-button-text);
  border-radius: var(--border-radius);
  border: 1px solid var(--color-card-text);
  padding: ${STYLING.spacing.xxs} ${STYLING.spacing.l};
  color: var(--color-card-text);
  font-size: small;
  font-weight: bold;
  text-transform: uppercase;
`;
