import arconnect from 'assets/img/icons/ar-logo.svg';
import arweaveApp from 'assets/img/icons/ar-logo.svg';
import arrowUp from 'assets/img/icons/arrowUp.svg';
import arrowLeft from 'assets/img/icons/arrowLeft.svg';
import arrowRight from 'assets/img/icons/arrowRight.svg';
// import article from 'assets/img/icons/article.svg';
import bazar from 'assets/img/icons/bazar.svg';
import brave from 'assets/img/icons/brave.svg';
import bright from 'assets/img/icons/bright.svg';
import dark from 'assets/img/icons/dark.svg';
import home from 'assets/img/icons/home.svg';
import filter from 'assets/img/icons/filter.svg';
import othent from 'assets/img/icons/othent.svg';
import safari from 'assets/img/icons/safari.svg';
import upload from 'assets/img/icons/upload.svg';
import user from 'assets/img/icons/user.svg';
import wave from 'assets/img/wave.svg';

interface Assets {
  [key: string]: any;
}

export const assets: Assets = {
  arrowUp: arrowUp,
  arrowLeft: arrowLeft,
  arrowRight: arrowRight,
  // article: article,
  bazar: bazar,
  brave: brave,
  bright: bright,
  dark: dark,
  home: home,
  filter: filter,
  upload: upload,
  user: user,
  safari: safari,
  wave: wave,
  wallets: {
    arconnect: arconnect,
    arweaveApp: arweaveApp,
    othent: othent,
  },
};
