import React from 'react';

// import { IconButton } from 'components/atoms/IconButton';
import { Portal } from 'components/wrappers/Portal';
import { ASSETS, DOM } from 'helpers/config';

import * as S from './styles';
import { IProps } from './types';

export default function Modal(props: IProps) {
  const escFunction = React.useCallback(
    (e: any) => {
      if (e.key === 'Escape' && props.handleClose) {
        props.handleClose();
      }
    },
    [props]
  );

  const handleOutsideClick = (e: any) => {
    if (!props.header) {
      if (e.currentTarget === e.target) {
        props.handleClose();
      }
    }
  };

  React.useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  function getBody() {
    return (
      <>
        <S.Container noHeader={!props.header}>
          {props.header && (
            <S.Header>
              <S.LT>
                <S.Title>{props.header}</S.Title>
              </S.LT>
              {props.handleClose && (
                <S.Close>
                  <button type="button" onClick={() => props.handleClose()} />
                </S.Close>
              )}
            </S.Header>
          )}
          <S.Body useVisibleOverflow={props.useVisibleOverflow || false}>{props.children}</S.Body>
        </S.Container>
      </>
    );
  }

  return (
    <Portal node={DOM.overlay}>
      <S.Wrapper
        noHeader={!props.header}
        top={window ? (window as any).pageYOffset : 0}
        onClick={handleOutsideClick}
        className="flip"
      >
        {getBody()}
      </S.Wrapper>
    </Portal>
  );
}

let modalOpenCounter = 0;

const showDocumentBody = () => {
  modalOpenCounter -= 1;
  if (modalOpenCounter === 0) {
    document.body.style.overflow = 'auto';
  }
};

const hideDocumentBody = () => {
  modalOpenCounter += 1;
  document.body.style.overflow = 'hidden';
};
