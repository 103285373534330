import styled, { createGlobalStyle } from 'styled-components';
import { STYLING } from 'helpers/config';

export const GlobalStyle = createGlobalStyle`

  html {
    width: 100%;
    height: 100%;
  }

  body {
    padding: 0;
    margin: 0;
    width: 100%;
    min-height: 100%;
    font-family: 'Poppins';
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: var(--color-background);
    &::-webkit-scrollbar {
      width: 16px;
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      background: var(--color-background);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-text);
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb:hover {
      cursor: pointer;
    }

    & * {
      scrollbar-width: thin;
      scrollbar-color: var(--color-background);
    }

    & *::-webkit-scrollbar-thumb {
      background-color: var(--color-text);
    }

    & *::-webkit-scrollbar-thumb:hover {
      cursor: pointer;
    }
  }

  #root {
    width: 100%;
    height: 100%;
  }
`;
