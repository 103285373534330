import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import AssetHeader from './assetHeader';
import MetaRenderer from './metaRenderer';
import AssetMeta from './assetMeta';
import VideoRenderer from './video';
import AudioRenderer from './audio';
import ImageRenderer from './image';
import ArticleRenderer from './article';
import DocumentRenderer from './document';
import CollectionRenderer from './collection';
import PageRenderer from './page';
import PostRenderer from './post';
import { getType } from './helpers';
import { naToUndefined } from 'components/contentList/listEntry/helpers';
import { getAssetById } from 'permaweb-sdk/dist/gql';
import './style.scss';

function Renderer(props: any) {
  const location = useLocation();
  const { id } = useParams();
  const { asset, type, address, expanded } = props;
  const [data, setData] = React.useState<any>(null);
  const [file, setFile] = React.useState<any>(null);
  const txid = id || address;
  const [renderer, setRenderer] = React.useState(type);

  const fetchAsset = async (txid: string) => {
    try {
      const response = await getAssetById({ id: txid });

      if (!response) {
        throw new Error('Network response was not ok');
      }

      if (response.type.includes('audio') || response.type.includes('video') || response.type.includes('image')) {
        const type = getType(response.type);
        setRenderer(type);
        let fileTxId = null;

        if (response.contentType.includes('json')) {
          const meta = await fetch('https://arweave.net/' + txid);
          const file = JSON.parse(await new Response(meta.body).text());
          fileTxId = file.fileTxId;
        }
        return {
          id: txid,
          type: type,
          contentType: response.contentType,
          title: response.title,
          description: response.description,
          dateCreated: response.dateCreated,
          url: `https://arweave.net/${fileTxId || txid}`,
          creator: response.creator,
          license: response.license,
          topics: response.topics,
          renderWith: response.renderWith,
        };
      } else if (response.dataProtocol === 'Collection') {
        const meta = await fetch('https://arweave.net/' + txid);
        const file = JSON.parse(await new Response(meta.body).text());
        setFile(file);
        setRenderer('collection');
        return response;
      } else if (response.type.includes('web-page')) {
        setRenderer('page');
        return response;
      } else if (response.type.includes('article')) {
        const meta = await fetch('https://arweave.net/' + txid);
        const file = JSON.parse(await new Response(meta.body).text());
        setFile(file);
        setRenderer('article');
        return response;
      } else if (response.type.includes('post')) {
        setRenderer('post');
        return response;
      } else {
        setRenderer('default');
        console.log('Unhandled Type! ID: ', id);
        return response;
      }
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  };

  const fetchFile = async (txid: string) => {
    try {
      const meta = await fetch('https://arweave.net/' + txid);
      const file = JSON.parse(await new Response(meta.body).text());
      return file;
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  React.useEffect(() => {
    if (asset) {
      if (asset.type.includes('audio') || asset.type.includes('video') || asset.type.includes('image')) {
        const type = getType(asset.type);
        if (asset.contentType.includes('json')) {
          const fetchData = async () => {
            try {
              const file = await fetchFile(asset.id);
              if (file) {
                setFile(file);
                setRenderer(type);
              }
            } catch (err) {}
          };
          fetchData();
        } else {
          setRenderer(type);
        }
      } else if (asset.dataProtocol === 'Collection') {
        const fetchData = async () => {
          try {
            const file = await fetchFile(asset.id);
            setFile(file);
            setRenderer('collection');
          } catch (err) {}
        };
        fetchData();
      } else if (asset.type.includes('page') || asset.type.includes('html') || asset.type.includes('token')) {
        setRenderer('page');
      } else if (asset.type.includes('post')) {
        setRenderer('post');
      }
    } else {
      const fetchData = async () => {
        try {
          const data = await fetchAsset(txid);
          setData(data);
        } catch (err) {}
      };
      fetchData();
    }
  }, [asset]);

  return (
    renderer && (
      <>
        {asset ? (
          <div className="renderer">
            {(() => {
              switch (renderer) {
                case 'video':
                  return (
                    <VideoRenderer
                      asset={asset || data}
                      header={location.pathname.includes('entry')}
                      expanded={expanded}
                    />
                  );
                case 'audio':
                  return (
                    <AudioRenderer asset={asset} header={location.pathname.includes('entry')} expanded={expanded} />
                  );
                case 'image':
                  return (
                    <ImageRenderer
                      asset={asset}
                      file={file}
                      header={location.pathname.includes('entry')}
                      expanded={expanded}
                    />
                  );
                case 'article':
                  return (
                    <ArticleRenderer asset={asset} header={location.pathname.includes('entry')} expanded={expanded} />
                  );
                case 'document':
                  return (
                    <DocumentRenderer asset={asset} header={location.pathname.includes('entry')} expanded={expanded} />
                  );
                case 'collection':
                  return <CollectionRenderer asset={asset} file={file} />;
                case 'page':
                  return (
                    <PageRenderer asset={asset} header={location.pathname.includes('entry')} expanded={expanded} />
                  );
                case 'post':
                  return (
                    <PostRenderer asset={asset} header={location.pathname.includes('entry')} expanded={expanded} />
                  );
                default:
                  return (
                    <>
                      {location.pathname.includes('entry') && <AssetHeader data={data} />}
                      <>
                        {data.renderWith && data.renderWith !== 'N/A' ? (
                          <iframe src={`https://${data.renderWith}.arweave.dev/?tx=${data.id}`} loading="lazy" />
                        ) : (
                          <iframe src={`https://arweave.net/${data.id}`} loading="lazy" />
                        )}
                      </>
                      {location.pathname.includes('entry') && <AssetMeta data={data} />}
                    </>
                  );
              }
            })()}
          </div>
        ) : (
          data && (
            <>
              <AssetHeader asset={data} />
              <MetaRenderer asset={data} file={file} expanded={true} />
              <AssetMeta asset={data} />
            </>
          )
        )}
      </>
    )
  );
}

export default React.memo(Renderer);
