import React from 'react';
import Ar from 'helpers/arweave/ar';
import { useAppSelector } from 'store/hooks';
import { getType } from '../helpers';
import './style.scss';

const Tile = React.memo((props: any) => {
  const { item } = props;
  const itemType = getType(item.type);

  const handleImageClick = (e: any) => {
    const wrapperElement = e.currentTarget;
    const tileElement = wrapperElement.querySelector('.collectionGrid__tile');

    if (tileElement) {
      const rect = tileElement.getBoundingClientRect();

      if (tileElement.classList.contains('collectionGrid__tile--expanded')) {
        tileElement.style.position = '';
        tileElement.style.top = '';
        tileElement.style.left = '';
        tileElement.style.width = '';
        tileElement.style.height = '';
        tileElement.classList.toggle('collectionGrid__tile--expanded');
      } else {
        tileElement.style.position = 'fixed';
        tileElement.style.top = `${rect.top}px`;
        tileElement.style.left = `${rect.left}px`;
        tileElement.style.width = `${rect.width}px`;
        tileElement.style.height = `${rect.height}px`;
        setTimeout(() => {
          tileElement.classList.toggle('collectionGrid__tile--expanded');
        }, 400);
      }
    }
  };

  if (itemType === 'image') {
    return (
      <div key={item.id} className="collectionGrid__tileWrapper" onClick={handleImageClick}>
        <div className="collectionGrid__tile collectionGrid__tile--image">
          <div
            className="collectionGrid__tile--imageBackcground"
            style={{ backgroundImage: `url(https://arweave.net/${item.id})` }}
          />
          <img src={`https://arweave.net/${item.id}`} />
          <label>{item.title}</label>
          <div className="collectionGrid__fullscreenPreviews"></div>
        </div>
      </div>
    );
  } else if (itemType === 'video') {
    return (
      <div className="collectionGrid__tileWrapper">
        <div className="collectionGrid__tile">
          <video controls>
            <source src={`https://arweave.net/${item.id}`} />
          </video>
        </div>
      </div>
    );
  } else {
    return (
      <div className="collectionGrid__tileWrapper">
        <div className="collectionGrid__tile">{item.type} Tile</div>
      </div>
    );
  }
});

function CollectionRenderer(props: any) {
  const { asset, file } = props;
  const [items, setItems] = React.useState<Array<any>>([]);
  const gateway = useAppSelector((state) => state.arweave.standardGateway);

  function getQuery(ids: any) {
    return `query {
      transactions(ids: ${JSON.stringify(ids)}) {
        edges {
          node {
            id
            owner {
              address
            }
            data {
              size
              type
            }
            tags {
              name
              value
            }
          }          
        }        
      }
    }`;
  }

  function parseEntry(result: any) {
    const newEntry = {
      id: result.id,
      type: result.tags.find((i: any) => i.name === 'Type').value || undefined,
      title: result.tags.find((i: any) => i.name === 'Title').value,
      description: result.tags.find((i: any) => i.name === 'Description').value,
      banner: result.tags.find((i: any) => i.name === 'Banner')?.value || undefined,
      owner: {
        id: result.owner.address,
      },
    };

    return newEntry;
  }

  async function fetchData(query: any, gateway: string) {
    try {
      const result = await Ar.fetchGraphQL(query, gateway);
      return result;
    } catch (err) {
      console.error('Error fetching data:', err);
      return;
    }
  }

  React.useEffect(() => {
    setItems([]);
    const fetchItems = async () => {
      try {
        const result = await fetchData(getQuery(file.items), gateway);
        for (let entry of result.transactions.edges) {
          setItems((prevItems: any) => {
            const newEntry = parseEntry(entry.node);

            if (!prevItems.some((item: any) => JSON.stringify(item) === JSON.stringify(newEntry))) {
              return [...prevItems, newEntry];
            }

            return prevItems;
          });
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchItems();
  }, [file.items]);

  const CollectionGrid = (props: any) => {
    const { items } = props;
    return (
      <div className="collectionGrid">
        {items &&
          items.map((item: any, i: number) => {
            return <Tile key={i} item={item} />;
          })}
      </div>
    );
  };

  return (
    <div className="renderer--collection">
      <CollectionGrid items={items} />
    </div>
  );
}

export default CollectionRenderer;
