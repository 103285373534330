import * as S from './styles';

function Loading(props: any) {
  const { loading } = props;

  return (
    <S.Loading visible={loading} className="flip">
      <S.Wave visible={loading}></S.Wave>
      <S.Wave visible={loading}></S.Wave>
    </S.Loading>
  );
}

export default Loading;
