import React from 'react';

import { GQLFetchArgsType } from 'permaweb-sdk/dist/helpers/types';
import { getQueryKey } from 'permaweb-sdk/dist/helpers/utils';
import { doFetchAssets } from 'permaweb-sdk/dist/implementations/rtk';

import AssetList from 'components/assetList';
import AssetListLoading from 'components/assetListLoading';
import { doFirstPage } from 'components/profileStamps/actions';
import { useAppDispatch } from 'store/hooks';

export interface IProps {
  address: string;
}

export default function ProfileStamps(props: IProps) {
  const { address } = props;
  const dispatch = useAppDispatch();

  const [query, setQuery] = React.useState<GQLFetchArgsType | null>(null);
  const queryKey = query ? getQueryKey(query) : '';

  const handleFirstPage = () => {
    dispatch(doFirstPage(address))
      .then((query) => query && setQuery(query))
      .catch(() => {});
  };

  const handleNextPage = () => {
    dispatch((dispatch: AppDispatch, getState: GetState) => {
      const state = getState();
      const fetching = state.assets.assetResultsByQueryKey[queryKey]?.fetching;
      const cursor = state.assets.assetResultsByQueryKey[queryKey]?.lastCursor;
      if (query && cursor && !fetching) {
        dispatch(doFetchAssets({ ...query, cursor }));
      }
    });
  };

  React.useEffect(() => {
    handleFirstPage();
  }, [address]);

  return (
    <div>
      <AssetList queryKey={queryKey} onFetchMore={handleNextPage} />
      <AssetListLoading queryKey={queryKey} />
    </div>
  );
}
