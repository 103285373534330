import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MakeState } from 'store/utils';

export interface ContentState {
  contentById: { [id: TxId]: { node: INode; vouched: boolean } };
  byQuery: {
    contentIds: { [query: string]: TxId[] };
    cursor: { [query: string]: string | null | undefined };
    isFetching: { [query: string]: boolean };
  };
}

const contentSlice = createSlice({
  name: 'content',
  initialState: MakeState<ContentState>({
    contentById: {},
    byQuery: { contentIds: {}, cursor: {}, isFetching: {} },
  }),
  reducers: {
    contentFetched: (
      state,
      action: PayloadAction<{
        query: string;
        edgesById: { [key: TxId]: IEdge };
        cursor: string | null;
      }>
    ) => {
      const { query, edgesById, cursor } = action.payload;
      state.contentById = Object.assign(state.contentById, edgesById);

      state.byQuery.isFetching[query] = false;
      state.byQuery.cursor[query] = cursor;

      if (state.byQuery.contentIds[query]) {
        state.byQuery.contentIds[query].push(...Object.keys(edgesById));
      } else {
        state.byQuery.contentIds[query] = Object.keys(edgesById);
      }
    },
    contentFetching: (state, action: PayloadAction<{ query: string; state: boolean }>) => {
      state.byQuery.isFetching[action.payload.query] = action.payload.state;
    },
    clearResults: (state) => {
      state.byQuery = { contentIds: {}, cursor: {}, isFetching: {} };
    },
  },
});

export default contentSlice;
