import './style.scss';

function DocumentRenderer(props: any) {
  const { asset } = props;

  return (
    <div className="renderer--document">
      {asset.items &&
        asset.items.map((item: any, i: number) => {
          return (
            <figure key={i}>
              <img src={`https://arweave.net/${asset.items[i]}`} />
              <figcaption>{asset.description}</figcaption>
            </figure>
          );
        })}
    </div>
  );
}

export default DocumentRenderer;
