import React from 'react';

import { doWalletDisconnect } from 'permaweb-sdk/dist/implementations/rtk/store/arweave/thunks';

import Avatar from 'components/avatar';
import Modal from 'components/base/modal';
import Onboarding from 'components/onboarding';
import { formatAddress, formatARAmount } from 'helpers/utils';
import { useLanguageProvider } from 'providers/LanguageProvider';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import uiSlice from 'store/ui/slice';
import { WalletList } from 'components/walletList';
import { CloseHandler } from 'components/wrappers/CloseHandler';

import * as S from './styles';

// TODO: dropdown z-index with routes
export default function WalletConnect(_props: { callback?: () => void }) {
  const dispatch = useAppDispatch();
  const walletAddress = useAppSelector((state) => state.arweave.walletAddress);
  const walletBalance = useAppSelector((state) => state.arweave.walletBalance);
  const profile = useAppSelector((state) => state.arweave.profile);
  const walletModalVisible = useAppSelector((state) => state.ui.walletModalVisible);
  const [onboarding, setOnboarding] = React.useState(false);

  const languageProvider = useLanguageProvider();
  const language = languageProvider.object[languageProvider.current];

  const [showWallet, setShowWallet] = React.useState<boolean>(false);
  const [showWalletDropdown, setShowWalletDropdown] = React.useState<boolean>(false);
  // const [showFund, setShowFund] = React.useState<boolean>(false);

  const [copied, setCopied] = React.useState<boolean>(false);
  const [label, setLabel] = React.useState<string | null>(null);

  React.useEffect(() => {
    setTimeout(() => {
      setShowWallet(true);
    }, 200);
  }, [walletAddress]);

  React.useEffect(() => {
    if (!showWallet) {
      setLabel(`${language.loading}...`);
    } else {
      if (walletAddress) {
        if (profile && profile.handle) {
          setLabel(profile.handle);
        } else {
          setLabel(formatAddress(walletAddress, false));
        }
      } else {
        setLabel(language.login);
      }
    }
  }, [showWallet, walletAddress, profile]);

  function handlePress() {
    if (walletAddress) {
      setShowWalletDropdown(!showWalletDropdown);
    } else {
      dispatch(uiSlice.actions.setWalletModalVisible(true));
    }
  }

  function handleClose() {
    dispatch(uiSlice.actions.setWalletModalVisible(false));
  }

  const copyAddress = React.useCallback(async () => {
    if (walletAddress) {
      if (walletAddress.length > 0) {
        await navigator.clipboard.writeText(walletAddress);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      }
    }
  }, [walletAddress]);

  function handleViewProfile() {
    // navigate(`${URLS.profilePosts(profile.walletAddress)}`);
    setShowWalletDropdown(false);
  }

  function handleDisconnect() {
    dispatch(doWalletDisconnect());
    setShowWalletDropdown(false);
  }

  return (
    <>
      {walletModalVisible && (
        <Modal handleClose={handleClose}>
          <S.LoginWrapper>
            {!onboarding ? <WalletList handleOnboarding={setOnboarding} /> : <Onboarding handleClose={handleClose} />}
          </S.LoginWrapper>
        </Modal>
      )}
      <CloseHandler
        callback={() => {
          setShowWalletDropdown(false);
        }}
        active={showWalletDropdown}
        disabled={false}
      >
        <S.Wrapper id="Wrapper">
          <S.PWrapper>
            {label && (
              <S.LAction onClick={handlePress}>
                <span>{label}</span>
              </S.LAction>
            )}
            {showWalletDropdown && <Avatar walletAddr={profile} />}
          </S.PWrapper>
          {showWalletDropdown && (
            <S.Dropdown className={'border-wrapper-alt1'}>
              <S.DHeaderWrapper onClick={handleViewProfile}>
                <S.DHeaderFlex>
                  <Avatar walletAddr={profile} />
                  <S.DHeader>
                    <p>{label}</p>
                    <span>{formatAddress(walletAddress, false)}</span>
                  </S.DHeader>
                </S.DHeaderFlex>
                <S.BWrapper>
                  <div>
                    <p>{language.arBalance}</p>
                    <span>{formatARAmount(Number(walletBalance.ar) || 0)}</span>
                  </div>
                </S.BWrapper>
              </S.DHeaderWrapper>
              <S.DBodyWrapper>
                <li onClick={copyAddress}>{copied ? `${language.copied}!` : language.copyWalletAddress}</li>
                <li onClick={handleViewProfile}>{language.viewProfile}</li>
              </S.DBodyWrapper>
              <S.DFooterWrapper>
                <li onClick={handleDisconnect}>{language.disconnect}</li>
              </S.DFooterWrapper>
            </S.Dropdown>
          )}
        </S.Wrapper>
      </CloseHandler>
      {/* {showFund && <TurboBalanceFund handleClose={() => setShowFund(false)} />} */}
    </>
  );
}
