import React from 'react';
import { ANS_110_TYPE_LIST } from 'permaweb-sdk/dist/helpers/typesAns110';
import { GATEWAYS } from 'permaweb-sdk/dist/helpers/config';

import ContentList from 'components/contentList';
import Ar from 'helpers/arweave/ar';
import { useAppSelector } from 'store/hooks';
import { selectAssetTypesFromListFilter } from 'store/ui/selectors';

const MIMES_MISUSED_AS_ANS110_TYPE = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/webp',
  'video/mp4',
  'video/webm',
  'video/ogg',
  'audio/mpeg',
  'audio/ogg',
  'audio/wav',
  'audio/webm',
];

interface Props {
  blockHeight: number;
  onUpdate: (data: { list: any[]; isLoading: boolean }) => void;
}

function New(props: Props) {
  const { onUpdate: notifyParent, blockHeight } = props;
  const [list, setList] = React.useState<any[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const assetTypes = useAppSelector(selectAssetTypesFromListFilter);
  const assetTypesStringified = JSON.stringify(assetTypes); // TMP
  const lockUpdateTrigger = React.useRef(false);
  const cursor = React.useRef(null);

  function getListQuery(quantity: number, cursor?: any) {
    const type = assetTypes.slice();

    if (type.length === 0) {
      // #100: Cast a wider net if user is not filtering
      type.push(...ANS_110_TYPE_LIST, ...MIMES_MISUSED_AS_ANS110_TYPE);
    }

    let x = `query EXPLORE_NEW {
        transactions(
          first: ${quantity}, 
          ${cursor ? `after: "${cursor}", ` : ''} 
          tags: [
            { name: "Implements", values: ["ANS-110"] },
            { name: "Data-Protocol", values: ["comment", "comment--dev", "repost"], op: NEQ }
            { name: "Type", values: ${JSON.stringify(type)} }
          ]
          block: { min: ${Ar.estimateBlockHeight(blockHeight, 30)} }
        ) {          
          edges {
            cursor
            node {
              id
              tags { name value }
            }
          }
        }
      }
    `;
    return x;
  }

  function parseEntries(query: any) {
    for (let entry of query.transactions.edges) {
      if (!entry.node.owner) {
        let owner = entry.node.tags.find((i: any) => i.name === 'Initial-Owner');
        if (!owner) {
          query.transactions.edges.shift();
          continue;
        }
        entry.node.owner = {
          address: owner.value,
        };
      }
      let content = entry.node.tags.find((i: any) => i.name === 'Media-Ids')?.value || null;
      if (!content) continue;
      content = JSON.parse(content);
      content = Object.values(content)[0];
      if (!content) continue;

      let banner = {
        name: 'Banner',
        value: content.id,
      };
      entry.node.tags.push(banner);
    }
    query.transactions.edges = query.transactions.edges.filter((entry: any) =>
      entry.node.owner ||
      (entry.node.tags.find((i: any) => i.name === 'Initial-Owner') &&
        (entry.node.owner = {
          address: entry.node.tags.find((i: any) => i.name === 'Initial-Owner').value,
        }))
        ? true
        : false
    );

    setList((prevList) => [...prevList, ...query.transactions.edges]);
    cursor.current = query.transactions.edges.length
      ? query.transactions.edges[query.transactions.edges.length - 1].cursor
      : null;
    lockUpdateTrigger.current = false;
  }

  async function fetchData(query: any) {
    try {
      const result = await Ar.fetchGraphQL(query, GATEWAYS.goldsky);
      parseEntries(result);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      lockUpdateTrigger.current = false;
      setIsLoading(false);
    }
  }

  React.useEffect(() => {
    notifyParent({ list, isLoading }); // Temp until unified via SDK
  }, [list, isLoading, notifyParent]);

  React.useEffect(() => {
    if (!lockUpdateTrigger.current) {
      lockUpdateTrigger.current = true;
      setIsLoading(true);
      setList([]);
      fetchData(getListQuery(50));
    }
  }, [assetTypesStringified]);

  const updateList = () => {
    if (!lockUpdateTrigger.current) {
      lockUpdateTrigger.current = true;
      setIsLoading(true);
      fetchData(getListQuery(20, cursor.current));
    }
  };

  return <ContentList list={list} action={() => updateList()} />;
}

export default New;
