import { GATEWAYS } from 'permaweb-sdk/dist/helpers/config';

import Ar from 'helpers/arweave/ar';
import { getUnknownError } from 'helpers/error';
import { gqlFetchContent } from 'store/content/query';
import contentSlice from 'store/content/slice';
import { selectStampedContentIds, selectStampedContentIdsSorted } from 'store/stamps/selectors';
import { doFetchRecentStamps, doFetchStampersForContent } from 'store/stamps/thunks';
import { ListSort } from 'store/ui/slice';

export function getContentQueryKey(options: IFetchContentOptions) {
  const { sort, days = 7, assetTypes = [] } = options;
  return `${sort}|${days}|${assetTypes.join(',')}`;
}

export interface IFetchContentOptions {
  sort: ListSort;
  days: number;
  assetTypes?: string[];
}

/**
 * Fetches content in an auto-paginated way (i.e. the next function call
 * fetches the next page). Reset actions are available in the slice.
 * @param options
 */
export function doFetchContent(options: IFetchContentOptions) {
  const PAGE_SIZE = 15;

  // [] This will be replaced by SDK's doFetchAssets once that supports
  //    querying multiple tag values.

  return async (dispatch: AppDispatch, getState: GetState) => {
    const query = getContentQueryKey(options);

    let state = getState();
    const fetching = state.content.byQuery.isFetching[query];
    const cursor = state.content.byQuery.cursor[query];

    if (fetching) {
      // prevent double dispatch, refactor to createAsyncThunk "condition"
      return;
    }

    if (cursor === null) {
      return; // no more content to fetch
    }

    dispatch(contentSlice.actions.contentFetching({ query, state: true }));

    try {
      const reFetchStamps = state.stamps.stampsDayRange < options.days;

      if (reFetchStamps) {
        await dispatch(doFetchRecentStamps(options.days));
        await dispatch(doFetchStampersForContent(['all']));
        state = getState();
      }

      // [] Currently, this selector selects everything, so once 30-day is selected, it will be stuck there.
      let contentIds = selectStampedContentIds(state);

      // === BAND_AID =========================================================
      const PAGINATION_METHOD: 'graphQL' | 'manual' = 'manual';

      if (PAGINATION_METHOD === 'manual') {
        contentIds = selectStampedContentIdsSorted(state, options.sort);
        let curLength = state.content.byQuery.contentIds[query]?.length || 0;
        let edges: IEdge[] = [];

        while (edges.length < PAGE_SIZE && curLength < contentIds.length) {
          const data = await Ar.fetchGraphQL(
            gqlFetchContent({
              txids: contentIds.slice(curLength, curLength + PAGE_SIZE),
              pageSize: PAGE_SIZE,
              assetTypes: options.assetTypes,
            }),
            GATEWAYS.goldsky
          );

          edges.push(...data.transactions.edges);
          curLength += PAGE_SIZE;
        }

        const sortedEdges = edges.sort((a, b) => contentIds.indexOf(a.node.id) - contentIds.indexOf(b.node.id));
        const edgesById: Record<string, IEdge> = sortedEdges.reduce((acc: { [key: string]: IEdge }, obj: IEdge) => {
          acc[obj.node.id] = obj;
          return acc;
        }, {});

        dispatch(
          contentSlice.actions.contentFetched({
            query,
            edgesById,
            cursor: curLength + edges.length < contentIds.length ? 'whatever' : null,
          })
        );
        return;
      }
      // ======================================================================

      const data = await Ar.fetchGraphQL(
        gqlFetchContent({
          txids: contentIds,
          cursor: cursor,
          pageSize: PAGE_SIZE,
          assetTypes: options.assetTypes,
        }),
        GATEWAYS.goldsky
      );

      const edges: Array<IEdge> = data.transactions.edges;
      const edgesById: Record<string, IEdge> = edges.reduce((acc: { [key: string]: IEdge }, obj: IEdge) => {
        acc[obj.node.id] = obj;
        return acc;
      }, {});

      const hasNextPage = data.transactions.pageInfo.hasNextPage;
      const newCursor = hasNextPage ? edges[edges.length - 1].cursor || null : null;

      dispatch(contentSlice.actions.contentFetched({ query, edgesById, cursor: newCursor }));
    } catch (e) {
      console.error(getUnknownError(e));
      dispatch(contentSlice.actions.contentFetching({ query, state: false }));
      // throw e;
    }
  };
}
