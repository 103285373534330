import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { pushIfUnique, spliceIfExists } from 'helpers/arrays';
import { MakeState } from 'store/utils';

export interface IStampCounts {
  total: number;
  vouched: number;
}
export interface IStampsState {
  stamps: IEdge[];
  stampsDayRange: number;
  stampCountsByDataSource: { [key: TxId]: IStampCounts };
  idsBeingStamped: TxId[];
  fetchingStamps: boolean;
  newestTimestamp?: number;
  newestCursor?: string;
  oldestTimestamp?: number;
  oldestCursor?: string;
  myStampedIds: Array<TxId>;
  vouchedById: { [key: string]: boolean };
  vouchedFetching: boolean;
  vouchedStateHash?: string;
  stampersForContentId: { [key: string]: { values: string[]; status: string } };
}

const stampsSlice = createSlice({
  name: 'stamps',
  initialState: MakeState<IStampsState>({
    stamps: [],
    stampsDayRange: 0,
    stampCountsByDataSource: {},
    idsBeingStamped: [],
    fetchingStamps: false,
    myStampedIds: [],
    vouchedById: {},
    vouchedFetching: false,
    stampersForContentId: {},
  }),
  reducers: {
    stampsFetched: (
      state,
      action: PayloadAction<{
        stamps: IEdge[];
        stampsDayRange: number;
        newestTimestamp: number;
        oldestTimestamp: number;
        newestCursor: string;
        oldestCursor: string;
      }>
    ) => {
      state.stamps = action.payload.stamps;
      state.stampsDayRange = action.payload.stampsDayRange;
      // state.stampCountsByDataSource = Object.assign(state.stampCountsByDataSource, action.payload.counts);
      state.fetchingStamps = false;
      state.newestTimestamp = action.payload.newestTimestamp;
      state.oldestTimestamp = action.payload.oldestTimestamp;
      state.newestCursor = action.payload.newestCursor;
      state.oldestCursor = action.payload.oldestCursor;
    },
    stampsFetching: (state, action: PayloadAction<boolean>) => {
      state.fetchingStamps = action.payload;
    },
    updateMyStampedIds: (state, action: PayloadAction<TxId[]>) => {
      state.myStampedIds = action.payload;
    },
    vouchedFetching: (state, action: PayloadAction<boolean>) => {
      state.vouchedFetching = action.payload;
    },
    vouchedFetched: (state, action: PayloadAction<{ result: { [key: string]: boolean }; stateHash: string }>) => {
      state.vouchedById = action.payload.result;
      state.vouchedStateHash = action.payload.stateHash;
      state.vouchedFetching = false;
    },
    stampersForContentFetching: (state, action: PayloadAction<string[]>) => {
      action.payload.forEach((id) => {
        if (!state.stampersForContentId[id]) {
          state.stampersForContentId[id] = { values: [], status: 'loading' };
        } else {
          if (state.stampersForContentId[id].status === 'loaded') {
            state.stampersForContentId[id].status = 'loading';
            // preserve existing list...
          }
        }
      });
    },
    stampersForContentFetched: (state, action: PayloadAction<{ data: { [key: TxId]: WalletId[] }; ids: TxId[] }>) => {
      const { data, ids } = action.payload;
      ids.forEach((id) => {
        const stamperIds = data[id] || [];
        state.stampersForContentId[id] = { status: 'loaded', values: stamperIds };

        if (!state.stampCountsByDataSource[id]) {
          state.stampCountsByDataSource[id] = {
            total: stamperIds.length,
            vouched: stamperIds.filter((id) => state.vouchedById[id]).length,
          };
        } else {
          // Don't create new object if already exists so Immer can do it's thing.
          state.stampCountsByDataSource[id].total = stamperIds.length;
          state.stampCountsByDataSource[id].vouched = stamperIds.filter((id) => state.vouchedById[id]).length;
        }
      });
    },
    stampingStarted: (state, action: PayloadAction<TxId>) => {
      pushIfUnique(state.idsBeingStamped, action.payload);
    },
    stampingDone: (state, action: PayloadAction<{ id: TxId; stampEdge?: IEdge }>) => {
      spliceIfExists(state.idsBeingStamped, action.payload.id);
      if (action.payload.stampEdge) {
        state.stamps.unshift(action.payload.stampEdge);
      }
    },
  },
});

export default stampsSlice;
