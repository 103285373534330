import styled, { keyframes } from 'styled-components';

import { STYLING } from 'helpers/config';

export const Wrapper = styled.div<{ top: number; noHeader: boolean }>`
  // min-height: 100vh;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;
  // background: rgba(var(--color-background-base), 0.7);
  background: rgba(255, 255, 255, 0.7);
`;

export const Container = styled.div<{
  noHeader: boolean;
}>`
  width: 650px;
  max-width: ${(props) => (props.noHeader ? '100%' : '90vw')};
  border-radius: ${STYLING.dimensions.radius.primary};
  background: var(--color-gray3);
  border: 1px solid var(--color-border);
  animation: ${keyframes`
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  `} 0.4s ease forwards;
  transform-origin: center;
  transform-origin: center;
`;

export const Header = styled.div`
  height: 65px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`;

export const LT = styled.div`
  max-width: 75%;
  display: flex;
  align-items: center;
`;

export const Title = styled.p`
  color: var(--color-text);
  font-size: ${STYLING.font.size.l};
  font-weight: ${(props) => props.theme.typography.weight.bold};
  font-weight: ${STYLING.font.weight.l};
  line-height: calc(${STYLING.font.size.l} + 5px);
  font-family: ${(props) => props.theme.typography.family.primary};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 2.5px 0 0 0;
`;

export const Close = styled.div`
  padding: 2.5px 0 0 0;
  svg {
  }
`;

export const Body = styled.div<{ useVisibleOverflow: boolean }>`
  max-height: calc(100dvh - 100px);
  width: 100%;
  overflow-y: ${(props) => (props.useVisibleOverflow ? 'visible' : 'auto')};
  scrollbar-color: transparent transparent;
  // padding: 0 0 0 20px 0;

  ::-webkit-scrollbar-track {
    padding: 0 5px;
  }

  ::-webkit-scrollbar {
    width: 15.5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 36px;
    border: 3.5px solid transparent;
    background-clip: padding-box;
  }
`;
